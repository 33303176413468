import React, {useEffect} from "react"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import {useNavigate} from 'react-router-dom';
import product_personal_chart_users from './landing_page_photos/product_personal_chart_users.png';
import personal_page_header_image from './landing_page_photos/personal_page_header_image.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import {variables} from './Variables.js';

export const ProductPersonal = () => {

  useEffect(() => {
      document.title = "Azure Git Statistics - Personal Subscription";
      informProductPersonal();
  }, []);

  const navigate = useNavigate();

  const getRegister = () => {
    navigate("/a/register")
  }
  
  const informProductPersonal = () => {
    if (process.env.NODE_ENV !== 'production')
    {
      return;
    }
    fetch(variables.API_URL+'ddbd44b1-1f82-4a11-aa96-813708f4a25b',{
        method:'POST',
        mode: 'cors',
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json'
        }
    })
    .then(res => {
        return res;
    })
  };

  return (
    <>
      {/* show only on md and lg and xl */}
      <Hidden only={['xs','sm']}>
        {/* header image and text */}
        <Box sx={{ position: 'relative', width: '100%', mt: 5}}>
          <Box component="img" sx={{ width: '100%' }} alt="yellow, green and blue shades" src={personal_page_header_image} />
          <Box
            sx={{ 
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <Typography
              variant='h3'
              gutterBottom
              sx={{ 
                color: '#031242',
                fontFamily: 'Quicksand, sans-serif',
                fontWeight: 'bold',
                textAlign: 'center',
                // Adding a white border effect using textShadow
                textShadow: `
                -2px -2px 0 #fff,  
                2px -2px 0 #fff,
                -2px 2px 0 #fff,
                2px 2px 0 #fff,
                -3px 0 0 #fff,
                3px 0 0 #fff,
                0 -3px 0 #fff,
                0 3px 0 #fff`
              }}
            >
              Get to know your stats
            </Typography>
            <Box align="center">
              <Typography
                variant='h5'  
                sx={{
                  marginBottom: 3,
                  color: '#031242',
                  fontFamily: 'Quicksand, sans-serif',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  // Adding a white border effect using textShadow
                  textShadow: `
                  -2px -2px 0 #fff,  
                  2px -2px 0 #fff,
                  -2px 2px 0 #fff,
                  2px 2px 0 #fff,
                  -3px 0 0 #fff,
                  3px 0 0 #fff,
                  0 -3px 0 #fff,
                  0 3px 0 #fff`
                }}
              >
                Azure Git Statistics lets a developer know how they are doing compared to the rest of the team
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid container alignItems="center" justifyContent="center" sx={{ minHeight: '100%'}}>
        <Grid item >

          {/* 1st quote */}
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="3rem">
            <Box 
              sx={{
                display: 'flex',
                width: '65%',
                height: '25rem',
                borderRadius: 2,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7'
              }}>
              <Box display="flex" flexDirection="column" sx={{ flex: '0 1 60%', height: '100%',
                background: 'radial-gradient(circle at top right, #f9fff7, transparent)' }}>
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start" sx={{ flexGrow: 1 }}>
                  <Typography
                    gutterBottom
                    variant="h4"
                    sx={{
                      fontFamily: 'Quicksand, sans-serif',
                      paddingLeft: "2rem",
                      paddingTop: "2rem",
                      textAlign: "left", // Changed from center to left
                      width: "80%"
                    }}
                  >
                    <FormatQuoteRoundedIcon fontSize='large'/><i> Do you know where I can see how many prs I did last year? </i><FormatQuoteRoundedIcon fontSize='large'/>
                  </Typography>
                </Box>

                <Divider variant='middle' sx={{ bgcolor: 'black' }}/>

                <Box display="flex" justifyContent="flex-end" alignItems="flex-end" sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: 'Quicksand, sans-serif',
                      paddingRight: "4rem",
                      paddingBottom: "3rem",
                      textAlign: "right", // Changed from center to right
                      width: "75%",

                    }}
                  >
                    - Edward Crow
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ flex: '0 1 40%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderLeft: "thick double #d1e4ff",
                background: 'radial-gradient(circle at top left, #d1e4ff, transparent)' }}>
                <Typography variant="h5" sx={{ fontFamily: 'Quicksand, sans-serif', textAlign: 'center', color: 'black', width: '100%', padding: "1rem" }}>
                  Right here! <KeyboardDoubleArrowDownIcon fontSize='large'/> We
                  offer developers a Personal plan so they can visualise their impact
                  compared to the rest of the team.
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* chart image */}
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={6}>
            <Box
              component="img"
              sx={{
                width: "65%",
                mt: "2rem",
                borderRadius: 10,
                boxShadow: '0px 0px 50px #d0d6f7',
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
              }}
              alt="a screenshot of the Total Pull Requests Per User chart, with blue, vertical bars"
              src={product_personal_chart_users}
            />
          </Box>

          {/* asterix text under chart */}
          <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%', // or the width you desire
            height: '100%', // or the height you desire
            mt: '1rem'
          }}
          >
            <Typography gutterBottom variant="subtitle2" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '100%' }}>
              *Only your name is visible in Personal plans, other developers are randomly renamed as <i>"User _ _ _"</i>
            </Typography>
          </Box>

          {/* Start your free trial button */}
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={6}>
            <Button onClick={() => getRegister()} variant="contained" color="secondary" size='large'>
              Start your free trial
            </Button>
          </Box>

          {/* text section */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingY={'5rem'}
            sx={{
              width: '100%', // or the width you desire
              height: '100%', // or the height you desire
            }}
            >
            <Typography gutterBottom variant="h4" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '50%' }}>
              Compare yourself to others accross the entire Organisation
            </Typography>
            <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
              How many pull requests you have authored or
            </Typography>
            <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%', color: '#c41db9' }}>
              how many you have reviewed
            </Typography>
            <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
              across any project, in any repository, any branch, during any period.
            </Typography>
          </Box>

          {/* 3 cards */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingY={'1rem'}
            sx={{
              width: '100%', // or the width you desire
              height: '100%', // or the height you desire
            }}
            >
            <Grid container spacing={2} sx={{marginTop: '2rem'}} justifyContent="center" alignItems="stretch">
              <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                <Card sx={{
                  backgroundColor: '#ebedf7', 
                  borderRadius: 8,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7', 
                  padding: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  height: '100%', 
                  width: '100%'
                }}
                >
                  <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                      Choose up to 3 projects
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                      Unlimited repositories
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                <Card sx={{
                  backgroundColor: '#ebedf7', 
                  borderRadius: 8,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7', 
                  padding: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  height: '100%', 
                  width: '100%'
                }}
                >
                  <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                      2 charts
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                      Use 2 different charts to visualise your stats
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                <Card sx={{
                  backgroundColor: '#ebedf7', 
                  borderRadius: 8,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7', 
                  padding: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  height: '100%', 
                  width: '100%'
                }}
                >
                  <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                      Export!
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                      Take your data out to use elsewhere
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        </Grid>
      </Hidden>

      {/* show only on xs and sm */}
      <Hidden only={['md','lg', 'xl']}>
        <Box sx={{ position: 'relative', width: '100%', mt: 5}}>
          <Box component="img" sx={{ width: '100%', height: '350px' }} alt="yellow, green and blue shades" src={personal_page_header_image} />
          <Box
            sx={{ 
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              mt: 1
            }}
          >
            <Typography
              variant='h5'
              width='100%'
              gutterBottom
              sx={{ 
                color: '#031242',
                fontFamily: 'Quicksand, sans-serif',
                fontWeight: 'bold',
                textAlign: 'center',
                // Adding a white border effect using textShadow
                textShadow: `
                -1px -1px 0 #fff,  
                1px -1px 0 #fff,
                -1px 1px 0 #fff,
                1px 1px 0 #fff`
              }}
            >
              Get to know your stats
            </Typography>
            <Box align="center">
              <Typography
                variant='body1'
                width='100%'
                sx={{
                  marginBottom: 3,
                  color: '#031242',
                  fontFamily: 'Quicksand, sans-serif',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  // Adding a white border effect using textShadow
                  textShadow: `
                  -1px -1px 0 #fff,  
                  1px -1px 0 #fff,
                  -1px 1px 0 #fff,
                  1px 1px 0 #fff`
                }}
              >
                Azure Git Statistics lets a developer know how they are doing compared to the rest of the team
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid container alignItems="center" justifyContent="center" sx={{ minHeight: '100%'}}>
        <Grid item >
          
          {/* 1st quote */}
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="3rem">
            <Box 
              sx={{
                width: '65%',
                borderRadius: 2,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7'
              }}>
              <Box display="flex" flexDirection="column" sx={{ height: '100%',
                background: 'radial-gradient(circle at top right, #f9fff7, transparent)' }}>
                <Box display="flex" justifyContent="flex-start" alignItems="flex-start" sx={{ flexGrow: 1, mb: 5 }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    sx={{
                      fontFamily: 'Quicksand, sans-serif',
                      paddingLeft: "2rem",
                      paddingTop: "2rem",
                      textAlign: "left", // Changed from center to left
                      width: "90%"
                    }}
                  >
                    <FormatQuoteRoundedIcon fontSize='large'/><i> Do you know where I can see how many prs I did last year? </i><FormatQuoteRoundedIcon fontSize='large'/>
                  </Typography>
                </Box>

                <Divider variant='middle' sx={{ bgcolor: 'black' }}/>

                <Box display="flex" justifyContent="flex-end" alignItems="flex-end" sx={{ flexGrow: 1, mt: 3 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: 'Quicksand, sans-serif',
                      paddingRight: "3rem",
                      paddingBottom: "2rem",
                      textAlign: "right", // Changed from center to right
                      width: "75%",

                    }}
                  >
                    - Edward Crow
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderTop: "thick double #d1e4ff",
                    background: 'radial-gradient(circle at top left, #d1e4ff, transparent)'
                  }}>
                  <Typography variant="h6`" sx={{ fontFamily: 'Quicksand, sans-serif', textAlign: 'center', color: 'black', width: '100%', padding: "2rem" }}>
                    Right here! <KeyboardDoubleArrowDownIcon fontSize='medium'/> We
                    offer developers a Personal plan so they can visualise their impact
                    compared to the rest of the team.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* chart image */}
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box
              component="img"
              sx={{
                width: "75%",
                mt: "4rem",
                borderRadius: 10,
                boxShadow: '0px 0px 50px #d0d6f7',
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
              }}
              alt="a screenshot of the Total Pull Requests Per User chart, with blue, vertical bars"
              src={product_personal_chart_users}
            />
            </Box>
            <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '100%', // or the width you desire
              height: '100%', // or the height you desire
              mt: '1rem'
            }}
            >
            <Typography gutterBottom variant="subtitle2" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '100%' }}>
              *Only your name is visible in Personal plans, other developers are randomly renamed as <i>"User _ _ _"</i>
            </Typography>
          </Box>
          {/* Start your free trial button */}
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={6}>
            <Button onClick={() => getRegister()} variant="contained" color="secondary" size='large'>
              Start your free trial
            </Button>
          </Box>
          {/* text section */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingY={'5rem'}
            sx={{
              width: '100%', // or the width you desire
              height: '100%', // or the height you desire
            }}
            >
            <Typography gutterBottom variant="h4" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
              Compare yourself to others accross the entire Organisation
            </Typography>
            <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
              How many pull requests you have authored or
            </Typography>
            <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%', color: '#c41db9' }}>
              how many you have reviewed
            </Typography>
            <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
              across any project, in any repository, any branch, during any period.
            </Typography>
          </Box>
          {/* 3 cards */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingY={'1rem'}
            sx={{
              width: '100%',
              height: '100%',
            }}
            >
            <Grid container sx={{marginTop: '2rem'}} justifyContent="center" alignItems="stretch">
              <Grid item xs={12} md={4} sx={{ display: 'flex', m: 2 }}>
                <Card sx={{
                  backgroundColor: '#ebedf7', 
                  borderRadius: 8,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7', 
                  padding: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  height: '100%', 
                  width: '100%'
                }}
                >
                  <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                      Choose up to 3 projects
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                      Unlimited repositories
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} sx={{ display: 'flex', m: 2 }}>
                <Card sx={{
                  backgroundColor: '#ebedf7', 
                  borderRadius: 8,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7', 
                  padding: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  height: '100%', 
                  width: '100%'
                }}
                >
                  <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                      2 charts
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                      Use 2 different charts to visualise your stats
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} sx={{ display: 'flex', m: 2 }}>
                <Card sx={{
                  backgroundColor: '#ebedf7', 
                  borderRadius: 8,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7', 
                  padding: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  height: '100%', 
                  width: '100%'
                }}
                >
                  <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                      Export!
                    </Typography>
                    <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                      Take your data out to use elsewhere
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        </Grid>
      </Hidden>
    </>
  );
}