import {MDBContainer} from 'mdb-react-ui-kit';

export const LogOut = ({ setIsUserLoggedIn }) => {

  localStorage.clear();
  setIsUserLoggedIn(false);

  return (
    <MDBContainer className='my-5'>
      You have been logged out. Click <a href="/a/log-in">here</a> to log back in.
    </MDBContainer>
  );

}