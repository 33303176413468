import React, {useState, useEffect} from "react"
import {variables} from './Variables.js';
import {useNavigate} from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, Paper } from '@mui/material';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';

//props are used for parent comps to send values or fucntions to children
export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({ email: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  var status = 0;

  useEffect(() => {
      document.title = "Azure Git Statistics - Forgot Your Password";
  }, []);

  const validate = () => {
      let tempErrors = { email: ''};
      if (!email) {
          tempErrors.email = 'Email is required';
      }
      if (!email.includes("@")) {
          tempErrors.email = 'Please provide a valid email address';
      }
      setErrors(tempErrors);
      return Object.values(tempErrors).every(x => x === "");
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  
  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (validate())
    {
      setSending(true);
      let emailInternal = email.toLowerCase();

      fetch(
        variables.API_URL+'ForgotPassword',
        {
            method:'POST',
            mode: 'cors',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                Email: emailInternal
            })
        }
      )
      .then(res => {
          if (res.status === 401)
          {
            setSending(false);
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            throw new Error('Server error')
          }
          return res.json();
      })
      .then(data => {
        setDialogMessage("An email has been sent to " + email + " with instructions on how to reset your password.")
        setIsDialogOpen(true);
        setSending(false);
      })
      .catch(error => {
          console.error('There has been a problem with your request:', error);
      })
    }
  }

  return (
    <>
      <Container maxWidth="sm">
          <Paper elevation={6} style={{ padding: '20px', marginTop: '6rem' }}>
              <Typography variant="h5" gutterBottom textAlign="center">
                  Reset your password
              </Typography>
              <Typography variant=" body1" paragraph textAlign="center">
                  To reset your password, enter your email below and submit. An email will be sent to you with instructions about how to complete the process.
              </Typography>
              <form onSubmit={handleForgotPassword} noValidate>
                  <TextField
                      label="Email"
                      type="email"
                      fullWidth
                      variant="outlined"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={!!errors.email}
                      helperText={errors.email}
                      margin="normal"
                      required
                  />
                  <Box textAlign="center" marginTop={2}>
                      <Button variant="contained" color="primary" type="submit" disabled={sending} fullWidth>
                          Reset password
                      </Button>
                  </Box>
              </form>
          </Paper>
      </Container>
      {isDialogOpen && (
        <SharedAlertDialog
          dialogMessage={dialogMessage}
          dialogTitle="Info"
          isOpen={isDialogOpen}
          handleDialogClose={handleDialogClose}
        />
      )}
    </>
  );
}
