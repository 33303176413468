import { variables } from './Variables.js';

export function getMyUser(callback) {
  fetchData_Get('GetMyUser', callback);
}

export function getAzureDetails(callback) {
  fetchData_Get('GetAzureDetails', callback);
}

export function getSubscription(callback) {
  fetchData_Get('GetSubscription', callback);
}

export function getUser(callback) {
  fetchData_Get('GetUser', callback);
}

export function getAzureUsers(callback) {
  fetchData_Get('GetAzureUsers', callback);
}

export function getAzureSourceBranches(repositories, callback) {
  fetchData_Post('GetAzureSourceBranches', repositories, callback);
}

export function getAzureTargetBranches(repositories, callback) {
  fetchData_Post('GetAzureTargetBranches', repositories, callback);
}

export function getAzureRepositories(callback) {
  fetchData_Get('GetAzureRepositories', callback);
}

function fetchData_Post(endpoint, repositories, callback) {
  let status = 0;
  const body = {
    AzureRepositoryNames: repositories
  };

  fetch(variables.API_URL + endpoint, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem("token")
    },
    body: JSON.stringify(body)
  })
  .then(res => {
    if (res.status === 400) {
      status = 400;
    }
    return res.json();
  })
  .then(data => {
    if (status === 400) {
      alert(data.message);
    } else {
      callback(data.message); // Invoke the callback function with the data
    }
  })
  .catch((error) => {
  });
}

function fetchData_Get(endpoint, callback) {
  let status = 0;
  fetch(variables.API_URL + endpoint, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem("token")
    }
  })
  .then(res => {
    if (res.status === 401) {
      status = 401
    }
    return res.json();
  })
  .then(data => {
    if (status === 400) {
      alert(data.message);
    } else {
      callback(data.message); // Invoke the callback function with the data
    }
  })
  .catch((res, error) => {
    callback({success_or_error: 'error', status_code: status}); // Invoke the callback function with the data
  });
}