import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChartForTotalPullRequestsPerMonthComponent = ({ dataProp }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let newChart;

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Destroy previous chart before creating a new one
      if (newChart) {
        newChart.destroy();
      }

      newChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: dataProp.map(row => row.month),
          datasets: [
            {
              label: '# of PRs',
              data: dataProp.map(row => row.pullRequestCount)
            }
          ]
        }
      });
    }

    return () => {
      // Clean up the chart when the component is unmounted
      if (newChart) {
        newChart.destroy();
      }
    };
  }, [dataProp]);

  return <canvas ref={chartRef} width="100%" height="100%"></canvas>;
};

export default BarChartForTotalPullRequestsPerMonthComponent;
