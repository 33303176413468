import {HomeNavBar} from './HomeNavBar'

export const HomeLayout = (props) => {
  
  const updateIsUserLoggedIn = (newValue) => {
    props.updateIsUserLoggedIn(newValue);
  };

  return (
  
    <>
      <HomeNavBar isUserLoggedIn={props.isUserLoggedIn} updateIsUserLoggedIn={updateIsUserLoggedIn}/>
    </>
  
  );

}