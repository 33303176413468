import React from 'react';
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';

export const AppFooter = (props) => {

  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const getScreen = (e) => {
    handleCloseNavMenu()
    switch (e) {
      case "Contact":
        getContact();
        break;
      case "Suggestions":
        getSuggestions();
        break;
      case "Terms and Privacy":
        getTermsAndPrivacy();
        break;
      case "Help":
        getHelp();
        break;
      case "Product":
        getProduct();
        break;
      case "Pricing":
        getPricing();
        break;
      case "Azure Git Statistics":
        getHome();
        break;
    }
  };
  const getContact = (e) => {
    handleCloseNavMenu()
    navigate("/contact");
  };
  const getSuggestions = (e) => {
    handleCloseNavMenu()
    navigate("/suggestions");
  };
  const getHelp = (e) => {
    handleCloseNavMenu()
    navigate("/help");
  };
  const getHome = (e) => {
    handleCloseNavMenu()
    navigate("/");
  };
  const getTermsAndPrivacy = (e) => {
    handleCloseNavMenu()
    navigate("/terms-and-privacy");
  };
  const getPricing = (e) => {
    handleCloseNavMenu()
    navigate("/pricing");
  };
  const getProduct = (e) => {
    handleCloseNavMenu()
    navigate("/product");
  };

  const menuItems = [
    <MenuItem key={1} onClick={() => getContact()}>
      <Typography color="black" textAlign="center">Contact</Typography>
    </MenuItem>,
    <MenuItem key={2} onClick={() => getSuggestions()}>
      <Typography color="black" textAlign="center">Suggestions</Typography>
    </MenuItem>,
    <MenuItem key={3} onClick={() => getTermsAndPrivacy()}>
      <Typography color="black" textAlign="center">Terms and Privacy</Typography>
    </MenuItem>,
    <MenuItem key={4} onClick={() => getHelp()}>
      <Typography color="black" textAlign="center">Help</Typography>
    </MenuItem>,
    <MenuItem key={5} onClick={() => getProduct()}>
      <Typography color="black" textAlign="center">Product</Typography>
    </MenuItem>,
    <MenuItem key={6} onClick={() => getPricing()}>
      <Typography color="black" textAlign="center">Pricing</Typography>
    </MenuItem>,
    <MenuItem key={7} onClick={() => getHome()}>
      <Typography color="black" textAlign="center">Azure Git Statistics</Typography>
    </MenuItem>
  ];

  return (
    <AppBar position='static' sx={{ top: 'auto', bottom: 0, backgroundColor: '#F5F5F5' }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Stack direction="row" spacing={5} >
            {['Contact', 'Suggestions', 'Terms and Privacy', 'Help', 'Product', 'Pricing', 'Azure Git Statistics'].map((text) => (
              <Link 
                component="button"
                variant="body1"
                sx={{ 
                  color: 'black', 
                  textDecoration: 'none', // No underline by default
                  '&:hover': { 
                    cursor: 'pointer', 
                    textDecoration: 'none', // Underline on hover
                    color: 'gray' // or any other color for hover state
                  } 
                }}
                onClick={() => { getScreen(text) }}
              >
                {text}
              </Link>
            ))}
          </Stack>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-controls="menu-footerbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="black"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-footerbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {menuItems}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}