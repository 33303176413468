import React, {useEffect} from "react"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {useNavigate} from 'react-router-dom';
import landing_page_top from './landing_page_photos/landing_page_top.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PersonalIcon from '@mui/icons-material/SettingsAccessibilitySharp';
import EnterpriseIcon from '@mui/icons-material/Diversity3Sharp';
import {variables} from './Variables.js';

export const Product = () => {

  useEffect(() => {
      document.title = "Azure Git Statistics - Products";
      informProduct();
  }, []);

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const getProductForPersonal = () => {
    navigate("/product/personal")
  }

  const getProductForEnterprise = () => {
    navigate("/product/enterprise")
  }
  
  const informProduct = () => {
    if (process.env.NODE_ENV !== 'production')
    {
      return;
    }
    fetch(variables.API_URL+'f30cd579-c186-4e9b-a88d-7d993d39882c',{
        method:'POST',
        mode: 'cors',
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json'
        }
    })
    .then(res => {
        return res;
    })
  };

  return (
    <>
    <Box sx={{ position: 'relative', width: '100%', mt: 5}}>
      <Box component="img" sx={{ width: '100%' }} alt="green, yellow and blue shapes in clouds" src={landing_page_top} />
      <Typography
        variant={isSmallScreen ? 'h4' : 'h2'}
        sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#031242',
          fontFamily: 'Quicksand, sans-serif',
          fontWeight: 'bold',
          textAlign: 'center',
          // Adding a white border effect using textShadow
          textShadow: `
          -2px -2px 0 #fff,  
          2px -2px 0 #fff,
          -2px 2px 0 #fff,
          2px 2px 0 #fff,
          -3px 0 0 #fff,
          3px 0 0 #fff,
          0 -3px 0 #fff,
          0 3px 0 #fff`
        }}
      >
        Product
      </Typography>
    </Box>
    <Grid container alignItems="center" justifyContent="center">
      <Grid item>
        {/* 3 centered cards */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%', // or the width you desire
            height: '100%', // or the height you desire
            my: '3rem'
          }}
          >
          <Grid container spacing={2} justifyContent="center" alignItems="stretch" width={'100%'}>
            <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
              <Card 
                onClick={() => getProductForPersonal()}
                sx={{
                  backgroundColor: '#ebedf7', 
                  borderRadius: 8,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7', 
                  padding: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  height: '100%', 
                  width: '100%'
                }}
              >
                <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    For personal use <PersonalIcon fontSize= "large"/>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
              <Card
                onClick={() => getProductForEnterprise()}
                sx={{
                  backgroundColor: '#ebedf7', 
                  borderRadius: 8,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7', 
                  padding: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  height: '100%', 
                  width: '100%'
                }}
              >
                <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    For enterprises <EnterpriseIcon fontSize= "large"/>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
    </>
  );
}