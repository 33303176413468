import React, {useState, useEffect} from "react"
import {variables} from './Variables.js';
import {useNavigate} from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, Paper } from '@mui/material';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';
import CryptoJS from 'crypto-js';

//props are used for parent comps to send values or fucntions to children
export const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [sending, setSending] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [errors, setErrors] = useState({ newPassword: '', newPasswordAgain: '', email: '', resetToken: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  var status = 0;

  useEffect(() => {
      document.title = "Azure Git Statistics - Reset Your Password";
  }, []);

  const createPasswordHash = (password) => {
    const salt = process.env.REACT_APP_SALT;
    const hashedPassword = CryptoJS.HmacSHA512(password, salt);
    return hashedPassword.toString();
  };

  const validate = () => {
      let tempErrors = { email: '', newPassword: '', newPasswordAgain: '', resetToken: '' };
      if (!email) {
          tempErrors.email = 'Your email is required';
      }
      if (!newPassword) {
          tempErrors.newPassword = 'A new password is required';
      }
      if (!newPasswordAgain) {
          tempErrors.newPasswordAgain = 'You must enter your new password again';
      }
      if (newPassword !== newPasswordAgain) {
          tempErrors.newPassword = 'Your passwords do not match';
          tempErrors.newPasswordAgain = 'Your passwords do not match';
      }
      if (resetToken.length !== 6) {
          tempErrors.resetToken = 'The 6-digit code you enterred is not 6 digits';
      }
      if (!/^\d+$/.test(resetToken)) {
          tempErrors.resetToken = 'A reset token only contains numbers';
      }
      setErrors(tempErrors);
      return Object.values(tempErrors).every(x => x === "");
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    if (dialogMessage === "Your password has been updated.")
    {
      navigate("/a/log-in");
    }
  };
  
  const handleResetPassword = (e) => {
    e.preventDefault();

    const newPasswordHash = createPasswordHash(newPassword);
    const newPasswordAgainHash = createPasswordHash(newPasswordAgain);

    if (validate())
    {
      let emailInternal = email.toLowerCase();
      setSending(true);

      fetch(
        variables.API_URL+'ResetPassword',
        {
            method:'POST',
            mode: 'cors',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
              Email: emailInternal,
              NewPasswordHash: newPasswordHash,
              NewPasswordAgainHash: newPasswordAgainHash,
              SixDigitCode: resetToken
            })
        }
      )
      .then(res => {
          if (res.status === 401)
          {
            setSending(false);
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            throw new Error('Server error')
          }
          return res.json();
      })
      .then(data => {
        setDialogMessage(data.message)
        setIsDialogOpen(true);
        setSending(false);
      })
      .catch(error => {
          console.error('There has been a problem with your request:', error);
      })
    }
  }

  return (
    <>
      <Container maxWidth="sm">
          <Paper elevation={6} style={{ padding: '20px', marginTop: '6rem' }}>
              <Typography variant="h5" gutterBottom textAlign="center">
                  Enter a new password
              </Typography>
              <form onSubmit={handleResetPassword} noValidate>
                  <TextField
                      label="Email"
                      type="email"
                      fullWidth
                      variant="outlined"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={!!errors.email}
                      helperText={errors.email}
                      margin="normal"
                      required
                  />
                  <TextField
                      label="New Password"
                      type="password"
                      fullWidth
                      variant="outlined"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      error={!!errors.newPassword}
                      helperText={errors.newPassword}
                      margin="normal"
                      required
                  />
                  <TextField
                      label="New Password Again"
                      type="password"
                      fullWidth
                      variant="outlined"
                      value={newPasswordAgain}
                      onChange={(e) => setNewPasswordAgain(e.target.value)}
                      error={!!errors.newPasswordAgain}
                      helperText={errors.newPasswordAgain}
                      margin="normal"
                      required
                  />
                  <TextField
                      label="6-digit code"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={resetToken}
                      onChange={(e) => setResetToken(e.target.value)}
                      error={!!errors.resetToken}
                      helperText={errors.resetToken}
                      margin="normal"
                      required
                  />
                  <Box textAlign="center" marginTop={2}>
                      <Button variant="contained" color="primary" type="submit" disabled={sending} fullWidth>
                          Reset password
                      </Button>
                  </Box>
              </form>
          </Paper>
      </Container>
      {isDialogOpen && (
        <SharedAlertDialog
          dialogMessage={dialogMessage}
          dialogTitle="Info"
          isOpen={isDialogOpen}
          handleDialogClose={handleDialogClose}
        />
      )}
    </>
  );
}
