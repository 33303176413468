import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {variables} from './Variables.js';
import { getAzureDetails, getSubscription } from './apiUtils.js';
import {useNavigate} from 'react-router-dom';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';
import { Container, Card, CardContent, Grid, TextField, Button, Typography, Alert } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export const AzureDetails = () => {

  const navigate = useNavigate();
  const [patName, setPatName] = useState('');
  const [patKey, setPatKey] = useState('');
  const [azureOrganisationName, setAzureOrganisationName] = useState('');
  const [shouldDisplayAzureProjects, setShouldDisplayAzureProjects] = useState(false);
  const [azureProjectsForFilter, setAzureProjectsForFilter] = useState([]);
  const [azureProjects, setAzureProjects] = useState([]);
  const [subscriptionTier, setSubscriptionTier] = useState('');
  const [azureProjectSelectionLimit, setAzureProjectSelectionLimit] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [updating, setUpdating] = useState(false);
  
  var status;

  useEffect(() => {
    document.title = "Azure Git Statistics - Your Azure Details";
    getAzureDetails(handleAzureDetailState);
    getSubscription(handleSubscriptionState);
  }, []);
  
  const handleAzureDetailState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
    }
    setPatName(data.personalAccessToken_Name);
    setPatKey(data.personalAccessToken_Key);
    setAzureOrganisationName(data.azureOrganisationName);
    if(data.azureProjects !== null)
    {
      setAzureProjects(data.azureProjects);
    }
    if(data.azureOrganisationName !== "")
    {
      getAzureProjects()
      setShouldDisplayAzureProjects(true)
    }
  };

  const handleSubscriptionState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
      return
    }
    setSubscriptionTier(data.subscriptionTier);
    if (data.subscriptionTier === "" || data.subscriptionIsCancelledInStripe)
    {
      navigate("/a/account-manager")
      return
    }
    if (data.subscriptionIsPastDue)
    {
      navigate("/a/account-manager/subscription-past-due")
      return
    }
    if(data.subscriptionTier === "Personal Subscription")
    {
      setAzureProjectSelectionLimit(3)
    }
  };
  
  function onSelectedAzureProject (selectedList) {
    if(selectedList === null)
    {
      return
    }
    if(selectedList.length > 3 && subscriptionTier === "Personal Subscription")
    {
      setDialogMessage("If you would like to choose more than 3 projects, please upgrade to an Enterprise Subscription")
      setIsDialogOpen(true);
    }
    else {
      setAzureProjects(selectedList);
    }
  }

  function updateAzureDetails(e){

    setIsDialogOpen(false)
    setUpdating(true)

    const postData = {
      PersonalAccessToken_Name: patName,
      PersonalAccessToken_Key: patKey,
      AzureOrganisationName: azureOrganisationName,
      AzureProjects: azureProjects,
    };

    fetch(
      variables.API_URL+'UpdateAzureDetails',
      {
        method:'POST',
        mode: 'cors',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'Authorization': 'bearer ' + localStorage.getItem("token")
        },
        body:JSON.stringify(postData)
      }
    )
    .then(res => {
      if (res.status === 401)
      {
        navigate("/a/log-in")
        throw new Error('Unauthorized')
      }
      if (res.status >= 500 && res.status < 600 )
      {
        throw new Error('Server error')
      }
      
      return res.json();
    })
    .then(data => {
      setDialogMessage(data.message)
      setIsDialogOpen(true);
      if (data.message !== "There was a problem updating your details. Either the Organisation does not exist, your PAT details are incorrect, or your PAT has expired.")
      {
        getAzureDetails(handleAzureDetailState)
      }
      setUpdating(false)
    })
    .catch(error => {
        console.error('There has been a problem with your request:', error);
    })
  }

  function getAzureProjects(e){
    setUpdating(true)

    fetch(
      variables.API_URL+'GetAzureProjects',
      {
        method:'GET',
        mode: 'cors',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'Authorization': 'bearer ' + localStorage.getItem("token")
        }
      }
    )
    .then(res => {
        if (res.status === 401)
        {
          navigate("/a/log-in")
          throw new Error('Unauthorized')
        }
        if (res.status >= 500 && res.status < 600 )
        {
          setDialogMessage("Oops! There was a problem. Your PAT may be incorrect or it has expired. Visit Azure DevOps to get a new one and then update it here.")
          setIsDialogOpen(true);
          setUpdating(false)
          throw new Error('Server error')
        }
        return res.json();
    })
    .then(data => {
        if (data.message === "Problem")
        {
          setDialogMessage("Oops! There was a problem. Your PAT may be incorrect or it has expired. Visit Azure DevOps to get a new one and then update it here.")
          setIsDialogOpen(true);
        }
        else
        {
          setAzureProjectsForFilter(data.message)
        }
        setUpdating(false)
    })
    .catch(error => {
        console.error('There has been a problem with your request:', error);
    })
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {isDialogOpen && (
        <SharedAlertDialog
          dialogMessage={dialogMessage}
          dialogTitle="Info"
          isOpen={isDialogOpen}
          handleDialogClose={handleDialogClose}
        />
      )}
      <Container sx={{mt: "3rem"}}>

        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  Add or Edit your Organisation and Personal Access Token
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin='normal'
                  label='Azure Organisation'
                  value={azureOrganisationName}
                  id='azureOrganisationName'
                  type='text'
                  onChange={(e) => setAzureOrganisationName(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin='normal'
                  label='Personal Access Token Name'
                  value={patName}
                  id='patName'
                  type='text'
                  onChange={(e) => setPatName(e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin='normal'
                  label='Personal Access Token Key'
                  value={patKey}
                  id='patKey'
                  type='text'
                  onChange={(e) => setPatKey(e.target.value)}
                />
              </Grid>
            </Grid>

            <Button variant='contained' size='medium' onClick={updateAzureDetails} className='mb-4 mt-4' disabled={updating}>
              Update
            </Button>

          </CardContent>
        </Card>
        
      </Container>

      {shouldDisplayAzureProjects &&
        <>
          <Container sx={{marginTop: "3rem"}}>
            <Card>
              {subscriptionTier === 'Personal Subscription' &&
                <Alert severity="warning">
                  <strong><u>Remember</u>:</strong> With the Personal Subscription plan you are only allowed to subscribe to 3 Projects. <strong>You are not allowed to unsubscribe from projects you have chosen and saved.</strong>
                </Alert>
              }
              <CardContent>
                <Grid container spacing={2} mt="1rem">
                  <Grid item xs={12}>
                    <Typography>
                      You will have access to all repositories belonging to the projects that you subscribe to below
                    </Typography>
                  </Grid>
                </Grid>
                
                <Grid container spacing={2} mt="1rem">
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      limitTags={3}
                      options={azureProjectsForFilter}
                      getOptionLabel={(option) => option.azureProjectName}
                      value={azureProjects}
                      onChange={(event, newValue) => {
                        onSelectedAzureProject(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Projects"
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Button variant='contained' size='medium' onClick={updateAzureDetails} className='mb-4 mt-4' disabled={updating}>
                  Update
                </Button>

              </CardContent>
            </Card>
          </Container>

        </>
      }
    </>
  )
};


export default AzureDetails;