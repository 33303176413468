import {useNavigate} from 'react-router-dom';
import React from 'react';
import {Outlet} from 'react-router-dom'
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {AppFooter} from './AppFooter';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import azure_git_statistics_logo_2 from './landing_page_photos/azure_git_statistics_logo_2.png';
import Button from '@mui/material/Button';

export const HomeNavBar = (props) => {

  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElCharts, setAnchorElCharts] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenChartsMenu = (event) => {
    setAnchorElCharts(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseChartsMenu = () => {
    setAnchorElCharts(null);
  };
 
  const logOut = async (e) => {
    handleCloseUserMenu()
    props.updateIsUserLoggedIn(false);
    localStorage.clear();
    navigate("/");
  };

  const getAccountManager = (e) => {
    handleCloseNavMenu()
    navigate("/a/account-manager");
  };

  const logIn = (e) => {
    handleCloseUserMenu()
    navigate("/a/log-in");
  };

  const getDashboard = (e) => {
    handleCloseNavMenu()
    navigate("/a/dashboard");
  };

  const getPricing = (e) => {
    handleCloseNavMenu()
    navigate("/pricing");
  };

  const getHome = (e) => {
    handleCloseNavMenu()
    navigate("/");
  };

  const getProduct = (e) => {
    handleCloseNavMenu()
    navigate("/product");
  };

  const register = (e) => {
    handleCloseUserMenu()
    navigate("/a/register");
  };

  const getPullRequestImportTool = (e) => {
    handleCloseNavMenu()
    navigate("/a/pull-request-import-tool");
  };

  const getTotalPullRequestsPerUser = (e) => {
    handleCloseNavMenu()
    handleCloseChartsMenu()
    navigate("/a/statistics/charts/total-prs-per-user");
  };

  const getTotalPullRequestsPerRepository = (e) => {
    handleCloseNavMenu()
    handleCloseChartsMenu()
    navigate("/a/statistics/charts/total-prs-per-repository");
  };

  const getTotalPullRequestsPerMonth = (e) => {
    handleCloseNavMenu()
    handleCloseChartsMenu()
    navigate("/a/statistics/charts/total-prs-per-month");
  };

  const getTotalApprovalsPerUser = (e) => {
    handleCloseNavMenu()
    handleCloseChartsMenu()
    navigate("/a/statistics/charts/total-approvals-per-user");
  };

  const getProjectStatistics = (e) => {
    handleCloseNavMenu()
    handleCloseChartsMenu()
    navigate("/a/statistics/charts/project-statistics");
  };

  const menuItemsForCharts = [
    <MenuItem key={1} onClick={() => getTotalPullRequestsPerUser()}>
      <Typography textAlign="center">Total Pull Requests Per User</Typography>
    </MenuItem>,
    <MenuItem key={2} onClick={() => getTotalPullRequestsPerRepository()}>
      <Typography textAlign="center">Total Pull Requests Per Repository</Typography>
    </MenuItem>,
    <MenuItem key={3} onClick={() => getTotalPullRequestsPerMonth()}>
      <Typography textAlign="center">Total Pull Requests Per Month</Typography>
    </MenuItem>,
    <MenuItem key={4} onClick={() => getTotalApprovalsPerUser()}>
      <Typography textAlign="center">Total Approvals Per User</Typography>
    </MenuItem>,
    <MenuItem key={5} onClick={() => getProjectStatistics()}>
      <Typography textAlign="center">Project Statistics</Typography>
    </MenuItem>
  ];

  const menuItemsWhenNotLoggedIn = [
    <MenuItem key={1} onClick={getPricing}>
      <Typography textAlign="center">Pricing</Typography>
    </MenuItem>,
    <MenuItem key={2} onClick={getProduct}>
      <Typography textAlign="center">Product</Typography>
    </MenuItem>
  ];

  const menuItemsAuthWhenNotLoggedIn = [
    <MenuItem key={1} onClick={() => logIn()}>
      <Typography textAlign="center">Log In</Typography>
    </MenuItem>,
    <MenuItem key={2} onClick={() => register()}>
      <Typography textAlign="center">Sign Up</Typography>
    </MenuItem>
  ];

  const menuItemsForTopNavSmallScreenWhenLoggedIn = [
    <MenuItem key={1} onClick={() => getHome()}>
      <Typography fontSize={18} fontFamily={'monospace'} textAlign="center">Azure Git Statistics</Typography>
    </MenuItem>,
    <Divider key={2} />,
    <MenuItem key={3} onClick={() => getDashboard()}>
      <Typography textAlign="center">Dashboard</Typography>
    </MenuItem>,
    <MenuItem key={4} onClick={() => getPullRequestImportTool()}>
      <Typography textAlign="center">Import</Typography>
    </MenuItem>,
    <MenuItem key={5} onClick={() => getAccountManager()}>
      <Typography textAlign="center">Account Manager</Typography>
    </MenuItem>,
    <MenuItem
      key={6}
      aria-controls="menu-charts"
      aria-haspopup
      onClick={handleOpenChartsMenu}
      color="inherit"
    >
      Charts
    </MenuItem>,
    <Menu
      key={7}
      id="menu-charts"
      anchorEl={anchorElCharts}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={Boolean(anchorElCharts)}
      onClose={handleCloseChartsMenu}
    >
      {menuItemsForCharts}
    </Menu>
  ];

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="fixed" sx={{ backgroundColor: '#F5F5F5' }}>
          <Toolbar disableGutters>
            {/* LARGE SCREEN STARTS HERE */}
            <Box
              component="img"
              onClick={() => getHome()}
              sx={{
                width: 70, height: 70, m: 1, borderRadius: 4, display: { xs: 'none', md: 'flex' }
              }}
              alt="a green and blue circular logo for azure git stats"
              src={azure_git_statistics_logo_2}
            />
            <Typography
              variant="h6"
              noWrap
              onClick={() => getHome()}
              sx={{
                mr: 2,
                ml: 1,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 500,
                color: 'black',
                textDecoration: 'none',
              }}
            >
              Azure Git Statistics
            </Typography>

            {
            props.isUserLoggedIn
            ?
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                onClick={() => getDashboard()}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Dashboard
              </Button>
              <Button
                onClick={() => getPullRequestImportTool()}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Import
              </Button>
              <Button
                onClick={() => getAccountManager()}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Account Manager
              </Button>
              <Button
                aria-controls="menu-charts"
                aria-haspopup
                onClick={handleOpenChartsMenu}
                sx={{color: "black"}} 
              >
                Charts
              </Button>
              <Menu
                id="menu-charts"
                anchorEl={anchorElCharts}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElCharts)}
                onClose={handleCloseChartsMenu}
              >
                {menuItemsForCharts}
              </Menu>
            </Box>
            :
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                onClick={() => getPricing()}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Pricing
              </Button>
              <Button
                onClick={() => getProduct()}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                Product
              </Button>
            </Box>
            }
            {/* LARGE SCREEN ENDS HERE */}

            {/* SMALL STARTS HERE */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {props.isUserLoggedIn ? menuItemsForTopNavSmallScreenWhenLoggedIn : menuItemsWhenNotLoggedIn}
              </Menu>
            </Box>

            <Typography
              variant="h6"
              noWrap
              onClick={() => getHome()}
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 500,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Box
                component="img"
                onClick={() => getHome()}
                sx={{
                  width: 70, height: 70, m: 1, borderRadius: 4, display: { xs: 'flex', md: 'none' }
                }}
                alt="a green and blue circular logo for azure git stats"
                src={azure_git_statistics_logo_2}
              />
            </Typography>
            
            {/* SMALL ENDS HERE */}
            
            {/* SHARED AVATAR STARTS HERE */}

            <Box sx={{ flexGrow: 0, mr: 2 }}>
              <Tooltip title="">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="a grey and blank avatar"/>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
              {
              props.isUserLoggedIn
              ?
              <MenuItem key={1} onClick={() => logOut()}>
                <Typography textAlign="center">Log Out</Typography>
              </MenuItem>
              :
              menuItemsAuthWhenNotLoggedIn
              }
              </Menu>
            </Box>
            
            {/* SHARED AVATAR ENDS HERE */}
          </Toolbar>
        </AppBar>

        <Box component="main" sx={{ flexGrow: 1, my: "3rem" }}>
          <Outlet />
        </Box>
        <AppFooter sx={{ mt: 'auto' }}/>
      </Box>
    </>
  );
}