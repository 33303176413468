import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChartForTotalApprovalsPerUserComponent = ({ dataProp }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let newChart;

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Destroy previous chart before creating a new one
      if (newChart) {
        newChart.destroy();
      }

      newChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: dataProp.map(row => row.azureUserDisplayName),
          datasets: [
            {
              label: 'Approved',
              data: dataProp.map(row => row.approved),
              backgroundColor: '#FF69B4',
            },
            {
              label: 'Approved with suggestions',
              data: dataProp.map(row => row.approvedWithSuggestions),
              backgroundColor: '#349945',
            },
            {
              label: 'No vote',
              data: dataProp.map(row => row.noVote),
              backgroundColor: '#00ABE4',
            },
            {
              label: 'Waiting for author',
              data: dataProp.map(row => row.waitingForAuthor),
              backgroundColor: '#ff9900',
            },
            {
              label: 'Rejected',
              data: dataProp.map(row => row.rejected),
              backgroundColor: '#1D1D1',
            },
            // Add more datasets for additional categories
          ],
        },
        options: {
          scales: {
            x: { stacked: true },
            y: { stacked: true },
          },
        },
      });
    }

    return () => {
      // Clean up the chart when the component is unmounted
      if (newChart) {
        newChart.destroy();
      }
    };
  }, [dataProp]);

  return <canvas ref={chartRef} width="100%" height="100%"></canvas>;
};

export default BarChartForTotalApprovalsPerUserComponent;
