import {NavBar} from './Navbar'

export const SharedLayout = (props) => {
  
  const updateIsUserLoggedIn = (newValue) => {
    props.updateIsUserLoggedIn(newValue);
  };

  return (
  
    <>
      <NavBar isUserLoggedIn={props.isUserLoggedIn} updateIsUserLoggedIn={updateIsUserLoggedIn}/>
    </>
  
  );

}