import React, {useState, useEffect} from "react"
import {variables} from './Variables.js';
import {useNavigate} from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, Paper, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import CryptoJS from 'crypto-js';

export const LogIn = ({ setIsUserLoggedIn }) => {
  const navigate = useNavigate();
  var status = 0;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });

  useEffect(() => {
      document.title = "Azure Git Statistics - Sign In";
      localStorage.clear();
      setIsUserLoggedIn(false);
  }, []);

  const createPasswordHash = (password) => {
    const salt = process.env.REACT_APP_SALT;
    const hashedPassword = CryptoJS.HmacSHA512(password, salt);
    return hashedPassword.toString();
  };

  const validate = () => {
    let tempErrors = { email: '', password: '' };
    if (!email) {
        tempErrors.email = 'Email is required';
    }
    if (!email.includes("@")) {
        tempErrors.email = 'Please provide a valid email address';
    }
    if (!password) {
        tempErrors.password = 'Password is required';
    }
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  }

  const handleLogIn = async (e) => {
    e.preventDefault();
    const passwordHash = createPasswordHash(password);
    if (validate())
    {
      let emailInternal = email.toLowerCase();

      fetch(
        variables.API_URL+'Login',
        {
          method:'POST',
          mode: 'cors',
          headers:{
              'Accept':'application/json',
              'Content-Type':'application/json'
          },
          body:JSON.stringify({
            email: emailInternal,
            passwordHash: passwordHash
          })
        }
      )
      .then(res => {
          if (res.status === 401)
          {
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status === 400)
          {
            status = 400
          }
          if (res.status >= 500 && res.status < 600 )
          {
            throw new Error('Server error')
          }
          return res.json();
      })
      .then(data => {
          if (status === 400)
          {
            alert(data.message);
            setIsUserLoggedIn(false);
          }
          else
          {
            localStorage.setItem("token", data.token);
            localStorage.setItem("email", data.email);
            localStorage.setItem("userId", data.userId);
            setIsUserLoggedIn(true);
            navigate("/a/dashboard");
          }
      })
      .catch(error => {
          console.error('There has been a problem with your request:', error);
      })
    }
  }

  return (
    <Container maxWidth="sm">
        <Paper elevation={6} style={{ padding: '20px', marginTop: '6rem'}}>
            <Typography variant="h5" gutterBottom textAlign="center">
                Login
            </Typography>
            <form onSubmit={handleLogIn} noValidate>
                <TextField
                    label="Email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                    margin="normal"
                    required
                />
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!errors.password}
                    helperText={errors.password}
                    margin="normal"
                    required
                />
                <Box textAlign="center" marginTop={2}>
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                        Log In
                    </Button>
                </Box>
                <Grid container justifyContent="flex-end">
                    <Grid>
                        <Link href="/a/forgot-password" variant="body2" style={{ marginTop: '8px' }}>
                            Forgot password?
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    </Container>
  );
}