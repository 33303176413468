import React, { useState, useEffect } from 'react';
import {variables} from './Variables.js';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAzureTargetBranches } from './apiUtils.js';
import { getAzureUsers } from './apiUtils.js';
import { getAzureSourceBranches } from './apiUtils.js';
import { getAzureRepositories } from './apiUtils.js';
import {useNavigate} from 'react-router-dom';
import { getSubscription } from './apiUtils.js';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';
import Grid from '@mui/material/Unstable_Grid2';
import BarChartForTotalPullRequestsPerRepsoitoryComponent from './BarChartForTotalPullRequestsPerRepsoitoryComponent.jsx';
import { getPreviousPeriod, getPlaceholderList, downloadCsvFile, calculateDaysBetweenDates, areChartFormDatesPresent } from './chartsSharedFunctions.js';

export const TotalPullRequestsPerRepository = () => {

    const navigate = useNavigate();
    const [dataForTotalPullRequestsPerRepositoryChart, setDataForTotalPullRequestsPerRepositoryChart] = useState([]);
    const [dataForSecondaryTotalPullRequestsPerRepositoryChart, setDataForSecondaryTotalPullRequestsPerRepositoryChart] = useState([]);
    const [sourceBranches, setSourceBranches] = useState([]);
    const [targetBranches, setTargetBranches] = useState([]);
    const [repositories, setRepositories] = useState([]);
    const [developers, setDevelopers] = useState([]);
    const [developersForFilter, setDevelopersForFilter] = useState([]);
    const [sourceBranchesForFilter, setSourceBranchesForFilter] = useState([]);
    const [targetBranchesForFilter, setTargetBranchesForFilter] = useState([]);
    const [repositoriesForFilter, setRepositoriesForFilter] = useState([]);
    const [startDateForChosenPeriod, setStartDateForChosenPeriod] = useState(null);
    const [endDateForChosenPeriod, setEndDateForChosenPeriod] = useState(null);
    const [startDateForPreviousPeriod, setStartDateForPreviousPeriod] = useState(null);
    const [endDateForPreviousPeriod, setEndDateForPreviousPeriod] = useState(null);
    const [shouldRepositoryDropDownBeDisabled, setShouldRepositoryDropDownBeDisabled] = useState(true);
    const [shouldUserDropDownBeDisabled, setShouldUserDropDownBeDisabled] = useState(true);
    const [placeholderForRepository, setPlaceholderForRepository] = useState('None selected...');
    const [placeholderForDevelopers, setPlaceholderForDevelopers] = useState('None selected...');
    const [placeholderForSourceBranches, setPlaceholderForSourceBranches] = useState('None selected...');
    const [placeholderForTargetBranches, setPlaceholderForTargetBranches] = useState('None selected...');
    const [shouldApplyButtonBeDisabled, setShouldApplyButtonBeDisabled] = useState(false);
    const [canBeExported, setCanBeExported] = useState(false);
    const [jsonData, setJsonData] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    //call these function for container load
    useEffect(() => {
      document.title = "Azure Git Statistics - Total Pull Requests per Repository";
      getAzureUsers(handleUsersUpdate);
      getAzureRepositories(handleRepositoriesUpdate)
      getSubscription(handleSubscriptionState);
    }, []);

    useEffect(() => {
      if (shouldApplyButtonBeDisabled && startDateForChosenPeriod !== null && endDateForChosenPeriod !== null) {
        doWork();
      }
    }, [shouldApplyButtonBeDisabled]);

    const handleSubscriptionState = (data) => {
      if(data.status_code === 401)
      {
        navigate("/a/log-in")
      }
      if (data.subscriptionTier === "" || data.subscriptionIsCancelledInStripe)
      {
        navigate("/a/account-manager/subscribe")
      }
      if (data.subscriptionIsPastDue)
      {
        navigate("/a/account-manager/subscription-past-due")
      }
      if (data.subscriptionTier === "Personal Subscription")
      {
        navigate("/a/account-manager/subscribe")
      };
      if (data.subscriptionTier === "Enterprise Subscription")
      {
        setShouldUserDropDownBeDisabled(false)
      };
    };
    
    function onRemoveRepository(selectedList) {
      setCanBeExported(false)
      const newSelectedList = selectedList.map(item => ({
        "Name": item.AzureRepositoryName
      }));

      setRepositories(newSelectedList);

      const placeholder = getPlaceholderList(newSelectedList)
      setPlaceholderForRepository(placeholder)
      
      if (newSelectedList.length === 0) {
        setSourceBranchesForFilter([])
        setTargetBranchesForFilter([])
        setShouldRepositoryDropDownBeDisabled(false)
      } else {
        setShouldRepositoryDropDownBeDisabled(true)
        getAzureSourceBranches(newSelectedList, handleSourceBranchesUpdate);
        getAzureTargetBranches(newSelectedList, handleTargetBranchesUpdate);
      }
    }

    function onRemoveTarget(selectedList) {
      setCanBeExported(false)
      const newSelectedList = selectedList.map(item => ({
        "Name": item.AzureBranchName
      }));
      setTargetBranches(newSelectedList);
      const placeholder = getPlaceholderList(newSelectedList)
      setPlaceholderForTargetBranches(placeholder)
    }

    function onRemoveSource(selectedList) {
      setCanBeExported(false)
      const newSelectedList = selectedList.map(item => ({
        "Name": item.AzureBranchName
      }));
      setSourceBranches(newSelectedList);
      const placeholder = getPlaceholderList(newSelectedList)
      setPlaceholderForSourceBranches(placeholder)
    }

    function onRemoveDevelopers(selectedList) {
      setCanBeExported(false)
      setDevelopers(selectedList);
      const newSelectedList = selectedList.map(item => ({
        "Name": item.azureUserDisplayName
      }));
      const placeholder = getPlaceholderList(newSelectedList)
      setPlaceholderForDevelopers(placeholder)
    }

    function onSelectedRepository(selectedList) {
      setCanBeExported(false)
      const newSelectedList = selectedList.map(item => ({
        "Name": item.AzureRepositoryName
      }));
      const placeholder = getPlaceholderList(newSelectedList)
      setPlaceholderForRepository(placeholder)
      setShouldRepositoryDropDownBeDisabled(true)
      setRepositories(newSelectedList);
      getAzureSourceBranches(newSelectedList, handleSourceBranchesUpdate);
      getAzureTargetBranches(newSelectedList, handleTargetBranchesUpdate);
    }

    function onSelectedSource(selectedList) {
      setCanBeExported(false)
      const newSelectedList = selectedList.map(item => ({
        "Name": item.AzureBranchName
      }));
      const placeholder = getPlaceholderList(newSelectedList)
      setPlaceholderForSourceBranches(placeholder)
      setSourceBranches(newSelectedList);
    }

    function onSelectedTarget(selectedList) {
      setCanBeExported(false)
      const newSelectedList = selectedList.map(item => ({
        "Name": item.AzureBranchName
      }));
      const placeholder = getPlaceholderList(newSelectedList)
      setPlaceholderForTargetBranches(placeholder)
      setTargetBranches(newSelectedList);
    }

    function onSelectedDeveloper(selectedList) {
      setCanBeExported(false)
      setDevelopers(selectedList);
      const newSelectedList = selectedList.map(item => ({
        "Name": item.azureUserDisplayName
      }));
      const placeholder = getPlaceholderList(newSelectedList)
      setPlaceholderForDevelopers(placeholder)
    }

    const onDatePickerChange = (dates) => {
      setCanBeExported(false)
      setStartDateForPreviousPeriod(null)
      setEndDateForPreviousPeriod(null)
      const [start, end] = dates;
      setStartDateForChosenPeriod(start);
      setEndDateForChosenPeriod(end);
    }

    const handleSourceBranchesUpdate = (data) => {
      setCanBeExported(false)
      setSourceBranchesForFilter(data);
      setShouldRepositoryDropDownBeDisabled(false)
    };

    const handleTargetBranchesUpdate = (data) => {
      setCanBeExported(false)
      setTargetBranchesForFilter(data);
      setShouldRepositoryDropDownBeDisabled(false)
    };

    const handleRepositoriesUpdate = (data) => {
      setCanBeExported(false)
      if(data.status_code === 401)
      {
        navigate("/a/log-in")
      }
      setRepositoriesForFilter(data);
      setShouldRepositoryDropDownBeDisabled(false)
    };

    const handleUsersUpdate = (data) => {
      setCanBeExported(false)
      if(data.status_code === 401)
      {
        navigate("/a/log-in")
      }
      setDevelopersForFilter(data);
    };

    function getTotalPullRequestsPerRepository(){
      if (areChartFormDatesPresent(startDateForChosenPeriod, endDateForChosenPeriod)) {
        setShouldApplyButtonBeDisabled(true);
      } else {
        setIsDialogOpen(true)
      }
    }

    const handleDialogClose = () => {
      setIsDialogOpen(false);
    };

    async function doWork(){
      await getTotalPullRequestsPerRepository2(true, startDateForChosenPeriod, endDateForChosenPeriod)
      const dates = getPreviousPeriod(startDateForChosenPeriod, endDateForChosenPeriod)
      setStartDateForPreviousPeriod(dates.startDateForPreviousPeriod)
      setEndDateForPreviousPeriod(dates.endDateForPreviousPeriod)
      await getTotalPullRequestsPerRepository2(false, dates.startDateForPreviousPeriod, dates.endDateForPreviousPeriod)
      setShouldApplyButtonBeDisabled(false)
    }

    async function getTotalPullRequestsPerRepository2(isMainChart, startDate, endDate){
      var status = 0;

      const postData = {
        AzureRepositoryNames: repositories,
        AzureSourceBranches: sourceBranches,
        AzureTargetBranches: targetBranches,
        AzureUsers: developers,
        StartDate: startDate,
        EndDate: endDate
      };

      await fetch(variables.API_URL+'GetTotalPullRequestsPerRepository',{
          method:'POST',
          mode: 'cors',
          headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'Authorization': 'bearer ' + localStorage.getItem("token")
          },
          body: JSON.stringify(postData),
      })
      .then(res => {
          if (res.status === 401)
          {
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            throw new Error('Server error')
          }
          return res.json();
      })
      .then(data => {
          if (status === 400)
          {
              alert(data.message);
          }
          else
          {
              if (isMainChart)
              {
                setDataForTotalPullRequestsPerRepositoryChart(data.message);
            
                if ((data.message).length !== 0)
                {
                  setJsonData(data.message)
                  setCanBeExported(true)
                }
              }
              else
              {
                setDataForSecondaryTotalPullRequestsPerRepositoryChart(data.message);
              }
          }
      })
      .catch(error => {
          console.error('There has been a problem with your request:', error);
      })
    }

    function getChosenPeriodString(){
      let previousPeriodMessage = getPreviousPeriodString()
      if (previousPeriodMessage === "Previous Period")
      {
        return "Chosen Period"
      }

      if(startDateForChosenPeriod !== null && endDateForChosenPeriod !== null)
      {
        var daysDifference = calculateDaysBetweenDates(startDateForChosenPeriod, endDateForChosenPeriod)
        return "Chosen " + daysDifference + "-day Period | " + startDateForChosenPeriod.toDateString() + " - " + endDateForChosenPeriod.toDateString()
      }

      return "Chosen Period"
    }

    function getPreviousPeriodString(){
      if(startDateForPreviousPeriod !== null && endDateForPreviousPeriod !== null)
      {
        var daysDifference = calculateDaysBetweenDates(startDateForChosenPeriod, endDateForChosenPeriod)
        return "Previous " + daysDifference + "-day Period | " + startDateForPreviousPeriod.toDateString() + " - " + endDateForPreviousPeriod.toDateString()
      }
      return "Previous Period"
    }

    return (

      <>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={6} marginBottom={4} marginRight={3}>
          <Typography variant="h4" gutterBottom justifyContent= 'center' align='center' sx={{fontFamily: 'Open Sans, sans-serif'}}>
            Total Pull Requests Per Repository
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container columnSpacing={2} columns={15} justifyContent="center" alignItems="center">
            <Grid md={3}>
              <FormGroup>
                <FormLabel>Repository</FormLabel>
                <Multiselect
                  displayValue="AzureRepositoryName"
                  onKeyPressFn={function noRefCheck(){}}
                  onRemove={onRemoveRepository}
                  onSearch={function noRefCheck(){}}
                  onSelect={onSelectedRepository}
                  options={repositoriesForFilter.map((branch) => ({ AzureRepositoryName: branch }))}
                  showCheckbox
                  disable={shouldRepositoryDropDownBeDisabled}
                  keepSearchTerm={true}
                  hideSelectedList={true}
                  placeholder={placeholderForRepository}
                  className='multiselectContainer optionContainer'
                />
              </FormGroup>
            </Grid>
            <Grid md={3}>
              <FormGroup>
                <FormLabel>Source</FormLabel>
                <Multiselect
                  displayValue="AzureBranchName"
                  onKeyPressFn={function noRefCheck(){}}
                  onRemove={onRemoveSource}
                  onSearch={function noRefCheck(){}}
                  onSelect={onSelectedSource}
                  options={sourceBranchesForFilter.map((branch) => ({ AzureBranchName: branch }))}
                  showCheckbox
                  keepSearchTerm={true}
                  hideSelectedList={true}
                  placeholder={placeholderForSourceBranches}
                  className='multiselectContainer'
                />
              </FormGroup>
            </Grid>
            <Grid md={3}>
              <FormGroup>
                <FormLabel>Target</FormLabel>
                <Multiselect
                  displayValue="AzureBranchName"
                  onKeyPressFn={function noRefCheck(){}}
                  onRemove={onRemoveTarget}
                  onSearch={function noRefCheck(){}}
                  onSelect={onSelectedTarget}
                  options={targetBranchesForFilter.map((branch) => ({ AzureBranchName: branch }))}
                  showCheckbox
                  keepSearchTerm={true}
                  hideSelectedList={true}
                  placeholder={placeholderForTargetBranches}
                  className='multiselectContainer'
                />
              </FormGroup>
            </Grid>
            <Grid md={3}>
              <FormGroup>
                <FormLabel>Developers</FormLabel>
                <Multiselect
                  displayValue="azureUserDisplayName"
                  onKeyPressFn={function noRefCheck(){}}
                  onRemove={onRemoveDevelopers}
                  onSearch={function noRefCheck(){}}
                  onSelect={onSelectedDeveloper}
                  options={developersForFilter}
                  disable={shouldUserDropDownBeDisabled}
                  showCheckbox
                  keepSearchTerm={true}
                  hideSelectedList={true}
                  placeholder={placeholderForDevelopers}
                  className='multiselectContainer'
                />
              </FormGroup>
            </Grid>
            <Grid md={3}>
              <FormGroup>
                <FormLabel>Dates</FormLabel>
                <DatePicker
                  onChange={onDatePickerChange}
                  startDate={startDateForChosenPeriod}
                  endDate={endDateForChosenPeriod}
                  selectsRange
                  className='date-picker'
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Box>

        <Box marginTop={5} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container columnSpacing={1} columns={12}>
            <Grid md={6}>
              <Button size="medium" disabled={shouldApplyButtonBeDisabled} variant="contained" endIcon={<SendIcon />} onClick={() => getTotalPullRequestsPerRepository()}>
                Apply
              </Button>
            </Grid>
            <Grid md={6}>
              <Button size="medium" disabled={!canBeExported} variant="contained" endIcon={<SendIcon />} onClick={() => downloadCsvFile(jsonData)}>
                Export
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box marginTop={5} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container columnSpacing={1} columns={12}>
            <Grid md={6}>
              <Typography variant="body1" gutterBottom textAlign='center' sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontFamily: 'Open Sans, sans-serif'
              }}>
                {getChosenPeriodString()}
              </Typography>
              <BarChartForTotalPullRequestsPerRepsoitoryComponent dataProp={dataForTotalPullRequestsPerRepositoryChart} />
            </Grid>
            <Grid md={6}>
              <Typography variant="body1" gutterBottom textAlign='center' sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontFamily: 'Open Sans, sans-serif'
              }}>
                {getPreviousPeriodString()}
              </Typography>
              <BarChartForTotalPullRequestsPerRepsoitoryComponent dataProp={dataForSecondaryTotalPullRequestsPerRepositoryChart} />
            </Grid>
          </Grid>
        </Box>

        {isDialogOpen && (
          <SharedAlertDialog
            dialogMessage="Please choose a valid date range."
            dialogTitle="Info"
            isOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
          />
        )}
      </>
    );
}