import React, { useState, useEffect } from 'react';
import {variables} from './Variables.js';
import { getSubscription } from './apiUtils.js';
import {useNavigate} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import AutoDeleteTwoToneIcon from '@mui/icons-material/AutoDeleteTwoTone';
import ArrowDropDownCircleTwoToneIcon from '@mui/icons-material/ArrowDropDownCircleTwoTone';
import Divider from '@mui/material/Divider';
import LoadingSkeleton from './LoadingSkeleton.jsx';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const SubscriptionOptions = () => {

  const navigate = useNavigate();
  const [subscriptionTier, setSubscriptionTier] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [subscriptionIsCancelled, setSubscriptionIsCancelled] = useState(false);
  const [subscriptionIsCancelledInStripe, setSubscriptionIsCancelledInStripe] = useState(false);
  const [isCancellationDialogOpen, setIsCancellationDialogOpen] = useState(false);
  const [isDowngradeDialogOpen, setIsDowngradeDialogOpen] = useState(false);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);

  useEffect(() => {
    document.title = "Azure Git Statistics - Manage Your Subscription";
    getSubscription(handleSubscriptionState);
  }, []);

  const handleSubscriptionState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
    }
    if(data.subscriptionTier === '')
    {
      navigate("/a/account-manager/subscribe")
    }
    setSubscriptionTier(data.subscriptionTier);
    setSubscriptionIsCancelled(data.subscriptionIsCancelled);
    setSubscriptionIsCancelledInStripe(data.subscriptionIsCancelledInStripe);
  };

  const getHelp = (e) => {
    navigate("/help");
  };

  function updateSubscription(upgradeOrDowngrade){

    var status = 0;

    if (upgradeOrDowngrade === "upgrade")
    {
      setIsUpgradeDialogOpen(false)
    }
    if (upgradeOrDowngrade === "downgrade")
    {
      setIsDowngradeDialogOpen(false)
    }

    if (upgradeOrDowngrade !== "upgrade")
    {
      if (upgradeOrDowngrade !== "downgrade")
      {
        return
      }
    }
    
    if (subscriptionTier === '')
    {
      setDialogMessage("You can not modify your Subscription because you are not currently subscribed.")
      setIsDialogOpen(true);
      return;
    }
    
    if (subscriptionIsCancelledInStripe)
    {
      setDialogMessage("Your Subscription has already been cancelled. Please buy a new Subscription.")
      setIsDialogOpen(true);
      return;
    }
    else if (subscriptionIsCancelled)
    {
      setDialogMessage("Your Subscription is scheduled to be cancelled at the end of the current period. Once it is cancelled you can then buy a new Subscription")
      setIsDialogOpen(true);
      return;
    }

    const queryParams = `upgradeOrDowngrade=${upgradeOrDowngrade}`;

    fetch(`${variables.API_URL}UpdateSubscription?${queryParams}`,{
        method:'POST',
        mode: 'cors',
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json',
          'Authorization': 'bearer ' + localStorage.getItem("token")
        }
    })
    .then(res => {
      if (res.status === 401)
      {
        navigate("/a/log-in")
        throw new Error('Unauthorized')
      }
      if (res.status >= 500 && res.status < 600 )
      {
        throw new Error('Server error')
      }
      return res.json();
    })
    .then(data => {
      getSubscription(handleSubscriptionState);
      setDialogMessage("Thank you for subscribing")
      setIsDialogOpen(true);
    })
    .catch(error => {
        console.error('There has been a problem with your request:', error);
    })
  };

  function cancelSubscription(nowOrAfterBillingCycle){

    var status = 0;

    setIsCancellationDialogOpen(false)

    if (nowOrAfterBillingCycle !== "now")
    {
      if (nowOrAfterBillingCycle !== "afterBillingCycle")
      {
        return;
      }
    }

    if (subscriptionTier === '')
    {
      setDialogMessage("You can not cancel a Subscription because you are not currently subscribed.")
      setIsDialogOpen(true);
      return;
    }
    
    if (nowOrAfterBillingCycle === "afterBillingCycle" && subscriptionIsCancelledInStripe)
    {
      setDialogMessage("Your Subscription has already been cancelled. Please buy a new Subscription.")
      setIsDialogOpen(true);
      return;
    }
    else if (nowOrAfterBillingCycle === "afterBillingCycle" && subscriptionIsCancelled)
    {
      setDialogMessage("Your Subscription is already scheduled to be cancelled at the end of the current period. You will continue to benefit from your Subscription until then.")
      setIsDialogOpen(true);
      return;
    }

    if (nowOrAfterBillingCycle === "now" && subscriptionIsCancelledInStripe)
    {
      setDialogMessage("Your Subscription has already been cancelled. Please buy a new Subscription.")
      setIsDialogOpen(true);
      return;
    }

    const queryParams = `nowOrAfterBillingCycle=${nowOrAfterBillingCycle}`;

    fetch(`${variables.API_URL}CancelSubscription?${queryParams}`, {
        method:'POST',
        mode: 'cors',
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json',
          'Authorization': 'bearer ' + localStorage.getItem("token")
        }
    })
    .then(res => {
        if (res.status === 401)
        {
          navigate("/a/log-in")
          throw new Error('Unauthorized')
        }
        if (res.status >= 500 && res.status < 600 )
        {
          throw new Error('Server error')
        }
        return res.json();
    })
    .then(data => {
        if (data.message === "subscription already cancelled")
        {
          setDialogMessage("Your Subscription has already been cancelled. You will continue to benefit from your Subscription until the end of the subscribied period.")
          setIsDialogOpen(true);

        }
        else if (data.message === "subscription cancelled after billing cycle")
        {
          setDialogMessage("Your Subscription has been cancelled. You will continue to benefit from your Subscription until the end of the subscribed period.")
          getSubscription(handleSubscriptionState)
          setIsDialogOpen(true);
        }
    })
    .catch(error => {
        console.error('There has been a problem with your request:', error);
    })
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const verifyCancellation = () => {
      setIsCancellationDialogOpen(true);
  };
  const verifyDowngrade = () => {
      setIsDowngradeDialogOpen(true);
  };
  const verifyUpgrade = () => {
      setIsUpgradeDialogOpen(true);
  };

  const handleCancellationDialogClose = () => {
      setIsCancellationDialogOpen(false);
  };
  const handleDowngradeDialogClose = () => {
      setIsDowngradeDialogOpen(false);
  };
  const handleUpgradeDialogClose = () => {
      setIsUpgradeDialogOpen(false);
  };

  function isLoading() {
    return subscriptionTier === null
  }

  function showContainer(){
    if (isLoading())
    {
      return (
        <LoadingSkeleton/>
      );
    }
    else
    {
      return (
        <>
          <Grid container spacing={2} sx={{marginTop: '4rem'}} justifyContent="center" alignItems="stretch">
            <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
              {
                subscriptionTier === "Personal Subscription"
                ?
                <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, padding: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                  <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                    <CardActionArea onClick={() => verifyUpgrade()}>
                      <Typography gutterBottom variant="h4" component="div" mb={2}>
                        Upgrade <ArrowCircleUpTwoToneIcon fontSize='large'/>
                      </Typography>
                      <Typography gutterBottom variant="h6">
                        Choosing this option will upgrade your Subscription to Enterprise
                      </Typography>
                      <Typography gutterBottom variant="subtitle1" color="text.secondary" mb={2}>
                        When upgrading, you will pay the difference between days spent/days remaining and the cost of an Enterprise Subscription
                      </Typography>
                    </CardActionArea>
                  </CardContent>
                  <Divider variant="fullWidth"/>
                  <CardActionArea onClick={() => getHelp()}>
                    <CardContent sx={{ ml: 2 }}>
                      Click here to find out more about upgrading and the Enterprise Subscription
                    </CardContent>
                  </CardActionArea>
                </Card>
                :
                <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, padding: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                  <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                    <CardActionArea onClick={() => verifyDowngrade()}>
                      <Typography gutterBottom variant="h4" component="div" mb={2}>
                        Downgrade <ArrowDropDownCircleTwoToneIcon fontSize='large'/>
                      </Typography>
                      <Typography gutterBottom variant="h6">
                        Choosing this option will update your plan to a Personal Subscription
                      </Typography>
                      <Typography gutterBottom variant="subtitle1" color="text.secondary" mb={2}>
                        The change is immediate and you will lose all features offered in the Enterprise Subscription. Your account will receive
                        a credit note which will automatically reduce the cost of your next bill
                      </Typography>
                    </CardActionArea>
                  </CardContent>
                  <Divider variant="fullWidth"/>
                  <CardActionArea onClick={() => getHelp()}>
                    <CardContent sx={{ ml: 2 }}>
                      {/* <Link href="/help" variant="body2" style={{ marginTop: '8px' }}> */}
                      Click here to find out more about downgrading, credit notes and the Personal Subscription
                      {/* </Link> */}
                    </CardContent>
                  </CardActionArea>
                </Card>
              }
            </Grid>
            <Grid item sm={12} md={6} sx={{ display: 'flex' }}>
              <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, padding: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                  <CardActionArea onClick={() => verifyCancellation()}>
                    <Typography gutterBottom variant="h4" component="div" mb={2}>
                      Cancel <AutoDeleteTwoToneIcon fontSize='large'/>
                    </Typography>
                    <Typography gutterBottom variant="h6">
                      Cancel your Subscription at the end of it's cycle
                    </Typography>
                    <Typography gutterBottom variant="subtitle1" color="text.secondary" mb={2}>
                      You will continue to benefit from your Subscription for the remaining days and then it will automatically cancel.
                      Any remaining credit notes will be refunded.
                    </Typography>
                  </CardActionArea>
                </CardContent>
                <Divider variant="fullWidth"/>
                <CardActionArea onClick={() => getHelp()}>
                  <CardContent sx={{ ml: 2 }}>
                    {/* <Link href="/help" variant="body2" style={{ marginTop: '8px' }}> */}
                      Click here to find out more about refunds
                    {/* </Link> */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>

          {isDialogOpen && (
            <SharedAlertDialog
              dialogMessage={dialogMessage}
              dialogTitle="Info"
              isOpen={isDialogOpen}
              handleDialogClose={handleDialogClose}
            />
          )}
          
          <Dialog
              open={isCancellationDialogOpen}
              onClose={handleCancellationDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">{"Confirm Cancellation"}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you want to cancel your Subscription?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleCancellationDialogClose} color="primary">
                      No
                  </Button>
                  <Button onClick={() => cancelSubscription("afterBillingCycle")} color="primary" autoFocus>
                      Yes
                  </Button>
              </DialogActions>
          </Dialog>
          <Dialog
              open={isUpgradeDialogOpen}
              onClose={handleUpgradeDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">{"Confirm Upgrade"}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you want to Upgrade your Subscription?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleUpgradeDialogClose} color="primary">
                      No
                  </Button>
                  <Button onClick={() => updateSubscription("upgrade")} color="primary" autoFocus>
                      Yes
                  </Button>
              </DialogActions>
          </Dialog>
          <Dialog
              open={isDowngradeDialogOpen}
              onClose={handleDowngradeDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">{"Confirm Downgrade"}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you want to Downgrade your Subscription?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleDowngradeDialogClose} color="primary">
                      No
                  </Button>
                  <Button onClick={() => updateSubscription("downgrade")} color="primary" autoFocus>
                      Yes
                  </Button>
              </DialogActions>
          </Dialog>
        </>
      )
    }
  }

  return (
    showContainer()
  )

};

export default SubscriptionOptions;