import React, { useState, useEffect } from 'react';
import {variables} from './Variables.js';
import "react-datepicker/dist/react-datepicker.css";
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import BarChartForTotalPullRequestsPerUser from './BarChartForTotalPullRequestsPerUserComponent.jsx';
import BarChartForTotalPullRequestsPerRepsoitoryComponent from './BarChartForTotalPullRequestsPerRepsoitoryComponent';
import LoadingSkeleton from './LoadingSkeleton';
import { getSubscription } from './apiUtils.js';

export const Dashboard = (props) => {

  const navigate = useNavigate();
  const [dataForTotalPullRequestsPerRepositoryChart, setDataForTotalPullRequestsPerRepositoryChart] = useState('');
  const [shouldShowTotalPullRequestsPerRepository, setShouldShowTotalPullRequestsPerRepository] = useState(false);
  const [shouldShowDashboard, setShouldShowDashboard] = useState(false);
  const [dataForTotalPullRequestsPerUserThisWeekChart, setDataForTotalPullRequestsPerUserThisWeekChart] = useState('');
  const [shouldShowTotalPullRequestsPerUserThisWeek, setShouldShowTotalPullRequestsPerUserThisWeek] = useState(false);
  const [dataForTotalPullRequestsPerUserLastWeekChart, setDataForTotalPullRequestsPerUserLastWeekChart] = useState('');
  const [shouldShowTotalPullRequestsPerUserLastWeek, setShouldShowTotalPullRequestsPerUserLastWeek] = useState(false);
  const [shouldShowLastWeeksTotalPrCount, setShouldShowLastWeeksTotalPrCount] = useState(false);
  const [shouldShowLast90DayBusiestRepo, setShouldShowLast90DayBusiestRepo] = useState(false);
  const [thisWeeksTotalPrCount, setThisWeeksTotalPrCount] = useState(0);
  const [lastWeeksTotalPrCount, setLastWeeksTotalPrCount] = useState(0);
  const [repositoryWithHighestPRCountInLast90Days, setRepositoryWithHighestPRCountInLast90Days] = useState(null);
  const [thisMonthHighestContributor, setThisMonthHighestContributor] = useState(null);
  const [lastMonthHighestContributor, setLastMonthHighestContributor] = useState(null);
  const [subscriptionTier, setSubscriptionTier] = useState('');
  const [subscriptionIsCancelled, setSubscriptionIsCancelled] = useState(false);
  const [subscriptionIsCancelledInStripe, setSubscriptionIsCancelledInStripe] = useState(false);

  const currentWeekFirst = getDayOfWeek("currentWeekFirst");
  const currentWeekLast = getDayOfWeek("currentWeekLast");
  const lastWeekFirst = getDayOfWeek("lastWeekFirst");
  const lastWeekLast = getDayOfWeek("lastWeekLast");
  const currentMonthFirst = getDayOfMonth("firstDayThisMonth");
  const currentMonthLast = getDayOfMonth("lastDayThisMonth");
  const lastMonthFirst = getDayOfMonth("firstDayLastMonth");
  const lastMonthLast = getDayOfMonth("lastDayLastMonth");
  const today = new Date();
  const ninetyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 90));

  //call these function for container load
  useEffect(() => {
    document.title = "Azure Git Statistics - Dashboard";
    getSubscription(handleSubscriptionState);
    getTotalPullRequestsPerRepository("currentWeek", currentWeekFirst, currentWeekLast)
    getTotalPullRequestsPerRepository("lastWeek", lastWeekFirst, lastWeekLast)
    getTotalPullRequestsPerRepository("last90Days", ninetyDaysAgo, today)
    getTotalPullRequestsPerUser("currentWeek", currentWeekFirst, currentWeekLast)
    getTotalPullRequestsPerUser("lastWeek", lastWeekFirst, lastWeekLast)
    getTotalPullRequestsPerUser("currentMonth", currentMonthFirst, currentMonthLast)
    getTotalPullRequestsPerUser("lastMonth", lastMonthFirst, lastMonthLast)
  }, []);

  const handleSubscriptionState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
      return
    }
    if (data.subscriptionTier === "" || data.subscriptionIsCancelledInStripe)
    {
      navigate("/a/account-manager")
      return
    }
    if (data.subscriptionIsPastDue)
    {
      navigate("/a/account-manager/subscription-past-due")
      return
    }
    setSubscriptionTier(data.subscriptionTier);
    setSubscriptionIsCancelled(data.subscriptionIsCancelled);
    setSubscriptionIsCancelledInStripe(data.subscriptionIsCancelledInStripe);
  };

  function getDayOfWeek(day) {
    const today = new Date();
    let dayOfWeek = today.getDay(); // Get the day of the week (0-6)
    let dayOffset = 0;
  
    if (dayOfWeek === 0) {
        dayOfWeek = 6; // sunday should be 6
    } else {
        dayOfWeek -= 1; // here monday becomes 0, Tuesday 1, etc
    }
  
    if (day === "currentWeekFirst") {
        dayOffset = 0;
    } else if (day === "currentWeekLast") {
        dayOffset = 6;
    } else if (day === "lastWeekFirst") {
        dayOffset = -7;
    } else if (day === "lastWeekLast") {
        dayOffset = -1;
    }
  
    const targetDay = new Date(today);
    targetDay.setDate(today.getDate() - (dayOfWeek - dayOffset));
  
    return targetDay;
  }

  function getDayOfMonth(day) {
    const today = new Date();
    let targetYear = today.getFullYear();
    let targetMonth = today.getMonth();

    if (day === "firstDayLastMonth" || day === "lastDayLastMonth") {
        if (targetMonth === 0) {
            targetMonth = 11; // December of last year
            targetYear -= 1;
        } else {
            targetMonth -= 1;
        }
    }

    const firstDayOfMonth = new Date(targetYear, targetMonth, 1);
    const lastDayOfMonth = new Date(targetYear, targetMonth + 1, 0);

    if (day === "firstDayThisMonth" || day === "firstDayLastMonth") {
        return firstDayOfMonth;
    } else if (day === "lastDayThisMonth" || day === "lastDayLastMonth") {
        return lastDayOfMonth;
    } else {
        return null; // Handle invalid input
    }
  }

  function getUserWithMostPullRequests(data) {
    let maxPullRequestCount = -1;
    let userWithHighestCount = '';

    data.forEach(item => {
      if (item.pullRequestCount > maxPullRequestCount) {
        maxPullRequestCount = item.pullRequestCount;
        userWithHighestCount = item.azureUserDisplayName;
      }
    });

    return userWithHighestCount;
  }

  function getTotalPullRequestsPerRepository(period, start, end){
    var status = 0;

    const postData = {
      AzureRepositoryNames: [],
      AzureSourceBranches: [],
      AzureTargetBranches: [],
      AzureUsers: [],
      StartDate: start,
      EndDate: end
    };

    fetch(variables.API_URL+'GetTotalPullRequestsPerRepository',{
        method:'POST',
        mode: 'cors',
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json',
          'Authorization': 'bearer ' + localStorage.getItem("token")
        },
        body: JSON.stringify(postData),
    })
    .then(res => {
      if (res.status === 401)
      {
        navigate("/a/log-in")
        throw new Error('Unauthorized')
      }
      if (res.status >= 500 && res.status < 600 )
      {
        throw new Error('Server error')
      }

      return res.json();
    })
    .then(data => {
        if (status === 400)
        {
            alert(data.message);
        }
        else
        {
          if(period === "currentWeek")
          {
            const array = data.message
            const totalPullRequestCount = array.reduce((sum, obj) => sum + obj.pullRequestCount, 0);
            setDataForTotalPullRequestsPerRepositoryChart(data.message);
            setShouldShowTotalPullRequestsPerRepository(true);
            setThisWeeksTotalPrCount(totalPullRequestCount)
          }
          if(period === "lastWeek")
          {
            const array = data.message
            const totalPullRequestCount = array.reduce((sum, obj) => sum + obj.pullRequestCount, 0);
            setLastWeeksTotalPrCount(totalPullRequestCount)
            setShouldShowLastWeeksTotalPrCount(true)
          }
          if(period === "last90Days")
          {
            const array = data.message
            if (array.length !== 0)
            {
              const repositoryWithHighestPRCount = array.reduce((max, current) => max.pullRequestCount > current.pullRequestCount ? max : current);
              setRepositoryWithHighestPRCountInLast90Days(repositoryWithHighestPRCount.repositoryName)
            }
            else
            {
              setRepositoryWithHighestPRCountInLast90Days('N/A')
            }
            setShouldShowLast90DayBusiestRepo(true)
          }
        }
    })
    .catch(error => {
        console.error('There has been a problem with your request:', error);
    })
  }

  function getTotalPullRequestsPerUser(period, start, end){
    var status = 0;

    const postData = {
      AzureRepositoryNames: [],
      AzureSourceBranches: [],
      AzureTargetBranches: [],
      AzureUsers: [],
      StartDate: start,
      EndDate: end
    };

    fetch(variables.API_URL+'GetTotalPullRequestsPerUser',{
        method:'POST',
        mode: 'cors',
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json',
          'Authorization': 'bearer ' + localStorage.getItem("token")
        },
        body: JSON.stringify(postData),
    })
    .then(res => {
      if (res.status === 401)
      {
        navigate("/a/log-in")
        throw new Error('Unauthorized')
      }
      if (res.status >= 500 && res.status < 600 )
      {
        throw new Error('Server error')
      }
      
      return res.json();
    })
    .then(data => {
        if (status === 400)
        {
            alert(data.message);
        }
        else
        {
          if(period === "currentWeek")
          {
            setDataForTotalPullRequestsPerUserThisWeekChart(data.message);
            setShouldShowTotalPullRequestsPerUserThisWeek(true);
          }
          if(period === "lastWeek")
          {
            setDataForTotalPullRequestsPerUserLastWeekChart(data.message);
            setShouldShowTotalPullRequestsPerUserLastWeek(true);
          }
          if(period === "currentMonth")
          {
            setThisMonthHighestContributor(getUserWithMostPullRequests(data.message))
          }
          if(period === "lastMonth")
          {
            setLastMonthHighestContributor(getUserWithMostPullRequests(data.message))
          }
        }
    })
    .catch(error => {
        console.error('There has been a problem with your request:', error);
    })
  }

  function isLoading() {
    return (
      !shouldShowTotalPullRequestsPerUserThisWeek ||
      !shouldShowTotalPullRequestsPerUserLastWeek ||
      !shouldShowTotalPullRequestsPerRepository ||
      !shouldShowLastWeeksTotalPrCount ||
      !shouldShowLast90DayBusiestRepo
    );
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  function showContainer(){
    if (isLoading())
    {
      return (
        <LoadingSkeleton/>
      );
    }
    else
    {
      return (
          <Grid container spacing={2} sx={{marginTop: '4rem'}}>

            <Grid sm={6} md={6} sx={{backgroundColor: '#ffffff', borderRadius: 2, padding: 1}}>
              <Stack direction="column" spacing={1} justifyContent= 'center'>
                {/* 1st Row of Left Column*/}
                <Stack direction="row" spacing={1} justifyContent= 'center'>
                  <Paper elevation={3} style={{ height: '100%', width: '100%', borderRadius: 20, padding: '1rem' }}>
                    {/* Text Part */}
                    <Typography gutterBottom fontFamily="sans-serif" variant="body1" fontWeight={"bold"}>
                    Total Pull Requests This Week
                    </Typography>
                    {/* Text Part */}
                    <Typography fontFamily="sans-serif" variant="body1">
                    {thisWeeksTotalPrCount}
                    </Typography>
                  </Paper>
                  <Paper elevation={3} style={{ height: '100%', width: '100%', borderRadius: 20, padding: '1rem' }}>
                    {/* Text Part */}
                    <Typography gutterBottom fontFamily="sans-serif" variant="body1" fontWeight={"bold"}>
                    Total Pull Requests Last Week
                    </Typography>
                    {/* Text Part */}
                    <Typography fontFamily="sans-serif" variant="body1">
                    {lastWeeksTotalPrCount}
                    </Typography>
                  </Paper>
                </Stack>
                {/* 2nd Row of Left Column*/}
                <Stack direction="row" spacing={1} justifyContent= 'center'>
                  <Paper elevation={3} style={{ height: '100%', width: '100%', borderRadius: 20, padding: '1rem' }}>
                    {/* Text Part */}
                    <Typography gutterBottom fontFamily="sans-serif" variant="body1" fontWeight={"bold"}>
                    Busiest Repository in the last 90 days
                    </Typography>
                    {/* Text Part */}
                    <Typography fontFamily="sans-serif" variant="body1">
                    {repositoryWithHighestPRCountInLast90Days}
                    </Typography>
                  </Paper>
                </Stack>
                {/* 3rd Row of Left Column*/}
                <Stack direction="row" spacing={1} justifyContent= 'center'>
                  <Paper elevation={3} style={{ height: '100%', width: '100%', borderRadius: 20, padding: '1rem' }}>
                    {/* Text Part */}
                    <Typography gutterBottom fontFamily="sans-serif" variant="body1" fontWeight={"bold"}>
                    Most Contributions This Month
                    </Typography>
                    {/* Text Part */}
                    <Typography fontFamily="sans-serif" variant="body1">
                    {thisMonthHighestContributor}
                    </Typography>
                  </Paper>
                  <Paper elevation={3} style={{ height: '100%', width: '100%', borderRadius: 20, padding: '1rem' }}>
                    {/* Text Part */}
                    <Typography gutterBottom fontFamily="sans-serif" variant="body1" fontWeight={"bold"}>
                    Most Contributions Last Month
                    </Typography>
                    {/* Text Part */}
                    <Typography fontFamily="sans-serif" variant="body1">
                    {lastMonthHighestContributor}
                    </Typography>
                  </Paper>
                </Stack>
              </Stack>
            </Grid>

            <Grid sm={6} md={6} sx={{backgroundColor: '#ffffff', borderRadius: 2, padding: 1}}>
              <Item>
                <Typography variant="h6" gutterBottom justifyContent= 'center' align='center'>
                  Total PRs this week across all repositories
                </Typography>
                <BarChartForTotalPullRequestsPerRepsoitoryComponent dataProp={dataForTotalPullRequestsPerRepositoryChart} />
              </Item>
            </Grid>

            <Grid sm={6} md={6} sx={{backgroundColor: '#ffffff', borderRadius: 2, padding: 1}}>
              <Item>
                <Typography variant="h6" gutterBottom justifyContent= 'center' align='center'>
                  Total PRs this week per User
                </Typography>
                <BarChartForTotalPullRequestsPerUser dataProp={dataForTotalPullRequestsPerUserThisWeekChart} />
              </Item>
            </Grid>

            <Grid sm={6} md={6} sx={{backgroundColor: '#ffffff', borderRadius: 2, padding: 1}}>
              <Item>
              <Typography variant="h6" gutterBottom justifyContent= 'center' align='center'>
                  Total PRs last week per User
                </Typography>
                <BarChartForTotalPullRequestsPerUser dataProp={dataForTotalPullRequestsPerUserLastWeekChart} />
              </Item>
            </Grid>

          </Grid>
      );
    }
  }

  return (
    showContainer()
  );

}