import React, {useEffect, useState} from "react";
import {variables} from './Variables.js';
import {useNavigate} from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, Paper } from '@mui/material';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';
import CryptoJS from 'crypto-js';

//props are used for parent comps to send values or fucntions to children
export const PasswordManager = (props) => {

  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [errors, setErrors] = useState({ newPassword: '', newPasswordAgain: '', currentPassword: ''});
  var status = 0;

  useEffect(() => {
      document.title = "Azure Git Statistics - Update Your Password";
  }, []);

  const createPasswordHash = (password) => {
    const salt = process.env.REACT_APP_SALT;
    const hashedPassword = CryptoJS.HmacSHA512(password, salt);
    return hashedPassword.toString();
  };

  const validate = () => {
      let tempErrors = { currentPassword: '', newPassword: '', newPasswordAgain: ''};
      if (!currentPassword) {
          tempErrors.currentPassword = 'Your current password is required';
      }
      if (!newPassword) {
          tempErrors.newPassword = 'A new password is required';
      }
      if (newPassword === " ") {
          tempErrors.newPassword = 'That is not a valid password';
      }
      if (!newPasswordAgain) {
          tempErrors.newPasswordAgain = 'You must enter your new password again';
      }
      if (newPassword !== newPasswordAgain) {
          tempErrors.newPassword = 'Your passwords do not match';
          tempErrors.newPasswordAgain = 'Your passwords do not match';
      }
      setErrors(tempErrors);
      return Object.values(tempErrors).every(x => x === "");
  }
  
  const handleUpdatePassword = (e) => {
    e.preventDefault();
    if (validate())
    {
      setSending(true)
      const currentPasswordHash = createPasswordHash(currentPassword);
      const newPasswordHash = createPasswordHash(newPassword);
      const newPasswordAgainHash = createPasswordHash(newPasswordAgain);

      fetch(
        variables.API_URL+'UpdatePassword',
        {
          method:'POST',
          mode: 'cors',
          headers:{
              'Accept':'application/json',
              'Content-Type':'application/json',
              'Authorization': 'bearer ' + localStorage.getItem("token")
          },
          body:JSON.stringify({
            CurrentPasswordHash: currentPasswordHash,
            NewPasswordHash: newPasswordHash,
            NewPasswordAgainHash: newPasswordAgainHash
          })
        }
      )
      .then(res => {
          if (res.status === 401)
          {
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            throw new Error('Server error')
          }
          return res.json();
        })
        .then(data => {
          setDialogMessage(data.message)
          setIsDialogOpen(true);
          setSending(false)
      })
      .catch(error => {
          console.error('There has been a problem with your request:', error);
      })
    }
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    navigate("/a/account-manager")
  };

  return (
    <>
      <Container maxWidth="sm">
          <Paper elevation={6} style={{ padding: '20px', marginTop: '6rem' }}>
              <Typography variant="h5" gutterBottom textAlign="center">
                  Enter a new password
              </Typography>
              <form onSubmit={handleUpdatePassword} noValidate>
                  <TextField
                      label="Current Password"
                      type="password"
                      fullWidth
                      variant="outlined"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      error={!!errors.currentPassword}
                      helperText={errors.currentPassword}
                      margin="normal"
                      required
                  />
                  <TextField
                      label="New Password"
                      type="password"
                      fullWidth
                      variant="outlined"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      error={!!errors.newPassword}
                      helperText={errors.newPassword}
                      margin="normal"
                      required
                  />
                  <TextField
                      label="New Password Again"
                      type="password"
                      fullWidth
                      variant="outlined"
                      value={newPasswordAgain}
                      onChange={(e) => setNewPasswordAgain(e.target.value)}
                      error={!!errors.newPasswordAgain}
                      helperText={errors.newPasswordAgain}
                      margin="normal"
                      required
                  />
                  <Box textAlign="center" marginTop={2}>
                      <Button variant="contained" color="primary" type="submit" disabled={sending} fullWidth>
                          Save new password
                      </Button>
                  </Box>
              </form>
          </Paper>
      </Container>
      {isDialogOpen && (
        <SharedAlertDialog
          dialogMessage={dialogMessage}
          dialogTitle="Info"
          isOpen={isDialogOpen}
          handleDialogClose={handleDialogClose}
        />
      )}
    </>
  );
}