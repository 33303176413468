import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';
import {variables} from './Variables.js';
import LoadingSkeleton from './LoadingSkeleton.jsx';
import { Grid, Card, CardContent } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import ForwardTwoToneIcon from '@mui/icons-material/ForwardTwoTone';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { getSubscription } from './apiUtils.js';

export const SpecificRepositoryStatistics = () => {
    let { guid } = useParams();

    useEffect(() => {
      getData(guid)
    }, [guid]);
    
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [azureRepositoryData, setAzureRepositoryData] = useState([]);
    const [azureRepositoryName, setAzureRepositoryName] = useState("");
    const [azureOrganisationName, setAzureOrganisationName] = useState("");
    const [azureProjectName, setAzureProjectName] = useState("");
    const [activePullRequests, setActivePullRequests] = useState([]);

    useEffect(() => {
        document.title = "Azure Git Statistics - " + azureRepositoryName;
        getSubscription(handleSubscriptionState);
    }, [azureRepositoryName]);

    const handleSubscriptionState = (data) => {
      if(data.status_code === 401)
      {
        navigate("/a/log-in")
        return;
      }
      if (data.subscriptionTier === "" || data.subscriptionIsCancelledInStripe)
      {
        navigate("/a/account-manager")
        return;
      }
      if (data.subscriptionIsPastDue)
      {
        navigate("/a/account-manager/subscription-past-due")
        return;
      }
      if (data.subscriptionTier === "Personal Subscription")
      {
        navigate("/a/account-manager/subscribe")
      };
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false)
        navigate("/a/statistics/charts/project-statistics")
    };

    async function getData(guid){
      setIsLoading(true)
      await getAzureRepository(guid)
      await getActivePrsForRepository(guid)
      await getUsersOrganisationNameAndProjectName(guid)
      setIsLoading(false);
    } 

    async function getUsersOrganisationNameAndProjectName(guid){
        const queryParams = `azureRepositoryId=${guid}`;
        await fetch(`${variables.API_URL}GetUsersOrganisationNameAndProjectName?${queryParams}`,{
            method:'GET',
            mode: 'cors',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': 'bearer ' + localStorage.getItem("token")
            }
        })
        .then(res => {
          if (res.status === 401)
          {
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            setDialogMessage("Oops! There was a problem. Please try again.")
            setIsDialogOpen(true);
            throw new Error('Server error')
          }
          return res.json();
        })
        .then(data => {
            if (data.message === "Either the Azure Repository Id you are trying to access does not exist or you are not subscribed to it's Project.")
            {
                setDialogMessage(data.message)
                setIsDialogOpen(true);
                return;
            }

            setAzureOrganisationName(data.azureOrganisationName)
            setAzureProjectName(data.azureProjectName)
        })
        .catch(error => {
            console.error('There has been a problem with your request:', error);
        })
    };

    async function getActivePrsForRepository(guid){
        const queryParams = `azureRepositoryId=${guid}`;
        await fetch(`${variables.API_URL}GetActivePrsForAzureRepository?${queryParams}`,{
            method:'GET',
            mode: 'cors',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': 'bearer ' + localStorage.getItem("token")
            }
        })
        .then(res => {
          if (res.status === 401)
          {
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            setDialogMessage("Oops! There was a problem. Please try again.")
            setIsDialogOpen(true);
            throw new Error('Server error')
          }
          return res.json();
        })
        .then(data => {
            if (data.message === "Either the Azure Repository Id you are trying to access does not exist or you are not subscribed to it's Project.")
            {
                setDialogMessage(data.message)
                setIsDialogOpen(true);
                return;
            }

            const sortedData = data.activePullRequests.sort((a, b) => b.CreatedDate + a.CreatedDate);
            setActivePullRequests(sortedData)
        })
        .catch(error => {
            console.error('There has been a problem with your request:', error);
        })
    };

    async function getAzureRepository(guid){
        const queryParams = `azureRepositoryId=${guid}`;
        await fetch(`${variables.API_URL}GetAzureRepository?${queryParams}`,{
            method:'GET',
            mode: 'cors',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': 'bearer ' + localStorage.getItem("token")
            }
        })
        .then(res => {
          if (res.status === 401)
          {
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            setDialogMessage("Oops! There was a problem. Please try again.")
            setIsDialogOpen(true);
            throw new Error('Server error')
          }
          return res.json();
        })
        .then(data => {
            if (data.message === "Either the Azure Repository Id you are trying to access does not exist or you are not subscribed to it's Project.")
            {
                setDialogMessage(data.message)
                setIsDialogOpen(true);
                return;
            }

            const sortedData = data.azureRepositoryData.sort((a, b) => b.year + a.year);

            for (let i = 0; i < sortedData.length; i++) {
                if (i === 0) {
                    sortedData[i].changePercentage = 0;
                } else {
                    let percentageChange = calculatePercentageChange(sortedData[i - 1].pullRequestCount, sortedData[i].pullRequestCount);
                    sortedData[i].changePercentage = percentageChange.toFixed(2);
                }
            }
            setAzureRepositoryData(sortedData)
            setAzureRepositoryName(data.azureRepositoryName)
        })
        .catch(error => {
            console.error('There has been a problem with your request:', error);
        })
    };

    function getUpOrDownArrow(changePercentage) {
        if (changePercentage === 0) return null;
        if (changePercentage >= 0) return <KeyboardDoubleArrowUpIcon fontSize='large' color='success'/>;
        if (changePercentage <= 0) return <KeyboardDoubleArrowDownIcon fontSize='large' color='error'/>;
    }

    function calculatePercentageChange(oldNumber, newNumber) {
        if (oldNumber === 0) return 0;
        return ((newNumber - oldNumber) / oldNumber) * 100;
    }

    function getHeaderForCard(year) {
      return (
          <span><strong>{year.substring(0, 4)}</strong></span>
      );
    }

    function formatDate(dateString) {
      const date = new Date(dateString);
    
      // Get the month (in short form), day of the month, and year from the date object
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      const year = date.getFullYear();
    
      // Combine the parts together
      return `${month} ${day} ${year}`;
    }
    
    function openPullRequest(id) {
      var link = 'https://' + azureOrganisationName + '.visualstudio.com/' + azureProjectName + '/_git/' + azureRepositoryName + '/pullrequest/' + id
      window.open(link, '_blank')
    }

    function showContainer(){
      if (isLoading)
      {
        return (
          <LoadingSkeleton/>
        );
      }
      else
      {
        return (
            <>
                <Container maxWidth="md">
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={15} marginBottom={4}>
                      <Typography variant="h3" gutterBottom justifyContent= 'center' align='center' sx={{fontFamily: 'Open Sans, sans-serif'}}>
                        {azureRepositoryName}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={6} marginBottom={4}>
                      <Typography variant="h4" gutterBottom justifyContent= 'center' align='center' sx={{fontFamily: 'Open Sans, sans-serif'}}>
                        Active Pull Requests
                      </Typography>
                    </Box>
                </Container>

                {
                  activePullRequests.length === 0
                  ?
                  <Alert severity="info" style={{ marginBottom: '10px' }}>
                    There are currently no active pull requests for this repository
                  </Alert>
                  :
                  <TableContainer component={Paper} sx={{ maxWidth: '100%', width: 'auto', margin: 'auto' }}>
                      <Table aria-label="simple table">
                          <TableHead>
                              <TableRow>
                                  <TableCell><strong>Pull Request ID</strong></TableCell>
                                  <TableCell><strong>Created Date</strong></TableCell>
                                  <TableCell><strong>Created By</strong></TableCell>
                                  <TableCell align="right"><strong>{"Source"} <ForwardTwoToneIcon/> {"Target"}</strong></TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {activePullRequests.map((row) => (
                                  <TableRow
                                      key={row.pullRequestId}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{'&:hover': { 
                                            cursor: 'pointer', 
                                            textDecoration: 'none',
                                            color: 'none',
                                            background: '#d1e4ff'
                                        }}}
                                        onClick={() => openPullRequest(row.pullRequestId)}
                                      >
                                        <OpenInNewOutlinedIcon color='info'/> {row.pullRequestId}
                                      </TableCell>
                                      <TableCell>{formatDate(row.createdDate)}</TableCell>
                                      <TableCell>{row.createdBy}</TableCell>
                                      <TableCell align="right"> <Chip sx={{m: "0.1rem"}} label={row.sourceRefName.substring(11)}/> <ForwardTwoToneIcon/> <Chip sx={{m: "0.1rem"}} label={row.targetRefName.substring(11)}/></TableCell>
                                  </TableRow>
                              ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
                }

                <Container maxWidth="md">
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={10} marginBottom={4}>
                      <Typography variant="h4" gutterBottom justifyContent= 'center' align='center' sx={{fontFamily: 'Open Sans, sans-serif'}}>
                        Total Pull Requests per year
                      </Typography>
                    </Box>
                    <Grid container mt={"1rem"} spacing={2} alignItems="stretch">
                      {azureRepositoryData.map((repository, index) => (
                        <Grid item xs={12} md={azureRepositoryData.length === 1 ? 12 : 4} key={repository.year} sx={{ display: 'flex' }}>
                          <Card
                            raised
                            sx={{
                              backgroundColor: '#ffffff',
                              borderRadius: 2,
                              padding: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%', 
                              width: '100%',
                              transition: 'background-color 0.3s ease-in-out',
                            }}>
                            <CardHeader
                              action={
                                <IconButton aria-label="settings">
                                  {getUpOrDownArrow(repository.changePercentage)} {repository.changePercentage} %
                                </IconButton>
                              }
                              title={getHeaderForCard(repository.year)}
                              subheader={repository.pullRequestCount + ' prs'}
                            />
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                </Container>
                  
                
                
                {isDialogOpen && (
                    <SharedAlertDialog
                    dialogMessage={dialogMessage}
                    dialogTitle="Info"
                    isOpen={isDialogOpen}
                    handleDialogClose={handleDialogClose}
                    />
                )}
            </>
        );
      }
  
    }

    return(
      showContainer()
    )
};