import React, {useState, useEffect} from "react"
import {variables} from './Variables.js';
import {useNavigate} from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, Paper, Alert } from '@mui/material';
import CryptoJS from 'crypto-js';

//props are used for parent comps to send values or fucntions to children
export const Register = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({ email: '', username: '', password: '' });
  var status = 0;

  useEffect(() => {
      document.title = "Azure Git Statistics - Sign Up";
  }, []);

  const createPasswordHash = (password) => {
    const salt = process.env.REACT_APP_SALT;
    const hashedPassword = CryptoJS.HmacSHA512(password, salt);
    return hashedPassword.toString();
  };

  const validate = () => {
      let tempErrors = { email: '', username: '', password: '' };
      if (!email) {
          tempErrors.email = 'Email is required';
      }
      if (!email.includes("@")) {
          tempErrors.email = 'Please provide a valid email address';
      }
      if (!username) {
          tempErrors.username = 'Username is required';
      }
      if (!password) {
          tempErrors.password = 'Password is required';
      }
      setErrors(tempErrors);
      return Object.values(tempErrors).every(x => x === "");
  }
  
  const handleRegister = (e) => {
    e.preventDefault();
    if (validate())
    {
      setSending(true);
      const passwordHash = createPasswordHash(password);
      let emailInternal = email.toLowerCase();

      fetch(
        variables.API_URL+'Register',
        {
          method:'POST',
          mode: 'cors',
          headers:{
              'Accept':'application/json',
              'Content-Type':'application/json'
          },
          body:JSON.stringify({
            Username: username,
            Email: emailInternal,
            PasswordHash: passwordHash
          })
        }
      )
      .then(res => {
        if (res.status === 401)
        {
          setSending(false);
          navigate("/a/log-in")
          throw new Error('Unauthorized')
        }
        if (res.status >= 500 && res.status < 600 )
        {
          throw new Error('Server error')
        }
        return res.json();
      })
      .then(data => {
          alert(data.message);
          setSending(false);
          if (status !== 400 && data.message !== 'An account with that Username or Email already exists.')
          {
            navigate("/a/log-in");
          }
      })
      .catch(error => {
          console.error('There has been a problem with your request:', error);
      })
    }
  }

  return (
    <Container maxWidth="sm">
        <Paper elevation={6} style={{ padding: '20px', marginTop: '6rem' }}>
            <Typography variant="h5" gutterBottom textAlign="center">
                Sign Up
            </Typography>
            <form onSubmit={handleRegister} noValidate>
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                    margin="normal"
                    required
                />
                <Alert severity="warning" style={{ marginBottom: '10px' }}>
                    Your email address <strong><u>must</u></strong> be the same email address used for Azure DevOps. <strong>This cannot be changed later!</strong>
                </Alert>
                <TextField
                    label="Username"
                    fullWidth
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    error={!!errors.username}
                    helperText={errors.username}
                    margin="normal"
                    required
                />
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!errors.password}
                    helperText={errors.password}
                    margin="normal"
                    required
                />
                <Box textAlign="center" marginTop={2}>
                    <Button variant="contained" color="primary" type="submit" disabled={sending} fullWidth>
                        Sign Up
                    </Button>
                </Box>
            </form>
        </Paper>
    </Container>
  );
}
