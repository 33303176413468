import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {useNavigate} from 'react-router-dom';
import { getSubscription } from './apiUtils.js';
import { getUser } from './apiUtils.js';
import LoadingSkeleton from './LoadingSkeleton';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';

export const AccountManager = () => {

  const navigate = useNavigate();

  const [selectedContainer, setSelectedContainer] = useState('');
  const [subscriptionTier, setSubscriptionTier] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [subscriptionIsCancelled, setSubscriptionIsCancelled] = useState(false);
  const [subscriptionIsCancelledInStripe, setSubscriptionIsCancelledInStripe] = useState(false);
  const [isGettingSubscription, setIsGettingSubscription] = useState(true);
  const [isGettingUser, setIsGettingUser] = useState(true);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    document.title = "Azure Git Statistics - Your Account";
    getSubscription(handleSubscriptionState);
    getUser(handleUserState);
  }, []);

  const handleSubscriptionState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
    }
    setSubscriptionTier(data.subscriptionTier);
    setSubscriptionIsCancelled(data.subscriptionIsCancelled);
    setSubscriptionIsCancelledInStripe(data.subscriptionIsCancelledInStripe);
    setIsGettingSubscription(false);
  };

  const handleUserState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
    }
    setEmail(data.email);
    setUsername(data.username);
    setIsGettingUser(false);
  };

  function redirectToStripeCustomerPortal() {
    let customerPortalLink = process.env.NODE_ENV === 'production' ?
      process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_PROD : 
      process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_DEV;
  
    if (customerPortalLink) {
      window.open(customerPortalLink, '_blank')
    } else {
      console.error('Stripe Customer Portal link is not defined');
    }
  }
  
  function handleAzureDetailsContainer() {
    if (subscriptionIsCancelledInStripe) {
      setDialogMessage(`Your ${subscriptionTier} has been cancelled. Please buy a new Subscription to continue using Azure Git Statistics`);
      setIsDialogOpen(true);
    } else if (subscriptionTier === '') {
      getSubscription(handleSubscriptionState)

      if (subscriptionTier === '') {
        setDialogMessage("To set your Azure Details you need to have a subscription. If you have recently bought a subscription, we may still be setting up your account. Please try again.");
        setIsDialogOpen(true);
      }
    } else {
      setSelectedContainer('azureDetailsContainer');
    }
  }
  
  function handleCardClick(containerToShow) {
    switch (containerToShow) {
      case 'azureDetailsContainer':
        handleAzureDetailsContainer();
        break;
      case 'goToStripeCustomerPortal':
        redirectToStripeCustomerPortal();
        break;
      default:
        setSelectedContainer(containerToShow);
    }
  }

  function getSubscribeWithStripeContainer(){
    navigate("/a/account-manager/subscribe");
  }

  function getSubscriptionOptionsContainer(){
    navigate("/a/account-manager/subscription");
  }
  
  function getAzureDetailsContainer(){
    navigate("/a/account-manager/azure-details");
  }

  function getPasswordManagerContainer(){
    navigate("/a/account-manager/password-manager");
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  function showContainer(){
    if (isGettingSubscription || isGettingUser )
    {
      return (
        <LoadingSkeleton/>
      );
    }
    else {
      if (selectedContainer === 'subscribeWithStripeContainer'){
        getSubscribeWithStripeContainer()
      }
      if (selectedContainer === 'subscriptionOptionsContainer'){
        getSubscriptionOptionsContainer()
      }
      else if (selectedContainer === 'azureDetailsContainer'){
        getAzureDetailsContainer()
      }
      else if (selectedContainer === 'passwordManagerContainer'){
        getPasswordManagerContainer()
      }
      else {
        return (
          <>
            <Grid container spacing={2} sx={{marginTop: '4rem'}} justifyContent="center" alignItems="stretch">
              <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, padding: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                  <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" component="div">
                      <strong>Email:</strong> {email}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      <strong>Username:</strong> {username}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                <CardActionArea onClick={() => handleCardClick('passwordManagerContainer')}>
                  <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, padding: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                    <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="div">
                        Password
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        Update your password
                      </Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
              <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                <CardActionArea onClick={() => handleCardClick('azureDetailsContainer')}>
                  <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, padding: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                    <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="div">
                        Your Azure Details
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        Your Organisation, Projects, Personal Access Token
                      </Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
              <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                {
                  subscriptionIsCancelledInStripe || subscriptionTier === ""
                  ?
                  <CardActionArea onClick={() => handleCardClick('subscribeWithStripeContainer')}>
                    <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, padding: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                      <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="div">
                          Subscription
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          Buy a Subscription
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardActionArea>
                  :
                  <CardActionArea onClick={() => handleCardClick('subscriptionOptionsContainer')}>
                    <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, padding: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                      <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="div">
                          Subscription
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          Update or Cancel Your Subscription
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardActionArea>
                }
              </Grid>
              <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                {
                  subscriptionIsCancelledInStripe || subscriptionTier === ""
                  ?
                  null
                  :
                  <CardActionArea onClick={() => handleCardClick('goToStripeCustomerPortal')}>
                    <Card sx={{ backgroundColor: '#ffffff', borderRadius: 2, padding: 1, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                      <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="div">
                          Customer Payment Portal
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          Update payment methods, view all your invoices, modify your billing address
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardActionArea>
                }
              </Grid>
            </Grid>

            {isDialogOpen && (
              <SharedAlertDialog
                dialogMessage={dialogMessage}
                dialogTitle="Info"
                isOpen={isDialogOpen}
                handleDialogClose={handleDialogClose}
              />
            )}
          </>
        );
      }
    }

  }

  return(
    showContainer()
  )

};

export default AccountManager;
