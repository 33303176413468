import {BrowserRouter, Routes, Route} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {Dashboard} from './Dashboard';
import {SharedLayout} from './SharedLayout';
import {HomeLayout} from './HomeLayout';
import {SubscribeWithStripe} from './SubscribeWithStripe';
import {SubscriptionOptions} from './SubscriptionOptions';
import {AccountManager} from './AccountManager';
import {PasswordManager} from './PasswordManager';
import {AzureDetails} from './AzureDetails';
import {PullRequestImportTool} from './PullRequestImportTool';
import {TotalPullRequestsPerUser} from './TotalPullRequestsPerUser';
import {TotalPullRequestsPerRepository} from './TotalPullRequestsPerRepository';
import {TotalPullRequestsPerMonth} from './TotalPullRequestsPerMonth';
import {TotalApprovalsPerUser} from './TotalApprovalsPerUser';
import {LogOut} from './LogOut';
import {LogIn} from './LogIn';
import {Register} from './Register';
import {Homepage} from './Homepage';
import { Help } from './Help';
import { ContactUs } from './ContactUs';
import { Suggestions } from './Suggestions';
import { PricingOptions } from './PricingOptions';
import { TermsAndPrivacy } from './TermsAndPrivacy';
import { Product } from './Product';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { ProductPersonal } from './ProductPersonal';
import { ProductEnterprise } from './ProductEnterprise';
import { SubscriptionPastDue } from './SubscriptionPastDue';
import { ProjectStatistics } from './ProjectStatistics';
import { SpecificProjectStatistics } from './SpecificProjectStatistics';
import { SpecificRepositoryStatistics } from './SpecificRepositoryStatistics';
import { ScrollToTop } from './ScrollToTop';

function App() {

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  //call these function for container load
  useEffect(() => {
    determineIfUserIsSignedIn();
  }, []);

  function determineIfUserIsSignedIn(){
    if (localStorage.getItem('token') === null){
      setIsUserLoggedIn(false);
    } else {
      setIsUserLoggedIn(true);
    }
  }

  const updateIsUserLoggedIn = (newValue) => {
    setIsUserLoggedIn(newValue);
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeLayout isUserLoggedIn={isUserLoggedIn} updateIsUserLoggedIn={updateIsUserLoggedIn}/>}>
            <Route path="/" element={<Homepage />} />
            <Route path="/help" element={<Help />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/suggestions" element={<Suggestions />} />
            <Route path="/pricing" element={<PricingOptions />} />
            <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />
            <Route path="/product" element={<Product />} />
            <Route path="/product/personal" element={<ProductPersonal />} />
            <Route path="/product/enterprise" element={<ProductEnterprise />} />
          </Route>
          <Route path="/a" element={<SharedLayout isUserLoggedIn={isUserLoggedIn} updateIsUserLoggedIn={updateIsUserLoggedIn}/>} >
            <Route path="/a/dashboard" element={<Dashboard />} />
            <Route path="/a/statistics/charts/total-prs-per-user" element={<TotalPullRequestsPerUser />} />
            <Route path="/a/statistics/charts/total-approvals-per-user" element={<TotalApprovalsPerUser />} />
            <Route path="/a/statistics/charts/total-prs-per-repository" element={<TotalPullRequestsPerRepository />} />
            <Route path="/a/statistics/charts/total-prs-per-month" element={<TotalPullRequestsPerMonth />} />
            <Route path="/a/statistics/charts/project-statistics" element={<ProjectStatistics />} />
            <Route path="/a/statistics/charts/project-statistics/:guid" element={<SpecificProjectStatistics />} />
            <Route path="/a/statistics/charts/repository-statistics/:guid" element={<SpecificRepositoryStatistics />} />
            <Route path="/a/account-manager" element={<AccountManager />} />
            <Route path="/a/account-manager/subscribe" element={<SubscribeWithStripe />} />
            <Route path="/a/account-manager/subscription" element={<SubscriptionOptions />} />
            <Route path="/a/account-manager/subscription-past-due" element={<SubscriptionPastDue />} />
            <Route path="/a/account-manager/password-manager" element={<PasswordManager />} />
            <Route path="/a/account-manager/azure-details" element={<AzureDetails />} />
            <Route path="/a/pull-request-import-tool" element={<PullRequestImportTool />} />
            <Route path="/a/log-out" element={<LogOut setIsUserLoggedIn={setIsUserLoggedIn}/>} />
            <Route path="/a/log-in" element={<LogIn setIsUserLoggedIn={setIsUserLoggedIn}/>} />
            <Route path="/a/register" element={<Register />} />
            <Route path="/a/forgot-password" element={<ForgotPassword />} />
            <Route path="/a/reset-password" element={<ResetPassword />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;