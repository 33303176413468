import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';
import {variables} from './Variables.js';
import LoadingSkeleton from './LoadingSkeleton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import LocalFireDepartmentSharp from '@mui/icons-material/LocalFireDepartmentSharp';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ForwardTwoToneIcon from '@mui/icons-material/ForwardTwoTone';
import { getSubscription } from './apiUtils.js';

export const SpecificProjectStatistics = () => {
    let { guid } = useParams();

    useEffect(() => {
      getData(guid)
    }, [guid]);
    
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [azureRepositoryData, setAzureRepositoryData] = useState([]);
    const [azureProjectName, setAzureProjectName] = useState("");
    const [activePullRequests, setActivePullRequests] = useState([]);

    useEffect(() => {
        document.title = "Azure Git Statistics - " + azureProjectName;
        getSubscription(handleSubscriptionState);
    }, [azureProjectName]);

    const handleSubscriptionState = (data) => {
      if(data.status_code === 401)
      {
        navigate("/a/log-in")
        return;
      }
      if (data.subscriptionTier === "" || data.subscriptionIsCancelledInStripe)
      {
        navigate("/a/account-manager")
        return;
      }
      if (data.subscriptionIsPastDue)
      {
        navigate("/a/account-manager/subscription-past-due")
        return;
      }
      if (data.subscriptionTier === "Personal Subscription")
      {
        navigate("/a/account-manager/subscribe")
      };
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false)
        navigate("/a/statistics/charts/project-statistics")
    };

    async function getData(guid){
      setIsLoading(true)
      await getAzureProject(guid)
      await getActivePrsForProject(guid)
      // await getUsersOrganisationNameAndProjectName(guid)
      setIsLoading(false);
    } 

    function calculateDiffInDays(time) {
      const givenTime = new Date(time);
      const now = new Date();
      const timeDiff = now - givenTime;
      return timeDiff / (1000 * 60 * 60 * 24);
    }
    
    function getIcon(time) {
      const diffInDays = calculateDiffInDays(time);
    
      // Decide the color based on the time difference
      let color;
      if (diffInDays <= 2) {
          color = "#FF5733"; // Red
      } else if (diffInDays <= 7) {
          color = "#FF8D33"; // Orange-Red
      } else if (diffInDays <= 14) {
          color = "#FFC300"; // Orange
      } else if (diffInDays <= 30) {
          color = "#FFD633"; // Yellow-Orange
      } else if (diffInDays <= 50) {
          color = "#FFFF33"; // Yellow
      } else if (diffInDays <= 90) {
          color = "#BFFF33"; // Yellow-Green
      } else if (diffInDays <= 120) {
          color = "#76FF33"; // Green
      } else if (diffInDays <= 180) {
          color = "#33FFD7"; // Light Blue
      } else if (diffInDays <= 365) {
          color = "#339BFF"; // Blue
      } else {
          color = "#3339FF"; // Dark Blue
      }
    
      return <LocalFireDepartmentSharp fontSize='large' sx={{color: color}} />;
    }
    
    function getDays(time) {
      const diffInDays = calculateDiffInDays(time);

      if (diffInDays < 1) {
        return "Today"
      } else if (diffInDays >= 1 && diffInDays < 2) {
          return Math.floor(diffInDays) + " day ago"
      } else if (diffInDays > 300000) {
          return "No completed pull requests, ever"
      } else {
        return Math.floor(diffInDays) + " days ago"
      }
    }

    function formatDate(dateString) {
      const date = new Date(dateString);
    
      // Get the month (in short form), day of the month, and year from the date object
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      const year = date.getFullYear();
    
      // Combine the parts together
      return `${month} ${day} ${year}`;
    }
    
    function openPullRequest(azureOrganisationName, azureProjectName, azureRepositoryName, id) {
      var link = 'https://' + azureOrganisationName + '.visualstudio.com/' + azureProjectName + '/_git/' + azureRepositoryName + '/pullrequest/' + id
      console.log(link)
      window.open(link, '_blank')
    }

    async function getActivePrsForProject(guid){
        const queryParams = `azureProjectId=${guid}`;
        await fetch(`${variables.API_URL}GetActivePrsForAzureProject?${queryParams}`,{
            method:'GET',
            mode: 'cors',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': 'bearer ' + localStorage.getItem("token")
            }
        })
        .then(res => {
          if (res.status === 401)
          {
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            setDialogMessage("Oops! There was a problem. Please try again.")
            setIsDialogOpen(true);
            throw new Error('Server error')
          }
          return res.json();
        })
        .then(data => {
            if (data.message === "Either the Azure Project Id you are trying to access does not exist or you are not subscribed to it.")
            {
                setDialogMessage(data.message)
                setIsDialogOpen(true);
                return;
            }
            const sortedData = data.activePullRequests.sort((a, b) => b.CreatedDate + a.CreatedDate);
            setActivePullRequests(sortedData)
        })
        .catch(error => {
            console.error('There has been a problem with your request:', error);
        })
    };

    async function getAzureProject(guid){
        const queryParams = `azureProjectId=${guid}`;
        await fetch(`${variables.API_URL}GetAzureProject?${queryParams}`,{
            method:'GET',
            mode: 'cors',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
                'Authorization': 'bearer ' + localStorage.getItem("token")
            }
        })
        .then(res => {
          if (res.status === 401)
          {
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            setDialogMessage("Oops! There was a problem. Please try again.")
            setIsDialogOpen(true);
            throw new Error('Server error')
          }
          return res.json();
        })
        .then(data => {
            if (data.message === "Either the Azure Project Id you are trying to access does not exist or you are not subscribed to it.")
            {
                setDialogMessage(data.message)
                setIsDialogOpen(true);
                setIsLoading(false);
                return;
            }
            const sortedData = data.azureRepositoryData.sort((a, b) => b.pullRequestCount - a.pullRequestCount);
            setAzureRepositoryData(sortedData)
            setAzureProjectName(data.azureProjectName)
        })
        .catch(error => {
            console.error('There has been a problem with your request:', error);
        })
    };

    const handleRowClick = (azureRepositoryId) => {
      navigate(`/a/statistics/charts/repository-statistics/${azureRepositoryId}`); // Navigate to the URL
    };

    function showContainer(){
      if (isLoading)
      {
        return (
          <LoadingSkeleton/>
        );
      }
      else
      {
        return (
            <>
                <Container maxWidth="md">
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={15} marginBottom={4}>
                      <Typography variant="h3" gutterBottom justifyContent= 'center' align='center' sx={{fontFamily: 'Open Sans, sans-serif'}}>
                        {azureProjectName}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={6} marginBottom={4}>
                      <Typography variant="h4" gutterBottom justifyContent= 'center' align='center' sx={{fontFamily: 'Open Sans, sans-serif'}}>
                        Active Pull Requests
                      </Typography>
                    </Box>
                </Container>
                {
                  activePullRequests.length === 0
                  ?
                  <Alert severity="info" style={{ marginBottom: '10px' }}>
                    There are currently no active pull requests for this repository
                  </Alert>
                  :
                  <TableContainer component={Paper} sx={{ maxWidth: '100%', width: 'auto', margin: 'auto' }}>
                      <Table aria-label="simple table">
                          <TableHead sx={{backgroundColor: "#d6d6d6"}}>
                              <TableRow>
                                  <TableCell><strong>Pull Request ID</strong></TableCell>
                                  <TableCell><strong>Repository</strong></TableCell>
                                  <TableCell><strong>Created Date</strong></TableCell>
                                  <TableCell><strong>Created By</strong></TableCell>
                                  <TableCell align="right"><strong>{"Source"} <ForwardTwoToneIcon/> {"Target"}</strong></TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {activePullRequests.map((row, index) => (
                                  <TableRow
                                    key={row.pullRequestId}
                                    sx={{ 
                                      '&:last-child td, &:last-child th': { border: 0 },
                                      backgroundColor: index % 2 === 1 ? '#f2f2f2' : ''
                                    }}
                                  >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{'&:hover': { 
                                            cursor: 'pointer', 
                                            textDecoration: 'none',
                                            color: 'none',
                                            background: '#d1e4ff'
                                        }}}
                                        // onClick={() => openPullRequest(azureOrganisationName, azureProjectName, azureRepositoryName, id)}
                                        onClick={() => openPullRequest(row.organisationName, row.projectName, row.repositoryName, row.pullRequestId)}
                                      >
                                        <OpenInNewOutlinedIcon color='info'/> {row.pullRequestId}
                                      </TableCell>
                                      <TableCell>{row.repositoryName}</TableCell>
                                      <TableCell>{formatDate(row.createdDate)}</TableCell>
                                      <TableCell>{row.createdBy}</TableCell>
                                      <TableCell align="right"> <Chip sx={{m: "0.1rem"}} label={row.sourceRefName.substring(11)}/> <ForwardTwoToneIcon/> <Chip sx={{m: "0.1rem"}} label={row.targetRefName.substring(11)}/></TableCell>
                                  </TableRow>
                              ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
                }
                <Container maxWidth="md">
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={10}>
                      <Typography variant="h4" gutterBottom justifyContent= 'center' align='center' sx={{fontFamily: 'Open Sans, sans-serif'}}>
                        All time Pull Request Count
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginBottom={4}>
                      <Typography variant="body1" gutterBottom justifyContent= 'center' align='center' sx={{fontFamily: 'Open Sans, sans-serif'}}>
                        <i>*the results below include all source and target branches, for completed pull requests only</i>
                      </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead sx={{backgroundColor: "#d6d6d6"}}>
                          <TableRow>
                            <TableCell><strong>Repository</strong></TableCell>
                            <TableCell><strong>Pull Requests</strong></TableCell>
                            <TableCell align="right"><strong>Most recent contribution</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {azureRepositoryData.map((repo, index) => (
                            <TableRow
                              key={index}
                              sx={{ 
                                '&:last-child td, &:last-child th': { border: 0 },
                                backgroundColor: index % 2 === 1 ? '#f2f2f2' : '',
                                '&:hover': { 
                                  cursor: 'pointer', 
                                  textDecoration: 'none',
                                  background: '#d1e4ff'
                                }
                              }}
                              onClick={() => handleRowClick(repo.azureRepositoryId)}
                            >
                              <TableCell component="th" scope="row">
                                {repo.azureRepositoryName}
                              </TableCell>
                              <TableCell>{repo.pullRequestCount}</TableCell>
                              <TableCell align="right">{getDays(repo.lastTimeCompleted)} {getIcon(repo.lastTimeCompleted)} <OpenInNewOutlinedIcon color='info'/></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                </Container>

                {isDialogOpen && (
                    <SharedAlertDialog
                    dialogMessage={dialogMessage}
                    dialogTitle="Info"
                    isOpen={isDialogOpen}
                    handleDialogClose={handleDialogClose}
                    />
                )}
            </>
        );
      }
  
    }

    return(
      showContainer()
    )
};