import React, { useState, useEffect } from 'react';
import { getAzureDetails } from './apiUtils.js';
import { getSubscription } from './apiUtils.js';
import {variables} from './Variables.js';
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Button from '@mui/material/Button';
import LoadingSkeleton from './LoadingSkeleton.jsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { CardActions } from '@mui/material';
import { LinearProgress } from '@mui/material';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';

export const ProjectStatistics = () => {

  const navigate = useNavigate();
  const [azureProjects, setAzureProjects] = useState('');
  const [importing, setImporting] = useState(false);
  const [importingProjectId, setImportingProjectId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
 
  useEffect(() => {
    document.title = "Azure Git Statistics - Project Statistics";
    getAzureDetails(handleAzureDetailState);
    getSubscription(handleSubscriptionState);
  }, []); 
  
  const handleAzureDetailState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
      return
    }
    setAzureProjects(data.azureProjects);
  };

  const handleSubscriptionState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
      return;
    }
    if (data.subscriptionTier === "" || data.subscriptionIsCancelledInStripe)
    {
      navigate("/a/account-manager")
      return;
    }
    if (data.subscriptionIsPastDue)
    {
      navigate("/a/account-manager/subscription-past-due")
      return;
    }
    if (data.subscriptionTier === "Personal Subscription")
    {
      navigate("/a/account-manager/subscribe")
    };
  };

  function doesUserHaveAzureProjects(azureProjects){
    return azureProjects !== null && azureProjects.length > 0;
  }

  function getStatisticsForProject(azureProject){
    if (!azureProject || !azureProject.azureProjectId)
    {
      console.error('Invalid project data');
      return;
    }
    navigate(`/a/statistics/charts/project-statistics/${azureProject.azureProjectId}`);
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {
        azureProjects === ''
        ?
          <LoadingSkeleton/>
        :
        doesUserHaveAzureProjects(azureProjects) ?
        (
          <Container maxWidth="md">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={10}>
              <Typography variant="h3" gutterBottom justifyContent= 'center' align='center' sx={{fontFamily: 'Open Sans, sans-serif'}}>
                My Subscribed Projects
              </Typography>
            </Box>
            <Grid container spacing={2} sx={{mb: 4, mt: "4rem", mr: 4, ml: 4, }} alignItems="stretch">
              {azureProjects.map((project, index) => (
                <Grid item xs={12} md={azureProjects.length === 1 ? 12 : 3} key={project.id} sx={{ display: 'flex' }}>
                  <Card
                    raised
                    sx={{
                      backgroundColor: '#ffffff',
                      borderRadius: 2,
                      padding: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%', 
                      width: '100%',
                      transition: 'background-color 0.3s ease-in-out',
                      ':hover': {
                        background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                                    radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                                    white`,
                      }
                    }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom width="100%" variant="h6" align="center" sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                        {project.azureProjectName}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth 
                        size="small"
                        variant="contained"
                        color="primary"
                        startIcon={<CloudDownloadIcon />}
                        disabled={importing}
                        onClick={() => getStatisticsForProject(project)}
                      >
                        Get Stats
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        )
        :
        (
          
          <Alert severity="warning" style={{ marginTop: '5rem' }}>
            You have not selected any Projects, please visit Your Azure Details in Account Manager to select some.
          </Alert>
        )
      }
      {isDialogOpen && (
        <SharedAlertDialog
          dialogMessage={dialogMessage}
          dialogTitle="Info"
          isOpen={isDialogOpen}
          handleDialogClose={handleDialogClose}
        />
      )}
    </>
  );
};