import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';

export const TermsAndPrivacy = (props) => {

  useEffect(() => {
      document.title = "Azure Git Statistics - Terms and Privacy";
  }, []);

  return (
    <Container maxWidth="md">
      <Box my={10}>
        <Typography variant="h3" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Azure Git Statistics! By accessing our service, you agree to be bound by these Terms of Service.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Use of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Azure Git Statistics provides statistical analysis tools for software development projects. Our service is designed to offer insights into project activities on Azure DevOps.
          By using our service you agree that Azure Git Statistics can access your Azure DevOps environment to gather the data it needs to function, such as Projects, Repositores and Pull 
          Requests.
        </Typography>
        <Typography variant="h5" gutterBottom>
          User Obligations
        </Typography>
        <Typography variant="body1" paragraph>
          As a user, you agree to use Azure Git Statistics responsibly and within the bounds of the law. Any misuse or unauthorized access is strictly prohibited.
        </Typography>
        <Typography variant="h3" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Your privacy is important to us. Our Privacy Policy outlines how we collect, use, and protect your information.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Data Collection
        </Typography>
        <Typography variant="body1" paragraph>
          We store the data you enter in to Azure Git Statistics, such as your email address, messages you write to support, and any other input that you as a user will enter.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Information Usage
        </Typography>
        <Typography variant="body1" paragraph>
          The information we collect is stored in a secure database and we do not share your personal information with third parties.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Importing Data From Azure DevOps
        </Typography>
        <Typography variant="body1" paragraph>
          As a user you will need to provide Azure Git Statistics with a Personal Access Token (PAT) from Azure DevOps. By providing Azure Git Statistics with this PAT
          you allow Azure Git Statistics to use it to connect to your Azure DevOps organisation. Azure Git Statistics will use this PAT to
          gather and store data on Projects and Repositories within your Organisation. When you decide to import Pull Requests from Azure DevOps using Azure Git Statistics,
          we store some of the information about each one to provide you with the service that we offer. 
        </Typography>
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms or our Privacy Policy, please contact us by sending a message <a href="/contact">here</a>.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Help
        </Typography>
        <Typography variant="body1" paragraph>
          For any additional help please visit our help section <a href="/help">here</a>.
        </Typography>
      </Box>
    </Container>
  );
};