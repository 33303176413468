import React, {useEffect} from "react"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import {useNavigate} from 'react-router-dom';
import product_enterprise_chart_users from './landing_page_photos/product_enterprise_chart_users.png';
import enterprise_page_header_image from './landing_page_photos/enterprise_page_header_image.png';
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';
import my_sub_projs from './landing_page_photos/my_sub_projs.png';
import project_stats from './landing_page_photos/project_stats.png';
import {variables} from './Variables.js';


export const ProductEnterprise = () => {

  useEffect(() => {
      document.title = "Azure Git Statistics - Enterprise Subscription";
      informProductEnterprise();
  }, []);
  
  const navigate = useNavigate();
  
  const getRegister = () => {
    navigate("/a/register")
  }
  
  const informProductEnterprise = () => {
    if (process.env.NODE_ENV !== 'production')
    {
      return;
    }
    fetch(variables.API_URL+'86293706-b1d1-4af6-bdd4-a0cf8c605c8f',{
        method:'POST',
        mode: 'cors',
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json'
        }
    })
    .then(res => {
        return res;
    })
  };

  return (
    <>
      {/* show only on md and lg and xl */}
      <Hidden only={['xs','sm']}>
        {/* header image with text */}
        <Box sx={{ position: 'relative', width: '100%', mt: 5}}>
          <Box component="img" sx={{ width: '100%', height: '350px' }} alt="yellow, green and blue shades" src={enterprise_page_header_image} />
          <Box
            sx={{ 
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <Typography
              variant='h3'
              gutterBottom
              sx={{ 
                color: '#031242',
                fontFamily: 'Quicksand, sans-serif',
                fontWeight: 'bold',
                textAlign: 'center',
                // Adding a white border effect using textShadow
                textShadow: `
                -2px -2px 0 #fff,  
                2px -2px 0 #fff,
                -2px 2px 0 #fff,
                2px 2px 0 #fff,
                -3px 0 0 #fff,
                3px 0 0 #fff,
                0 -3px 0 #fff,
                0 3px 0 #fff`
              }}
            >
              Track all teams in your entire Organisation
            </Typography>
            <Box align="center">
              <Typography
                variant='h5'
                sx={{
                  marginBottom: 3,
                  color: '#031242',
                  fontFamily: 'Quicksand, sans-serif',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  // Adding a white border effect using textShadow
                  textShadow: `
                  -1px -1px 0 #fff,  
                  1px -1px 0 #fff,
                  -1px 1px 0 #fff,
                  1px 1px 0 #fff`
                }}
              >
                Azure Git Statistics allows leaders to stay on track, all the time
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100%' }}>
          <Grid item>

            {/* text section */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              paddingY={'5rem'}
              sx={{
                width: '100%',
                height: '100%'
              }}
              >
              <Typography variant="h4" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '55%' }}>
                Click on a Project, get all its open pull requests, immediately
              </Typography>
              <Typography variant="h5" component="paragraph" sx={{mt: 3, fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
                One screen, for every single repository in your project!
              </Typography>
              <Typography variant="h6" component="paragraph" sx={{mt: 2, fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
              Azure Git Statistics allows Team Leads, Project Owners and Scrum Masters to stay on top of the game
              </Typography>
            </Box>
            
            {/* 2 images side-by-side */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="1rem">
              <Stack direction="row" spacing={1} justifyContent= 'center'>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box
                    component="img"
                    sx={{
                      width: "100%",
                      height: "53%",
                      borderRadius: 3,
                      boxShadow: '0px 0px 50px #d0d6f7',
                      borderStyle: 'solid',
                      borderWidth: '1px', 
                      borderColor: '#d0d6f7',
                    }}
                    alt="a screenshot of the Total Pull Requests Per User chart, with blue, vertical bars"
                    src={my_sub_projs}
                  />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 3,
                    boxShadow: '0px 0px 50px #d0d6f7',
                    borderStyle: 'solid',
                    borderWidth: '1px', 
                    borderColor: '#d0d6f7',
                  }}
                  alt="a screenshot of the Total Pull Requests Per User chart, with blue, vertical bars"
                  src={project_stats}
                />
                </Box>
              </Stack>
            </Box>

            {/* 1st quote */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="5rem">
              <Box 
                sx={{
                  display: 'flex',
                  width: '65%',
                  height: '25rem',
                  borderRadius: 2,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7'
                }}>
                <Box display="flex" flexDirection="column" sx={{ flex: '0 1 60%', height: '100%',
                  background: 'radial-gradient(circle at top right, #f9fff7, transparent)' }}>
                  <Box display="flex" justifyContent="flex-start" alignItems="flex-start" sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      sx={{
                        fontFamily: 'Quicksand, sans-serif',
                        paddingLeft: "2rem",
                        paddingTop: "2rem",
                        textAlign: "left", // Changed from center to left
                        width: "80%"
                      }}
                    >
                      <FormatQuoteRoundedIcon fontSize='large'/><i> Leadership needs to know how much work went in last month.
                        Can you get that to me by the end of the week? </i><FormatQuoteRoundedIcon fontSize='large'/>
                    </Typography>
                  </Box>

                  <Divider variant='middle' sx={{ bgcolor: 'black' }}/>

                  <Box display="flex" justifyContent="flex-end" alignItems="flex-end" sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'Quicksand, sans-serif',
                        paddingRight: "4rem",
                        paddingBottom: "3rem",
                        textAlign: "right", // Changed from center to right
                        width: "75%",

                      }}
                    >
                      - Boss
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ flex: '0 1 40%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderLeft: "thick double #d1e4ff",
                  background: 'radial-gradient(circle at top left, #d1e4ff, transparent)' }}>
                  <Typography variant="h5" sx={{ fontFamily: 'Quicksand, sans-serif', textAlign: 'center', color: 'black', width: '90%', padding: "1rem" }}>
                    Azure Git Statistics can do better than that! All the data, right now, in any form you need.
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* total PRs per user chart image */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={6}>
            <Box
              component="img"
              sx={{
                width: "65%",
                mt: "2rem",
                borderRadius: 10,
                boxShadow: '0px 0px 50px #d0d6f7',
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
              }}
              alt="a screenshot of the Total Pull Requests Per User chart, with blue, vertical bars"
              src={product_enterprise_chart_users}
            />
            </Box>

            {/* asterix text under chart image */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%', // or the width you desire
                height: '100%', // or the height you desire
                mt: '1rem'
              }}
              >
              <Typography gutterBottom variant="subtitle2" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '100%' }}>
                *With an Enterprise Subscription your can choose which developers you want in the Developers dropdown 
              </Typography>
            </Box>

            {/* start now button */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={6}>
              <Button onClick={() => getRegister()} variant="contained" color="secondary" size='large'>
              Start your free trial
              </Button>
            </Box>

            {/* text section */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              paddingY={'5rem'}
              sx={{
                width: '100%', // or the width you desire
                height: '100%', // or the height you desire
              }}
              >
              <Typography gutterBottom variant="h4" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '50%' }}>
                Compare teams accross the entire Organisation
              </Typography>
              <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
                Where are pull requests being authored
              </Typography>
              <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%', color: '#c41db9' }}>
                and reviewed
              </Typography>
              <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
              across any project, in any repository, any branch and during any period.
              </Typography>
            </Box>

            {/* 3 cards */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              paddingY={'1rem'}
              sx={{
                width: '100%', // or the width you desire
                height: '100%', // or the height you desire
              }}
              >
              <Grid container spacing={2} sx={{marginTop: '2rem'}} justifyContent="center" alignItems="stretch">
                <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                  <Card sx={{
                    backgroundColor: '#ebedf7', 
                    borderRadius: 8,
                    borderStyle: 'solid',
                    borderWidth: '1px', 
                    borderColor: '#d0d6f7', 
                    padding: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%', 
                    width: '100%'
                  }}
                  >
                    <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                        Subscribe to all Projects
                      </Typography>
                      <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                        Track everything, everywhere
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                  <Card sx={{
                    backgroundColor: '#ebedf7', 
                    borderRadius: 8,
                    borderStyle: 'solid',
                    borderWidth: '1px', 
                    borderColor: '#d0d6f7', 
                    padding: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%', 
                    width: '100%'
                  }}
                  >
                    <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                        Access to all charts
                      </Typography>
                      <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                        Use charts to visualise your teams' stats
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
                  <Card sx={{
                    backgroundColor: '#ebedf7', 
                    borderRadius: 8,
                    borderStyle: 'solid',
                    borderWidth: '1px', 
                    borderColor: '#d0d6f7', 
                    padding: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%', 
                    width: '100%'
                  }}
                  >
                    <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                        Export!
                      </Typography>
                      <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                        Take your data out to use elsewhere
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>

          </Grid>
        </Grid>
      </ Hidden>

      {/* show only on xs and sm */}
      <Hidden only={['md','lg', 'xl']}>
        {/* header image with text */}
        <Box sx={{ position: 'relative', width: '100%', mt: 5}}>
          <Box component="img" sx={{ width: '100%', height: '350px' }} alt="yellow, green and blue shades" src={enterprise_page_header_image} />
          <Box
            sx={{ 
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <Typography
              variant='h4'
              gutterBottom
              sx={{ 
                color: '#031242',
                fontFamily: 'Quicksand, sans-serif',
                fontWeight: 'bold',
                textAlign: 'center',
                // Adding a white border effect using textShadow
                textShadow: `
                -2px -2px 0 #fff,  
                2px -2px 0 #fff,
                -2px 2px 0 #fff,
                2px 2px 0 #fff,
                -3px 0 0 #fff,
                3px 0 0 #fff,
                0 -3px 0 #fff,
                0 3px 0 #fff`
              }}
            >
              Track all teams in your entire Organisation
            </Typography>
            <Box align="center">
              <Typography
                variant='h6'
                sx={{
                  marginBottom: 3,
                  color: '#031242',
                  fontFamily: 'Quicksand, sans-serif',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  // Adding a white border effect using textShadow
                  textShadow: `
                  -1px -1px 0 #fff,  
                  1px -1px 0 #fff,
                  -1px 1px 0 #fff,
                  1px 1px 0 #fff`
                }}
              >
                Azure Git Statistics allows leaders to stay on track, all the time
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100%' }}>
          <Grid item>

            {/* text section */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              paddingTop={'5rem'}
              paddingBottom={'4rem'}
              sx={{
                width: '100%',
                height: '100%'
              }}
              >
              <Typography variant="h4" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '80%' }}>
                Click on a Project, get all its open pull requests, immediately
              </Typography>
              <Typography variant="h5" component="paragraph" sx={{mt: 3, fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
                One screen, for every single repository in your project!
              </Typography>
              <Typography variant="h6" component="paragraph" sx={{mt: 2, fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
              Azure Git Statistics allows Team Leads, Project Owners and Scrum Masters to stay on top of the game
              </Typography>
            </Box>
            
            {/* 2 images side-by-side */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Stack direction="column" spacing={2} justifyContent= 'center'>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box
                    component="img"
                    sx={{
                      width: "80%",
                      height: "41%",
                      borderRadius: 3,
                      boxShadow: '0px 0px 50px #d0d6f7',
                      borderStyle: 'solid',
                      borderWidth: '1px', 
                      borderColor: '#d0d6f7',
                    }}
                    alt="a screenshot of the Total Pull Requests Per User chart, with blue, vertical bars"
                    src={my_sub_projs}
                  />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box
                  component="img"
                  sx={{
                    width: "80%",
                    height: "80%",
                    borderRadius: 3,
                    boxShadow: '0px 0px 50px #d0d6f7',
                    borderStyle: 'solid',
                    borderWidth: '1px', 
                    borderColor: '#d0d6f7',
                  }}
                  alt="a screenshot of the Total Pull Requests Per User chart, with blue, vertical bars"
                  src={project_stats}
                />
                </Box>
              </Stack>
            </Box>

            {/* 1st quote */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt="5rem">
              <Box 
                sx={{
                  width: '65%',
                  borderRadius: 2,
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7'
                }}>
                <Box display="flex" flexDirection="column" sx={{ height: '100%',
                  background: 'radial-gradient(circle at top right, #f9fff7, transparent)' }}>
                  <Box display="flex" justifyContent="flex-start" alignItems="flex-start" sx={{ flexGrow: 1, mb: 5 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      sx={{
                        fontFamily: 'Quicksand, sans-serif',
                        paddingLeft: "2rem",
                        paddingTop: "2rem",
                        textAlign: "left", // Changed from center to left
                        width: "90%"
                      }}
                    >
                      <FormatQuoteRoundedIcon fontSize='large'/><i> Leadership needs to know how much work went in last month.
                        Can you get that to me by the end of the week? </i><FormatQuoteRoundedIcon fontSize='large'/>
                    </Typography>
                  </Box>

                  <Divider variant='middle' sx={{ bgcolor: 'black' }}/>

                  <Box display="flex" justifyContent="flex-end" alignItems="flex-end" sx={{ flexGrow: 1, mt: 3 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Quicksand, sans-serif',
                        paddingRight: "3rem",
                        paddingBottom: "2rem",
                        textAlign: "right", // Changed from center to right
                        width: "75%",

                      }}
                    >
                      - Boss
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderTop: "thick double #d1e4ff",
                      background: 'radial-gradient(circle at top left, #d1e4ff, transparent)'
                    }}>
                    <Typography variant="h6`" sx={{ fontFamily: 'Quicksand, sans-serif', textAlign: 'center', color: 'black', width: '100%', padding: "2rem" }}>
                      Azure Git Statistics can do better than that! All the data, right now, in any form you need.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* total PRs per user chart image */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={6}>
            <Box
              component="img"
              sx={{
                width: "65%",
                mt: "2rem",
                borderRadius: 10,
                boxShadow: '0px 0px 50px #d0d6f7',
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
              }}
              alt="a screenshot of the Total Pull Requests Per User chart, with blue, vertical bars"
              src={product_enterprise_chart_users}
            />
            </Box>

            {/* asterix text under chart image */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%', // or the width you desire
                height: '100%', // or the height you desire
                mt: '1rem'
              }}
              >
              <Typography gutterBottom variant="subtitle2" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '100%' }}>
                *With an Enterprise Subscription your can choose which developers you want in the Developers dropdown 
              </Typography>
            </Box>

            {/* start now button */}
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={6}>
              <Button onClick={() => getRegister()} variant="contained" color="secondary" size='large'>
                Start your free trial
              </Button>
            </Box>

            {/* text section */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              paddingTop={'5rem'}
              paddingBottom={'3rem'}
              sx={{
                width: '100%', // or the width you desire
                height: '100%', // or the height you desire
              }}
              >
              <Typography gutterBottom variant="h4" component="div" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '50%' }}>
                Compare teams accross the entire Organisation
              </Typography>
              <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
                Where are pull requests being authored
              </Typography>
              <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%', color: '#c41db9' }}>
                and reviewed
              </Typography>
              <Typography variant="h6" component="paragraph" sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'center', width: '65%' }}>
                across any project, in any repository, any branch, during any period.
              </Typography>
            </Box>

            {/* 3 cards */}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              paddingY={'1rem'}
              sx={{
                width: '100%',
                height: '100%',
              }}
              >
              <Grid container sx={{marginTop: '1rem'}} justifyContent="center" alignItems="stretch">
                <Grid item xs={12} md={4} sx={{ display: 'flex', m: 2}}>
                  <Card sx={{
                    backgroundColor: '#ebedf7', 
                    borderRadius: 8,
                    borderStyle: 'solid',
                    borderWidth: '1px', 
                    borderColor: '#d0d6f7', 
                    padding: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%', 
                    width: '100%'
                  }}
                  >
                    <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                        Subscribe to all Projects
                      </Typography>
                      <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                        Track everything, everywhere
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', m: 2 }}>
                  <Card sx={{
                    backgroundColor: '#ebedf7', 
                    borderRadius: 8,
                    borderStyle: 'solid',
                    borderWidth: '1px', 
                    borderColor: '#d0d6f7', 
                    padding: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%', 
                    width: '100%'
                  }}
                  >
                    <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                        Access to all charts
                      </Typography>
                      <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                        Use charts to visualise your teams' stats
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: 'flex', m: 2 }}>
                  <Card sx={{
                    backgroundColor: '#ebedf7', 
                    borderRadius: 8,
                    borderStyle: 'solid',
                    borderWidth: '1px', 
                    borderColor: '#d0d6f7', 
                    padding: 1, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%', 
                    width: '100%'
                  }}
                  >
                    <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                        Export!
                      </Typography>
                      <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                        Take your data out to use elsewhere
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>

          </Grid>
        </Grid>
      </ Hidden>
    </>
  );
}