import React, { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

export const Help = () => {

  useEffect(() => {
    document.title = "Azure Git Statistics - Help";
  }, []);
  
  return (
    <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100%' }}>
      <Grid item>
        <Box maxWidth={900} display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={10}>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography><h5>Subscriptions</h5></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
              <p>Azure Git Statistics offers 2 Subscriptions, Personal and Enterprise</p>
              <h5>Personal</h5>
              <p/>
              <ul>
                <li>1 Organisation</li>
                <li>Up to 3 Projects</li>
                <li>Unable to modify Projects that you have subscribed to</li>
                <li>Only your name is visible on charts</li>
                <li>Unlimited repositories</li>
                <li>Access to 2 charts</li>
                <li>Export raw chart data to .csv</li>
              </ul>


              <h5>Enterprise</h5>
              <p/>
              <ul>
                <li>1 Organisation</li>
                <li>Unlimited Projects</li>
                <li>Able to modify Projects once you have subscribed to them</li>
                <li>All developer names visible</li>
                <li>Unlimited repositories</li>
                <li>Includes all charts</li>
                <li>Pull request management features</li>
                <li>Export raw chart data to .csv</li>
              </ul>
              <p>When purchasing a Subscription you will be redirected to a Stripe checkout form, <strong>https://checkout.stripe.com</strong>.</p>
            </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography><h5>Payments</h5></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
              <p>Payments are securely handled using Stripe and Stripe's API. Visit <strong>https://stripe.com</strong> for more information.</p>
              <p>When purchasing a Subscription you will be redirected to a Stripe checkout form, <strong>https://checkout.stripe.com</strong>.</p>
            </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography><h5>Upgrading your Subscription</h5></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
              <p>During the Subscription Cycle, you can upgrade from Personal to Enterprise.</p>
              <p>Upgrading is immediate. As a result, two things will happen:</p>
              <ul>
                <li>We will apply a Credit Note for the unused time on your Personal Subscription.</li>
                <li>You will be charged for the remaining time in the current subscription period, using the Enterprise subscription rate. This payment will be processed immediately.</li>
              </ul>
              <p><strong>Example:</strong> On November 1st, Jane Doe purchases a Personal Subscription for €5.00/month. Halfway through the subscription period, on November 15th, she decides to upgrade to Enterprise, which costs €20.00/month. Jane will receive a Credit Note of €2.66 for the unused period in the cycle (16 days: November 15th to November 30th). Additionally, Jane will be debited €10.66 for the <strong>remaining time in the current subscription period</strong>, using the Enterprise Subscription rate. Jane will be charged €8.00 immediately - then on December 1st, Jane will pay the Enterprise rate of €20.00/month.</p>
            </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography><h5>Downgrading your Subscription</h5></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
              <p>During the Subscription Cycle, you can downgrade from Enterprise to Personal.</p>
              <p>Downgrading is immediate. As a result, we will apply a Credit Note for the unused time on your Enterprise Subscription. The Credit Note will be used to pay for your next month's subscription</p>
              <p><strong>Example:</strong> On November 1st, Jane Doe purchases an Enterprise Subscription for €20.00/month. Halfway through the subscription period, on November 15th, she decides to downgrade to Personal, which costs €5.00/month. Jane will receive a Credit Note of €10.66 for the unused period in the cycle (16 days: November 15th to November 30th). On December 1st, Jane will not pay the €5.00 fee and the balance on Jane's Credit Note will be decreased: -€10.66 + €5.00 = €5.67</p>
            </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography><h5>Cancelling your Subscription</h5></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
              <p>You can cancel your subscription in Account Manager. If a Subscription is canceled, it will be cancelled at the end of the current subscription period</p>
            </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography><h5>Refunds for Subscriptions</h5></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
              <p>Refunds for Subscriptions are provided if there are Credit Notes on your account - all unused Credit Notes will be refunded:</p>
              <p><strong>Refund Processing Time: </strong> Refunds will take 3 to 5 business days to process. Once processed, it may take 5 to 10 days for the refund amount to reflect in your account.</p>
            </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography><h5>Free Trial</h5></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
              <p>A 1-day free trial is available for you to try a Subscription plan that we offer. After 24 hours, your account will be charged.</p>
              <p>If you do not wish to continue with your Subscription, you must cancel your Subscription before 24 hours have passed. You can cancel your Subscription at https://www.azuregitstatistics.com/a/account-manager/subscription</p>
            </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Grid>
    </Grid>
  );
}