import React, { useEffect } from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

export const SubscriptionPastDue = () => {

  useEffect(() => {
      document.title = "Azure Git Statistics - Subscription Due";
  }, []);

  let customerPortalLink = process.env.NODE_ENV === 'production' ?
      process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_PROD : 
      process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_DEV;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginTop="5rem"
    >
      <Paper elevation={3} style={{ padding: '2rem', textAlign: 'center' }}>
        <ErrorTwoToneIcon style={{ fontSize: '4rem', color: 'red' }} />
        <Typography variant="h5" style={{ marginTop: '1rem' }}>
          Subscription Payment Issue
        </Typography>
        <Typography variant="body1" style={{ marginTop: '1rem' }}>
          Your subscription is past due. This means that the recent payment was not successful. Please update your payment details to continue enjoying our services.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href={customerPortalLink}
          style={{ marginTop: '1.5rem' }}
        >
          Update Payment Details
        </Button>
      </Paper>
    </Box>
  );
};

export default SubscriptionPastDue;