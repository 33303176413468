import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { getSubscription } from './apiUtils.js';
import Box from '@mui/material/Box';

export const SubscribeWithStripe = () => {

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Azure Git Statistics - Subscribe";
    getSubscription(handleSubscriptionState);
  });

  const handleSubscriptionState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
    }
    
    if(!data.subscriptionIsCancelledInStripe) //subscriptionIsCancelledInStripe is false if a new account which has never bought a sub before
    {
      if(data.subscriptionIsPastDue)
      {
        navigate("/a/account-manager/subscription-past-due")
        return
      }
      if(data.subscriptionTier === "")
      {
        return;
      }
      navigate("/a/account-manager/subscription")
    }

  };

  const userId = localStorage.getItem("userId");
  const email = localStorage.getItem("email");

  return (
    <>
      <Box marginTop="3rem">
        <stripe-pricing-table
          pricing-table-id={process.env.NODE_ENV === 'production' ? process.env.REACT_APP_STRIPE_PRICING_TABLE_ID_PROD : process.env.REACT_APP_STRIPE_PRICING_TABLE_ID_DEV}
          publishable-key={process.env.NODE_ENV === 'production' ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV}
          client-reference-id={userId}
          customer-email={email}
          >
        </stripe-pricing-table>
      </Box>
    </>
  )

};

export default SubscribeWithStripe;