export function getPreviousPeriod(startDate, endDate) {
  const durationInMilliseconds = endDate - startDate;
  
  const startDateForPreviousPeriod = new Date(startDate.getTime() - durationInMilliseconds - 86400000);
  const endDateForPreviousPeriod = new Date(endDate.getTime() - durationInMilliseconds - 86400000);
  
  return { startDateForPreviousPeriod, endDateForPreviousPeriod };
}

export function areChartFormDatesPresent(startDate, endDate) {
  return startDate !== null && endDate !== null;
}

export function calculateDaysBetweenDates(startDate, endDate){
  // Calculate the time difference in milliseconds
  const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());

  // Convert the time difference to days
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  return daysDifference + 1;
};

export function getPlaceholderList(jsonArray) {
  if (jsonArray.length === 0) {
      return "None selected...";
  }

  const firstObjectName = jsonArray[0].Name;
  const numberOfObjects = jsonArray.length - 1;

  if(jsonArray.length === 1)
  {
    return `${firstObjectName}`;
  }
  else
  {
    return `${firstObjectName} (+${numberOfObjects})`;
  }
}

export function downloadCsvFile(jsonData) {
  if (jsonData) {
    const csv = convertToCSV(jsonData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    URL.revokeObjectURL(url);
  }
}

const convertToCSV = (data) => {
  if (data.length === 0) return '';
  const headers = Object.keys(data[0]);
  const csv = [headers.join(',')];

  data.forEach((item) => {
    const values = headers.map((header) => {
      const value = item[header];
      return JSON.stringify(value);
    });
    csv.push(values.join(','));
  });

  return csv.join('\n');
};