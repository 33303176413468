import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import DoneIcon from '@mui/icons-material/Done';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Hidden from '@mui/material/Hidden';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import landing_page_wave2 from './landing_page_photos/landing_page_wave2.png';
import landing_page_top2 from './landing_page_photos/landing_page_top2.png';
import landing_page_1 from './landing_page_photos/landing_page_1.png';
import landing_page_3 from './landing_page_photos/landing_page_3.png';
import pr_illustration from './landing_page_photos/pr_illustration.png';
import repo_illustration from './landing_page_photos/repo_illustration.png';
import approval_illustration from './landing_page_photos/approval_illustration.png';
import {variables} from './Variables.js';
import {useNavigate} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import DeblurOutlinedIcon from '@mui/icons-material/DeblurOutlined';

export const Homepage = (props) => {

  const navigate = useNavigate();

  useEffect(() => {
    informHome();
    document.title = "Azure Git Statistics";
  }, []);

  const getPricing = () => {
    navigate("/pricing")
  }

  const getRegister = (e) => {
    navigate("/a/register");
  };

  const getProduct = (e) => {
    navigate("/product");
  };

  const getProductEnterprise = (e) => {
    navigate("/product/enterprise");
  };
  
  const informHome = () => {
    if (process.env.NODE_ENV !== 'production')
    {
      return;
    }
    fetch(variables.API_URL+'e908a7f4-2b8d-49d0-b37a-de8e5235754b',{
        method:'POST',
        mode: 'cors',
        headers:{
          'Accept':'application/json',
          'Content-Type':'application/json'
        }
    })
    .then(res => {
        return res;
    })
  };

  return (
    <>
      {/* show only on lg and xl */}
      <Hidden only={['xs', 'sm', 'md']}>
        {/* Top image and text */}
        <Box sx={{ position: 'relative', width: '100%', mt: 5}}>
          <Box component="img" sx={{ width: '100%' }} alt="green and blue circular shapes" src={landing_page_top2} />
          <Typography
            variant='h2'
            sx={{ 
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#031242',
              fontFamily: 'Quicksand, sans-serif',
              fontWeight: 'bold',
              textAlign: 'center',
              // Adding a white border effect using textShadow
              textShadow: `
              -2px -2px 0 #fff,  
               2px -2px 0 #fff,
              -2px 2px 0 #fff,
               2px 2px 0 #fff,
              -3px 0 0 #fff,
               3px 0 0 #fff,
              0 -3px 0 #fff,
               0 3px 0 #fff`
            }}
          >
            Azure Git Statistics
          </Typography>
        </Box>
        {/* Next centered div with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                marginTop: '4rem', 
                marginLeft: '20%',
                width: '60%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at bottom left, #e6ffe7, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top right, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom left, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                A Pull Request Manager for Azure DevOps
              </Typography>
              <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                With Azure Git Statistics you can immediately see all Open pull requests across all repositories in a project.
              </Typography>
              <Button onClick={() => getProductEnterprise()} variant="contained" color="info" size='large'>
                Discover more and start your free trial
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" mt={12} paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                See who created it, when, and which branches are involved. Click on the Pull Request and it will take you to that
                Azure DevOps pull request page.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {/* 1st image, text on right */}
        <Box sx={{ position: 'relative', width: '100%', mt: '4rem' }}>
          <Box
            component="img" 
            sx={{
              width: '75%',
              borderRadius: 10,
              boxShadow: '0px 0px 50px #d0d6f7',
              borderStyle: 'solid',
              borderWidth: '1px', 
              borderColor: '#d0d6f7',
              marginLeft: "15%"
            }}
            alt="a beige and black glactic wave with stars"
            src={landing_page_wave2}
          />
          <Box
            sx={{ 
              position: 'absolute',
              top: '40%',
              left: '65%',
              transform: 'translate(-50%, -50%)'
            }}
          >
          <Typography
            variant='h3'
            gutterBottom
            sx={{ 
              color: '#031242',
              fontFamily: 'Quicksand, sans-serif',
              fontWeight: 'bold',
              textAlign: 'center',
              // Adding a white border effect using textShadow
              textShadow: `
              -2px -2px 0 #fff,  
               2px -2px 0 #fff,
              -2px 2px 0 #fff,
               2px 2px 0 #fff,
              -3px 0 0 #fff,
               3px 0 0 #fff,
              0 -3px 0 #fff,
               0 3px 0 #fff`
            }}
          >
            Visualise your team's efficiency
          </Typography>
          <Box align="center">
            <Typography
              variant='h5'
              sx={{
                marginBottom: 3,
                color: '#031242',
                fontFamily: 'Quicksand, sans-serif',
                fontWeight: 'bold',
                textAlign: 'center',
                // Adding a white border effect using textShadow
                textShadow: `
                -2px -2px 0 #fff,  
                2px -2px 0 #fff,
                -2px 2px 0 #fff,
                2px 2px 0 #fff,
                -3px 0 0 #fff,
                3px 0 0 #fff,
                0 -3px 0 #fff,
                0 3px 0 #fff`
              }}
            >
              Discover who is making the most impact, and where!
            </Typography>
            <Button onClick={() => getProduct()} variant="contained" color="secondary" size='large'>
              Find out more
            </Button>
          </Box>
          </Box>
        </Box>{/* 1st centered dev with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                marginTop: '4rem', 
                marginLeft: '20%',
                width: '60%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at bottom right, #e6ffe7, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                A unified platform for your development statistics
              </Typography>
              <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                Azure Git Statistics gathers all your pull requests from Azure DevOps, into one intuitive platform.
                Track, analyze, and enhance your team's performance with tailored analytics. 
              </Typography>
              <Button onClick={() => getRegister()} variant="contained" color="info" size='large'>
                Start now
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" mt={12} paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                Dive into detailed pull request analytics, monitor repository activity, and leverage data to 
                accelerate your development process. With Azure Git Statistics, you're equipped to make informed 
                decisions swiftly and efficiently.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {/* 3 centered cards */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          paddingY={'1rem'}
          sx={{
            width: '100%', // or the width you desire
            height: '100%', // or the height you desire
            my: '5rem'
          }}
          >
          <Grid container spacing={2} justifyContent="center" alignItems="stretch" width={'65%'}>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7', 
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%'
              }}
              >
                <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Track <QueryStatsOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    Discover trends
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7', 
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%'
              }}
              >
                <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Analyze <LocalLibraryOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    What's working, what's not
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7', 
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%'
              }}
              >
                <CardContent sx={{ mt: 2, ml: 2, flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Improve <DeblurOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    Use data to take action
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {/* 2nd image and text */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container>
            <Grid item md={4}>
              <Box sx={{width: '75%', ml: '45%', mt: '5%'}}>
                <Typography gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h4" mr={2}>
                  "We want to improve collaboration in your team"
                </Typography>
                <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h5" mr={2}>
                  Use charts to create reports to help your team accelerate and keep on track
                  through sprints and releases
                </Typography>
              </Box>
            </Grid>
            <Grid md={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box
                component="img"
                sx={{
                  width: "75%",
                  boxShadow: '0px 0px 50px #d0d6f7',
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7',
                  borderRadius: 10
                }}
                alt="a group of women and men sitting around a table and computers in an office overlooking the city"
                src={landing_page_3}
              />
            </Grid>
          </Grid>
        </Box>
        {/* 2nd centered div with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                my: '5rem',
                marginLeft: '20%',
                width: '60%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at top left, #d1e4ff, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                Streamline Your Development Cycle in Azure DevOps
              </Typography>
              <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                Identify bottlenecks, optimize your team's workflow, and help reduce the time from
                development to deployment with Azure Git Statistics. Empower your developers with data.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" mt={12} paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                Facilitate better collaboration by visualising contribution patterns and peer review practices across your teams.
                Azure Git Statistics helps turn individual efforts into collective success.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {/* 3rd image and text on left */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container>
            <Grid item md={4}>
              <Box sx={{width: '85%', ml: '35%'}}>
                <Typography gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h4" mr={2}>
                Analytics, Insightful Stats, Smarter Workflow
                </Typography>
                <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h5" mr={2}>
                Discover the busiest Projects and Repositories in your Organization
                </Typography>
                <Stack direction="row" spacing={1} marginY={3}>
                  <Chip
                    label="Organization"
                    icon={<DoneIcon />}
                    sx={{backgroundColor: "#00B3FF"}}
                  />
                  <Chip
                    label="Projects"
                    icon={<DoneIcon />}
                    sx={{backgroundColor: "#7593FF"}}
                  />
                  <Chip
                    label="Repositories"
                    icon={<DoneIcon />}
                    sx={{backgroundColor: "#FF9CD2"}}
                  />
                </Stack>
                <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h5" mr={2}>
                Analyze and contrast performance across your own custom time frames
                </Typography>
              </Box>
            </Grid>
            <Grid md={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box
                component="img"
                sx={{
                  width: "75%",
                  boxShadow: '0px 0px 50px #d0d6f7',
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7',
                  borderRadius: 10
                }}
                alt="a group of men and women standing in front of a blue, black and white screen"
                src={landing_page_1}
              />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={8}>
            <Button onClick={() => getPricing()} variant="contained" color="info" size='large'>
              See pricing
            </Button>
          </Box>
        </Box>
      </Hidden>

      {/* show only on md */}
      <Hidden only={['xs', 'sm', 'lg', 'xl']}>
        {/* Top image and text */}
        <Box sx={{ position: 'relative', width: '100%', mt: 5}}>
          <Box component="img" sx={{ width: '100%' }} alt="green and blue circular shapes" src={landing_page_top2} />
          <Typography
            variant='h2'
            sx={{ 
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#031242',
              fontFamily: 'Quicksand, sans-serif',
              fontWeight: 'bold',
              textAlign: 'center',
              // Adding a white border effect using textShadow
              textShadow: `
              -2px -2px 0 #fff,  
               2px -2px 0 #fff,
              -2px 2px 0 #fff,
               2px 2px 0 #fff,
              -3px 0 0 #fff,
               3px 0 0 #fff,
              0 -3px 0 #fff,
               0 3px 0 #fff`
            }}
          >
            Azure Git Statistics
          </Typography>
        </Box>
        {/* 1st centered dev with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                marginTop: '4rem', 
                marginLeft: '13%',
                width: '75%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at bottom left, #e6ffe7, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top right, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom left, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                A Pull Request Manager for Azure DevOps
              </Typography>
              <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                With Azure Git Statistics you can immediately see all Open pull requests across all repositories in a project.
              </Typography>
              <Button onClick={() => getProductEnterprise()} variant="contained" color="info" size='large'>
                Discover more and start your free trial
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" mt={17} paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                See who created it, when, and which branches are involved. Click on the Pull Request and it will take you to that
                Azure DevOps pull request page.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {/* 1st image, text on right */}
        <Box sx={{ position: 'relative', width: '100%', mt: '4rem' }}>
          <Box
            component="img" 
            sx={{
              width: '80%',
              borderRadius: 10,
              boxShadow: '0px 0px 50px #d0d6f7',
              borderStyle: 'solid',
              borderWidth: '1px', 
              borderColor: '#d0d6f7',
              marginLeft: "10%"
            }}
            alt="a beige and black glactic wave with stars"
            src={landing_page_wave2}
          />
          <Box
            sx={{ 
              position: 'absolute',
              top: '46%',
              left: '65%',
              transform: 'translate(-50%, -50%)'
            }}
          >
          <Typography
            variant='h4'
            gutterBottom
            sx={{ 
              color: '#031242',
              fontFamily: 'Quicksand, sans-serif',
              fontWeight: 'bold',
              textAlign: 'center',
              // Adding a white border effect using textShadow
              textShadow: `
              -2px -2px 0 #fff,  
               2px -2px 0 #fff,
              -2px 2px 0 #fff,
               2px 2px 0 #fff,
              -3px 0 0 #fff,
               3px 0 0 #fff,
              0 -3px 0 #fff,
               0 3px 0 #fff`
            }}
          >
            Visualise your team's efficiency
          </Typography>
          <Box align="center">
            <Typography
              variant='h6'
              sx={{
                marginBottom: 3,
                color: '#031242',
                fontFamily: 'Quicksand, sans-serif',
                fontWeight: 'bold',
                textAlign: 'center',
                // Adding a white border effect using textShadow
                textShadow: `
                -2px -2px 0 #fff,  
                2px -2px 0 #fff,
                -2px 2px 0 #fff,
                2px 2px 0 #fff,
                -3px 0 0 #fff,
                3px 0 0 #fff,
                0 -3px 0 #fff,
                0 3px 0 #fff`
              }}
            >
              Discover who is making the most impact, and where!
            </Typography>
            <Button onClick={() => getProduct()} variant="contained" color="secondary" size='medium'>
              Find out more
            </Button>
          </Box>
          </Box>
        </Box>
        {/* 1st centered dev with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                marginTop: '4rem', 
                marginLeft: '13%',
                width: '75%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at bottom right, #e6ffe7, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                A unified platform for your development statistics
              </Typography>
              <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                Azure Git Statistics gathers all your pull requests from Azure DevOps, into one intuitive platform.
                Track, analyze, and enhance your team's performance with tailored analytics. 
              </Typography>
              <Button onClick={() => getRegister()} variant="contained" color="info" size='large'>
                Start now
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" mt={17} paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                Dive into detailed pull request analytics, monitor repository activity, and leverage data to 
                accelerate your development process. With Azure Git Statistics, you're equipped to make informed 
                decisions swiftly and efficiently.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {/* 3 centered cards */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%', // or the width you desire
            height: '100%', // or the height you desire
            my: '5rem'
          }}
          >
          <Grid container spacing={2} justifyContent="center" alignItems="stretch" width={'65%'}>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%',
              }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Track <QueryStatsOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    Discover trends
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%'
              }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Analyze <LocalLibraryOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    What's working, what's not
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7', 
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%'
              }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Improve <DeblurOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    Use data to take action
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {/* 2nd image and text */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container>
            <Grid item md={4}>
              <Box sx={{width: '80%', ml: '35%', mt: '10%'}}>
                <Typography gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h5">
                  "We want to improve collaboration in your team"
                </Typography>
                <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h6">
                  Use charts to create reports to help your team accelerate and keep on track
                  through sprints and releases
                </Typography>
              </Box>
            </Grid>
            <Grid md={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Box
                component="img"
                sx={{
                  width: "90%",
                  boxShadow: '0px 0px 50px #d0d6f7',
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7',
                  borderRadius: 10
                }}
                alt="a group of women and men sitting around a table and computers in an office overlooking the city"
                src={landing_page_3}
              />
            </Grid>
          </Grid>
        </Box>
        {/* 2nd centered div with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                my: '5rem', 
                marginLeft: '13%',
                width: '75%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at top left, #d1e4ff, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                Streamline Your Development Cycle in Azure DevOps
              </Typography>
              <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                Identify bottlenecks, optimize your team's workflow, and help reduce the time from
                development to deployment with Azure Git Statistics. Empower your developers with data.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" mt={17} paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
                Facilitate better collaboration by visualising contribution patterns and peer review practices across your teams.
                Azure Git Statistics helps turn individual efforts into collective success.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        {/* 3rd image and text on left */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container>
            <Grid item md={4}>
              <Box sx={{width: '90%', ml: '27%', mt: '10%'}}>
                <Typography gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h5">
                Analytics, Insightful Stats, Smarter Workflow
                </Typography>
                <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h6">
                Discover the busiest Projects and Repositories in your Organization
                </Typography>
                <Stack direction="column" spacing={1} marginY={3}>
                  <Stack direction="row" spacing={1} marginY={3}>
                    <Chip
                      label="Organization"
                      icon={<DoneIcon />}
                      sx={{backgroundColor: "#00B3FF"}}
                    />
                    <Chip
                      label="Projects"
                      icon={<DoneIcon />}
                      sx={{backgroundColor: "#7593FF"}}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1} marginY={3}>
                    <Chip
                      label="Repositories"
                      icon={<DoneIcon />}
                      sx={{backgroundColor: "#FF9CD2"}}
                    />
                  </Stack>
                </Stack>
                <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h6">
                Analyze and contrast performance across your own custom time frames
                </Typography>
              </Box>
            </Grid>
            <Grid md={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Box
                component="img"
                sx={{
                  width: "90%",
                  boxShadow: '0px 0px 50px #d0d6f7',
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7',
                  borderRadius: 10
                }}
                alt="a group of men and women standing in front of a blue, black and white screen"
                src={landing_page_1}
              />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={8}>
            <Button onClick={() => getPricing()} variant="contained" color="info" size='large'>
              See pricing
            </Button>
          </Box>
        </Box>
      </Hidden>

      {/* show only on sm */}
      <Hidden only={['xs', 'md', 'lg', 'xl']}>
        {/* Top image and text */}
        <Box sx={{ position: 'relative', width: '100%', mt: 5 }}>
          <Box component="img" sx={{ width: '100%' }} alt="green and blue circular shapes" src={landing_page_top2} />
          <Typography
            variant='h4'
            sx={{ 
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#031242',
              fontFamily: 'Quicksand, sans-serif',
              fontWeight: 'bold',
              textAlign: 'center',
              // Adding a white border effect using textShadow
              textShadow: `
              -2px -2px 0 #fff,  
               2px -2px 0 #fff,
              -2px 2px 0 #fff,
               2px 2px 0 #fff,
              -3px 0 0 #fff,
               3px 0 0 #fff,
              0 -3px 0 #fff,
               0 3px 0 #fff`
            }}
          >
            Azure Git Statistics
          </Typography>
        </Box>
        {/* 1st centered dev with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                marginTop: '4rem',
                marginLeft: '13%',
                width: '75%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at bottom left, #e6ffe7, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top right, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom left, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
            A Pull Request Manager for Azure DevOps
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            With Azure Git Statistics you can immediately see all Open pull requests across all repositories in a project.
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            See who created it, when, and which branches are involved. Click on the Pull Request and it will take you to that
            Azure DevOps pull request page.
          </Typography>
          <Button onClick={() => getProductEnterprise()} variant="contained" color="info" size='medium'>
             Discover more and start your free trial
          </Button>
        </Paper>
        {/* 1st image, text on right */}
        <Box sx={{ position: 'relative', width: '95%', mt: '4rem' }}>
          <Box
            component="img" 
            sx={{
              width: '100%',
              borderRadius: 10,
              boxShadow: '0px 0px 50px #d0d6f7',
              borderStyle: 'solid',
              borderWidth: '1px', 
              borderColor: '#d0d6f7',
              m: 1
            }}
            alt="a beige and black glactic wave with stars"
            src={landing_page_wave2}
          />
          <Box
            sx={{ 
              position: 'absolute',
              top: '49%',
              left: '66%',
              transform: 'translate(-50%, -50%)'
            }}
          >
          <Typography
            variant='h5'
            gutterBottom
            sx={{ 
              color: '#031242',
              fontFamily: 'Quicksand, sans-serif',
              fontWeight: 'bold',
              textAlign: 'center',
              // Adding a white border effect using textShadow
              textShadow: `
              -2px -2px 0 #fff,  
               2px -2px 0 #fff,
              -2px 2px 0 #fff,
               2px 2px 0 #fff,
              -3px 0 0 #fff,
               3px 0 0 #fff,
              0 -3px 0 #fff,
               0 3px 0 #fff`
            }}
          >
            Visualise your team's efficiency
          </Typography>
          <Box align="center">
            <Typography
              variant='body1'
              sx={{
                marginBottom: 1,
                color: '#031242',
                fontFamily: 'Quicksand, sans-serif',
                fontWeight: 'bold',
                textAlign: 'center',
                // Adding a white border effect using textShadow
                textShadow: `
                -2px -2px 0 #fff,  
                2px -2px 0 #fff,
                -2px 2px 0 #fff,
                2px 2px 0 #fff,
                -3px 0 0 #fff,
                3px 0 0 #fff,
                0 -3px 0 #fff,
                0 3px 0 #fff`
              }}
            >
              Discover who is making the most impact, and where!
            </Typography>
            <Button onClick={() => getProduct()} variant="contained" color="secondary" size='small'>
              Find out more
            </Button>
          </Box>
          </Box>
        </Box>
        {/* 1st centered dev with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                marginTop: '4rem',
                marginLeft: '13%',
                width: '75%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at bottom right, #e6ffe7, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
            A unified platform for your development statistics
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            Azure Git Statistics gathers all your pull requests from Azure DevOps, into one intuitive platform.
            Track, analyze, and enhance your team's performance with tailored analytics. 
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            Dive into detailed pull request analytics, monitor repository activity, and leverage data to 
            accelerate your development process. With Azure Git Statistics, you're equipped to make informed 
            decisions swiftly and efficiently.
          </Typography>
          <Button onClick={() => getRegister()} variant="contained" color="info" size='medium'>
            Start now
          </Button>
        </Paper>
        {/* 3 centered cards */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%', // or the width you desire
            height: '100%', // or the height you desire
            my: '4rem'
          }}
          >
          <Grid container spacing={2} justifyContent="center" alignItems="stretch" width={'50%'}>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%',
              }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Track <QueryStatsOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    Discover trends
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%'
              }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Analyze <LocalLibraryOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    What's working, what's not
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7', 
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%'
              }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Improve <DeblurOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    Use data to take action
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {/* 2nd image and text */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container>
            <Grid item md={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{width: "80%"}}>
                <Typography gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h4" textAlign="center">
                  "We want to improve collaboration in your team"
                </Typography>
                <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h6" textAlign="center">
                  Use charts to create reports to help your team accelerate and keep on track
                  through sprints and releases
                </Typography>
              </Box>
            </Grid>
            <Grid md={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box
                component="img"
                sx={{
                  width: "90%",
                  boxShadow: '0px 0px 50px #d0d6f7',
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7',
                  borderRadius: 10
                }}
                alt="a group of women and men sitting around a table and computers in an office overlooking the city"
                src={landing_page_3}
              />
            </Grid>
          </Grid>
        </Box>
        {/* 2nd centered div with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                my: '5rem', 
                marginLeft: '13%',
                width: '75%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at top left, #d1e4ff, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
            Streamline Your Development Cycle in Azure DevOps
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            Identify bottlenecks, optimize your team's workflow, and help reduce the time from
            development to deployment with Azure Git Statistics. Empower your developers with data.
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            Facilitate better collaboration by visualising contribution patterns and peer review practices across your teams.
            Azure Git Statistics helps turn individual efforts into collective success.
          </Typography>
        </Paper>
        {/* 3rd image and text on left */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container>
            <Container>
              <Grid item md={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{width: "100%"}}>
                  <Typography gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h4" textAlign="center">
                  Analytics, Insightful Stats, Smarter Workflow
                  </Typography>
                  <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h6" textAlign="center">
                  Discover the busiest Projects and Repositories in your Organization
                  </Typography>
                  <Stack direction="column" spacing={1} marginY={3}>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Organization"
                        icon={<DoneIcon />}
                        sx={{backgroundColor: "#00B3FF"}}
                      />
                      <Chip
                        label="Projects"
                        icon={<DoneIcon />}
                        sx={{backgroundColor: "#7593FF"}}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Repositories"
                        icon={<DoneIcon />}
                        sx={{backgroundColor: "#FF9CD2"}}
                      />
                    </Stack>
                  </Stack>
                  <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h6" textAlign="center">
                  Analyze and contrast performance across your own custom time frames
                  </Typography>
                </Box>
              </Grid>
            </Container>
            <Grid md={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box
                component="img"
                sx={{
                  width: "90%",
                  boxShadow: '0px 0px 50px #d0d6f7',
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7',
                  borderRadius: 10,
                  mt: 2
                }}
                alt="a group of men and women standing in front of a blue, black and white screen"
                src={landing_page_1}
              />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={8}>
            <Button onClick={() => getPricing()} variant="contained" color="info" size='large'>
              See pricing
            </Button>
          </Box>
        </Box>
      </Hidden>

      {/* show only on xs */}
      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        {/* Top image and text */}
        <Box sx={{ position: 'relative', width: '100%', mt: 5 }}>
          <Box component="img" sx={{ width: '100%' }} alt="green and blue circular shapes" src={landing_page_top2} />
          <Typography
            variant='h4'
            sx={{ 
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#031242',
              fontFamily: 'Quicksand, sans-serif',
              fontWeight: 'bold',
              textAlign: 'center',
              // Adding a white border effect using textShadow
              textShadow: `
              -2px -2px 0 #fff,  
               2px -2px 0 #fff,
              -2px 2px 0 #fff,
               2px 2px 0 #fff,
              -3px 0 0 #fff,
               3px 0 0 #fff,
              0 -3px 0 #fff,
               0 3px 0 #fff`
            }}
          >
            Azure Git Statistics
          </Typography>
        </Box>
        {/* 1st centered div with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                marginTop: '2rem',
                marginLeft: '13%',
                width: '75%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at bottom left, #e6ffe7, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top right, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom left, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
            A Pull Request Manager for Azure DevOps
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            With Azure Git Statistics you can immediately see all Open pull requests across all repositories in a project. 
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            See who created it, when, and which branches are involved. Click on the Pull Request and it will take you to that
            Azure DevOps pull request page.
          </Typography>
          <Button onClick={() => getProductEnterprise()} variant="contained" color="info" size='medium'>
            Discover more and start your free trial
          </Button>
        </Paper>
        {/* 1st image, text on right */}
        <Box sx={{ position: 'relative', width: '95%', mt: '4rem' }}>
          <Box
            component="img" 
            sx={{
              width: '100%',
              borderRadius: 10,
              boxShadow: '0px 0px 50px #d0d6f7',
              borderStyle: 'solid',
              borderWidth: '1px', 
              borderColor: '#d0d6f7',
              m: 1
            }}
            alt="a beige and black glactic wave with stars"
            src={landing_page_wave2}
          />
          <Box
            sx={{ 
              position: 'absolute',
              top: '30%',
              left: '75%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            <Typography
              variant='h5'
              sx={{ 
                color: '#031242',
                fontFamily: 'Quicksand, sans-serif',
                fontWeight: 'bold',
                fontSize: '1.30rem',
                textAlign: 'center',
                // Adding a white border effect using textShadow
                textShadow: `
                -2px -2px 0 #fff,  
                2px -2px 0 #fff,
                -2px 2px 0 #fff,
                2px 2px 0 #fff,
                -3px 0 0 #fff,
                3px 0 0 #fff,
                0 -3px 0 #fff,
                0 3px 0 #fff`
              }}
            >
              Visualise your team's efficiency
            </Typography>
          </Box>
          <Box align="center">
            <Typography
              variant='body1'
              sx={{
                marginY: 1,
                color: '#031242',
                fontFamily: 'Quicksand, sans-serif',
                fontWeight: 'bold',
                textAlign: 'center',
                // Adding a white border effect using textShadow
                textShadow: `
                -2px -2px 0 #fff,  
                2px -2px 0 #fff,
                -2px 2px 0 #fff,
                2px 2px 0 #fff,
                -3px 0 0 #fff,
                3px 0 0 #fff,
                0 -3px 0 #fff,
                0 3px 0 #fff`
              }}
            >
              Discover who is making the most impact, and where!
            </Typography>
            <Button onClick={() => getProduct()} variant="contained" color="secondary" size='small'>
              Find out more
            </Button>
          </Box>
        </Box>
        {/* 1st centered div with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                marginTop: '2rem',
                marginLeft: '13%',
                width: '75%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at bottom right, #e6ffe7, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
            A unified platform for your development statistics
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            Azure Git Statistics gathers all your pull requests from Azure DevOps, into one intuitive platform.
            Track, analyze, and enhance your team's performance with tailored analytics. 
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            Dive into detailed pull request analytics, monitor repository activity, and leverage data to 
            accelerate your development process. With Azure Git Statistics, you're equipped to make informed 
            decisions swiftly and efficiently.
          </Typography>
          <Button onClick={() => getRegister()} variant="contained" color="info" size='medium'>
            Start now
          </Button>
        </Paper>
        {/* 3 centered cards */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            height: '100%',
            my: '4rem'
          }}
          >
          <Grid container spacing={2} justifyContent="center" alignItems="stretch" width={'50%'}>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }} width={'100%'}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%',
              }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Track <QueryStatsOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    Discover trends
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }} width={'100%'}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7',
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%'
              }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Analyze <LocalLibraryOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    What's working, what's not
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={4} sx={{ display: 'flex' }} width={'100%'}>
              <Card sx={{
                backgroundColor: '#ebedf7', 
                borderRadius: 8,
                borderStyle: 'solid',
                borderWidth: '1px', 
                borderColor: '#d0d6f7', 
                padding: 1, 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%', 
                width: '100%'
              }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" fontWeight={'bold'} sx={{ fontFamily: 'Open Sans, sans-serif', width: '100%' }}>
                    Improve <DeblurOutlinedIcon fontSize='large'/>
                  </Typography>
                  <Typography variant="body1" component="div" sx={{ fontFamily: 'Nunito, sans-serif', width: '100%' }}>
                    Use data to take action
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {/* 2nd image and text */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container>
            <Grid item md={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{width: "80%"}}>
                <Typography gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h4" textAlign="center">
                  "We want to improve collaboration in your team"
                </Typography>
                <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h6" textAlign="center">
                  Use charts to create reports to help your team accelerate and keep on track
                  through sprints and releases
                </Typography>
              </Box>
            </Grid>
            <Grid md={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box
                component="img"
                mt={2}
                sx={{
                  width: "90%",
                  boxShadow: '0px 0px 50px #d0d6f7',
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7',
                  borderRadius: 10
                }}
                alt="a group of women and men sitting around a table and computers in an office overlooking the city"
                src={landing_page_3}
              />
            </Grid>
          </Grid>
        </Box>
        {/* 2nd centered div with colours */}
        <Paper
          elevation={4}
          sx={{
                padding: '2rem',
                my: '5rem', 
                marginLeft: '13%',
                width: '75%',
                transition: 'background-color 0.3s ease-in-out',
                background: 'radial-gradient(circle at top left, #d1e4ff, transparent)',
                ':hover': {
                  background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                              radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                              white`,
                }
              }}
          >
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
            Streamline Your Development Cycle in Azure DevOps
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            Identify bottlenecks, optimize your team's workflow, and help reduce the time from
            development to deployment with Azure Git Statistics. Empower your developers with data.
          </Typography>
          <Typography variant="body1" paragraph sx={{ fontFamily: 'Nunito, sans-serif' }}>
            Facilitate better collaboration by visualising contribution patterns and peer review practices across your teams.
            Azure Git Statistics helps turn individual efforts into collective success.
          </Typography>
        </Paper>
        {/* 3rd image and text on left */}
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Grid container>
            <Container>
              <Grid item md={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{width: "100%"}}>
                  <Typography gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }} variant="h4" textAlign="center">
                  Analytics, Insightful Stats, Smarter Workflow
                  </Typography>
                  <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h6" textAlign="center">
                  Discover the busiest Projects and Repositories in your Organization
                  </Typography>
                  <Stack direction="column" spacing={1} marginY={3}>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Organization"
                        icon={<DoneIcon />}
                        sx={{backgroundColor: "#00B3FF"}}
                      />
                      <Chip
                        label="Projects"
                        icon={<DoneIcon />}
                        sx={{backgroundColor: "#7593FF"}}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Repositories"
                        icon={<DoneIcon />}
                        sx={{backgroundColor: "#FF9CD2"}}
                      />
                    </Stack>
                  </Stack>
                  <Typography gutterBottom sx={{ fontFamily: 'Nunito, sans-serif' }} variant="h6" textAlign="center">
                  Analyze and contrast performance across your own custom time frames
                  </Typography>
                </Box>
              </Grid>
            </Container>
            <Grid md={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box
                component="img"
                sx={{
                  width: "90%",
                  boxShadow: '0px 0px 50px #d0d6f7',
                  borderStyle: 'solid',
                  borderWidth: '1px', 
                  borderColor: '#d0d6f7',
                  borderRadius: 10,
                  mt: 2
                }}
                alt="a group of men and women standing in front of a blue, black and white screen"
                src={landing_page_1}
              />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={8}>
            <Button onClick={() => getPricing()} variant="contained" color="info" size='large'>
              See pricing
            </Button>
          </Box>
        </Box>
      </Hidden>
      
      {/* bottom 3 cards */}
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={5}>
        <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
            <Paper elevation={3} sx={{
                // height: 625,
                flexGrow: 1,
                borderRadius: 10,
                m: '1rem',
                transition: 'box-shadow 0.1s ease-in-out, border-color 0.1s ease-in-out',
                borderColor: 'transparent',
                borderStyle: 'solid',
                borderWidth: '3px',
                '&:hover': {
                  boxShadow: '0px 0px 50px rgba(209, 228, 255, 1)',
                }
              }}
            >
              {/* Text Part: Title */}
              <Box sx={{ p: 2, overflow: 'auto', height: '105px' }}>
                <Typography variant="h5" fontWeight="bold" mt={1} fontFamily="Open Sans, sans-serif" width="100%">
                  Comprehensive PR Tracking
                </Typography>
              </Box>

              {/* Image Part */}
              <CardMedia
                component="img"
                image={pr_illustration}
                alt="2 computers with an arrow pointing to each other depicting a pull request"
                sx={{ bgcolor: '#cfe8fc', objectFit: 'cover' }}
              />

              {/* Text Part: Description */}
              <Box sx={{ p: 2, overflow: 'auto' }}>
                <Typography variant="body1" fontFamily="Nunito, sans-serif">
                  Monitor the lifecycle of every pull request. Know who's contributing the most and keep track of PR completion rates.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
            <Paper elevation={3} sx={{
                // height: 625,
                flexGrow: 1,
                borderRadius: 10,
                m: '1rem',
                transition: 'box-shadow 0.1s ease-in-out, border-color 0.1s ease-in-out',
                borderColor: 'transparent',
                borderStyle: 'solid',
                borderWidth: '3px',
                '&:hover': {
                  boxShadow: '0px 0px 50px rgba(230, 255, 231, 1)',
                }
              }}
            >
              {/* Text Part: Title */}
              <Box sx={{ p: 2, overflow: 'auto', height: '105px' }}>
                <Typography  sx={{ fontFamily: 'Open Sans, sans-serif', width: "100%" }} variant="h5" fontWeight={"bold"} mt={1}>
                  Approvals
                </Typography>
              </Box>

              {/* Image Part */}
              <CardMedia
                component="img"
                image={approval_illustration}
                alt="a green check mark depiciting a pull request which is approved"
                sx={{ bgcolor: '#cfe8fc', objectFit: 'cover' }}
              />

              {/* Text Part: Description */}
              <Box sx={{ p: 2, overflow: 'auto' }}>
                <Typography sx={{ fontFamily: 'Nunito, sans-serif' }} variant="body1">
                  Visualise who is approving Pull Requests, and make adjustments.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
            <Paper elevation={3} sx={{
                flexGrow: 1,
                borderRadius: 10,
                m: '1rem',
                transition: 'box-shadow 0.1s ease-in-out, border-color 0.1s ease-in-out',
                borderColor: 'transparent',
                borderStyle: 'solid',
                borderWidth: '3px',
                '&:hover': {
                  boxShadow: '0px 0px 50px rgba(209, 228, 255, 1)',
                }
              }}
            >
              {/* Text Part: Title */}
              <Box sx={{ p: 2, overflow: 'auto', height: '105px' }}>
                <Typography sx={{ fontFamily: 'Open Sans, sans-serif', width: "100%" }} variant="h5" fontWeight={"bold"} mt={1}>
                  Repository Analytics
                </Typography>
              </Box>

              {/* Image Part */}
              <CardMedia
                component="img"
                image={repo_illustration}
                alt="a cylinder container depicting a repository to store source code"
                sx={{ bgcolor: '#cfe8fc', objectFit: 'cover' }}
              />

              {/* Text Part: Description */}
              <Box sx={{ p: 2, overflow: 'auto' }}>
                <Typography sx={{ fontFamily: 'Nunito, sans-serif' }} variant="body1">
                Dive into your repositories. Understand whats changing the most and what needs more attention.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      
    </>
  );
}
export default Homepage;
