import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';
import {variables} from './Variables.js';

export const Suggestions = () => {

    useEffect(() => {
        document.title = "Azure Git Statistics - Suggestions";
    }, []);
    
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [isSending, setIsSending] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleDialogClose = () => {
      setIsDialogOpen(false);
      navigate("/")
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSending(true)

        let emailInternal = formData.email.toLowerCase();

        const postData = {
          Name: formData.name,
          Email: emailInternal,
          Message: formData.message
        };

        fetch(variables.API_URL+'Suggestions',{
            method:'POST',
            mode: 'cors',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json'
            },
            body: JSON.stringify(postData),
        })
        .then(res => {
          if (res.status === 401)
          {
            navigate("/a/log-in")
            throw new Error('Unauthorized')
          }
          if (res.status >= 500 && res.status < 600 )
          {
            setIsSending(false);
            setDialogMessage("Oops! There was a problem. Please try again.")
            setIsDialogOpen(true);
            throw new Error('Server error')
          }
          return res.json();
        })
        .then(data => {
            setIsSending(false);
            setDialogMessage("Thank you for sending your suggestion to Azure Git Statistics.")
            setIsDialogOpen(true)
        })
        .catch(error => {
            console.error('There has been a problem with your request:', error);
        })
    };

    return (
        <>
            <Container maxWidth="sm">
                <Box marginTop={10}>
                    <Typography variant="h4" component="h1" gutterBottom>We Value Your Suggestions!</Typography>
                    <Typography variant="body1" gutterBottom>
                        Your ideas make us better. No suggestion is too small or too big, we use them to improve our site.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            required
                            margin="normal"
                        />
                        <TextField
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            required
                            margin="normal"
                        />
                        <TextField
                            label="Suggestion"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            fullWidth
                            required
                            multiline
                            rows={4}
                            margin="normal"
                        />
                        <Button type="submit" variant="contained" color="primary" disabled={isSending}>Send</Button>
                    </form>
                </Box>
            </Container>
            
            {isDialogOpen && (
                <SharedAlertDialog
                dialogMessage={dialogMessage}
                dialogTitle="Info"
                isOpen={isDialogOpen}
                handleDialogClose={handleDialogClose}
                />
            )}
        </>
    );
};