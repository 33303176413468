import React, { useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {useNavigate} from 'react-router-dom';
import { Container, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Button, Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {variables} from './Variables.js';

export const PricingOptions = () => {

    useEffect(() => {
        document.title = "Azure Git Statistics - Plans and Pricing";
        informPricing();
    }, []);

    const navigate = useNavigate();

    const getSubscribe = () => {
      navigate("/a/account-manager/subscribe");
    };

    const getProductForPersonal = () => {
      navigate("/product/personal")
    }

    const getProductForEnterprise = () => {
      navigate("/product/enterprise")
    }

    const getRegister = () => {
      navigate("/a/register");
    }
  
    const informPricing = () => {
      if (process.env.NODE_ENV !== 'production')
      {
        return;
      }
      fetch(variables.API_URL+'016cab3f-20f3-49ea-854c-63260ebf4647',{
          method:'POST',
          mode: 'cors',
          headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
          }
      })
      .then(res => {
          return res;
      })
    };

    return (
        <>
            <Container maxWidth="md" >
                <Box my={10}>
                    <Grid container spacing={4}>
                        {/* Personal Subscription Plan */}
                        <Grid item xs={12} md={6}>
                            <Paper style={{ padding: '20px' }}>
                                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                    Personal Subscription
                                </Typography>
                                <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                    €5.00
                                </Typography>
                                <List>
                                    {/* Dummy data, replace with actual features */}
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="1 Organisation" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Up to 3 Projects" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Unable to modify Projects that you have subscribed to" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Only your name is visible on charts" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Unlimited repositories" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Access to 2 charts" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Export raw chart data to .csv" />
                                    </ListItem>
                                </List>
                                
                                <Box textAlign="center" marginTop={4}>
                                    <Box marginBottom={2}>
                                        <Button onClick={() => getProductForPersonal()} variant="contained" color="success">
                                            Learn More
                                        </Button>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>

                        {/* Enterprise Subscription Plan */}
                        <Grid item xs={12} md={6}>
                            <Paper style={{ padding: '20px'}}>
                                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                    Enterprise Subscription
                                </Typography>
                                <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                    €20.00
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="1 Organisation" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Unlimited Projects" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Able to modify Projects once you have subscribed to them" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="All developer names visible" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Unlimited repositories" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Includes all charts " />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Pull request management features" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleOutlineIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Export raw chart data to .csv"/>
                                    </ListItem>
                                </List>
                                
                                <Box textAlign="center" marginTop={4}>
                                    <Box marginBottom={2}>
                                        <Button onClick={() => getProductForEnterprise()} variant="contained" color="success">
                                            Learn More
                                        </Button>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box textAlign="center" marginTop={4}>
                        <Box marginBottom={2}>
                            <Button onClick={() => getSubscribe()} variant="outlined" color="primary">
                                Already have an account? Buy subscription
                            </Button>
                        </Box>
                        <Box>
                            <Button onClick={() => getRegister()} variant="contained" color="info">
                                Don't have an account? Start you're free trial today
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default PricingOptions;