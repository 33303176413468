import React, { useState, useEffect } from 'react';
import { getAzureDetails } from './apiUtils.js';
import { getSubscription } from './apiUtils.js';
import {variables} from './Variables.js';
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Button from '@mui/material/Button';
import LoadingSkeleton from './LoadingSkeleton.jsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import { CardActions } from '@mui/material';
import { LinearProgress } from '@mui/material';
import {SharedAlertDialog} from './SharedAlertDialog.jsx';

export const PullRequestImportTool = () => {

  const navigate = useNavigate();
  const [azureProjects, setAzureProjects] = useState('');
  const [importing, setImporting] = useState(false);
  const [importingProjectId, setImportingProjectId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
 
  useEffect(() => {
    document.title = "Azure Git Statistics - Pull Request Import Tool";
    getAzureDetails(handleAzureDetailState);
    getSubscription(handleSubscriptionState);
  }, []); 
  
  const handleAzureDetailState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
    }
    setAzureProjects(data.azureProjects);
  };

  const handleSubscriptionState = (data) => {
    if(data.status_code === 401)
    {
      navigate("/a/log-in")
      return
    }
    if (data.subscriptionTier === "" || data.subscriptionIsCancelledInStripe)
    {
      navigate("/a/account-manager")
      return
    }
    if (data.subscriptionIsPastDue)
    {
      navigate("/a/account-manager/subscription-past-due")
      return
    }
  };

  function handlePullRequestImport(azureProject){
    setImporting(true);
    setImportingProjectId(azureProject.id);
    setIsDialogOpen(false)
    setDialogMessage(
      "Importing has started. This process may take a few minutes, especially if this is your first time importing " +
      "this project and depending on the volume of Pull Requests. Please do not close the window or refresh this page, " +
      "as this can interrupt the import process. Your data is secure during this time. Once the import is complete " +
      "another pop up will appear saying 'Import Complete'. For any issues, please contact support at www.azuregitstatistics.com/contact."
      )
    setIsDialogOpen(true);

    const postData = {
      AzureProjectId: azureProject.azureProjectId,
      AzureProjectName: azureProject.azureProjectName
    };

    fetch(variables.API_URL+'GetPullRequestsFromAzure',{
        method:'POST',
        mode: 'cors',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json',
            'Authorization': 'bearer ' + localStorage.getItem("token")
        },
        body: JSON.stringify(postData),
    })
    .then(res => {
      if (res.status === 401)
      {
        navigate("/a/log-in")
        throw new Error('Unauthorized')
      }
      if (res.status >= 500 && res.status < 600 )
      {
        setImporting(false);
        setImportingProjectId(null);
        setIsDialogOpen(false);
        setDialogMessage("Oops! There was a problem. Your PAT may be incorrect or it has expired. Visit Azure DevOps to get a new one and then update it in Account Manager > Your Azure Details. "+
        "If your PAT is up to date, please try again. If the problem persists, please contact support at https://www.azuregitstatistics.com/contact")
        setIsDialogOpen(true);
        throw new Error('Server error')
      }
      return res.json();
    })
    .then(data => {
      setImporting(false);
      setImportingProjectId(null);
      if (data.message === "Problem")
      {
        setDialogMessage("Oops! There was a problem. Your PAT may be incorrect or it has expired. Visit Azure DevOps to get a new one and then update it in Account Manager > Your Azure Details")
      }
      else
      {        
        setDialogMessage("Import Complete")
      }
      setIsDialogOpen(true);
    })
    .catch(error => {
        console.error('There has been a problem with your request:', error);
    })
  };

  function doesUserHaveAzureProjects(azureProjects){
    return azureProjects !== null && azureProjects.length > 0;
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {
        azureProjects === ''
        ?
          <LoadingSkeleton/>
        :
        doesUserHaveAzureProjects(azureProjects) ?
        (
          <Grid container spacing={2} sx={{mb: 4, mt: "4rem", mr: 4, ml: 4, }} alignItems="stretch">
            {azureProjects.map((project, index) => (
              <Grid item sm={12} md={azureProjects.length === 1 ? 12 : 4} key={project.id} sx={{ display: 'flex' }}>
                <Card
                  raised
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: 2,
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%', 
                    width: '100%',
                    transition: 'background-color 0.3s ease-in-out',
                    ':hover': {
                      background: `radial-gradient(circle at top left, #d1e4ff, transparent), 
                                  radial-gradient(circle at bottom right, #e6ffe7, transparent), 
                                  white`,
                    }
                  }}>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h6" align="center" sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                      {project.azureProjectName}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth 
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<CloudDownloadIcon />}
                      disabled={importing}
                      onClick={() => handlePullRequestImport(project)}
                    >
                      Import
                    </Button>
                  </CardActions>
                  {importingProjectId === project.id && (
                    <Box sx={{ width: '100%', mt: 1 }}>
                      <LinearProgress color="secondary" />
                    </Box>
                  )}
                </Card>
              </Grid>
            ))}
          </Grid>
        )
        :
        (
          
          <Alert severity="warning" style={{ marginTop: '5rem' }}>
            You have not selected any Projects, please visit Your Azure Details in Account Manager to select some.
          </Alert>
        )
      }
      {isDialogOpen && (
        <SharedAlertDialog
          dialogMessage={dialogMessage}
          dialogTitle="Info"
          isOpen={isDialogOpen}
          handleDialogClose={handleDialogClose}
        />
      )}
    </>
  );
};